import { WordsObject, WORD_TAGS } from '../../../../store/interface';
import { PARSED_WORD_FREQUENCY } from './interface';

export const getCurrentWordsByTab = (
  wordFrequencies: WordsObject,
  currentTab: string,
  wordBank: WORD_TAGS
): WordsObject => {
  let currentWordBank;
  switch (currentTab) {
    case 'competitors': {
      currentWordBank = wordBank.competitors;
      break;
    }
    case 'products': {
      currentWordBank = wordBank.products;
      break;
    }
    case 'other': {
      currentWordBank = wordBank.others;
      break;
    }
    case 'purchaseIntent': {
      currentWordBank = wordBank.purchaseIntent;
      break;
    }
    case 'actionPersuation': {
      currentWordBank = wordBank.actionPersuation;
      break;
    }
    case 'concerns': {
      currentWordBank = wordBank.concerns;
      break;
    }
    default:
      return wordFrequencies;
  }
  return filterWordFrequencies(wordFrequencies, currentWordBank);
};

const filterWordFrequencies = (wordFrequencies: WordsObject, wordBankFilterFromUi: string[]): WordsObject => {
  let filteredFrequencies = {} as WordsObject;

  wordBankFilterFromUi.forEach((word: string) => {
    if (wordFrequencies[word]) {
      filteredFrequencies[word] = wordFrequencies[word];
    }
  });
  return filteredFrequencies;
};

export const toArray = (wordFrequencies: WordsObject): PARSED_WORD_FREQUENCY[] => {
  const wordFrequenciesArray = [] as PARSED_WORD_FREQUENCY[];
  Object.keys(wordFrequencies).forEach((word) => {
    const { comments, isActive, count } = wordFrequencies[word];
    if (count) {
      wordFrequenciesArray.push({
        word,
        comments,
        isActive,
        count,
      });
    }
  });
  return wordFrequenciesArray;
};
