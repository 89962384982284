import { REACTIONS } from '../../../../../store/interface';
import { assets } from '../../../utils/assets';
import { Wrapper, Container } from './Styles';
import { ComposedChart, Area, LabelList, YAxis, XAxis, Bar, CartesianGrid, Tooltip } from "recharts";
import { ChartHeadline } from '../Sentiment/Styles';
import { emojiDict } from './emojiDict';
import { capitalizeFirstLetter, getMaximumForBarGraph } from '../utils/helper'
import { FunctionComponent, PureComponent } from 'react';
import { colors, colorsCharts, randomizeColorsChart } from '../../../../../utils';
import { useCustomChartRenderers } from '../utils/useCustomChartRenderers';
import { REACTIONS_ORDER } from '../../../../../store/constants';

const BrandMoreReactions = ({ reactions, isReportPage }: { reactions: REACTIONS; isReportPage?: boolean }) => {
  let reactionsCopy = { ...reactions };
  const { renderCustomLabel, renderCustomAvgTick, renderPostsCountTick, renderAvgStripedBar, renderAvgStripedBarForSameColorMultBars } = useCustomChartRenderers();
  const totalReactionsAmountCopy = reactions.totalReactions;

  delete reactionsCopy.shares;
  delete reactionsCopy.thankful;
  delete reactionsCopy.pride;
  delete reactionsCopy.totalReactions;

  let dataReactions: any[] = []
  Object.entries(reactionsCopy).map(([name, amount]) => {
    dataReactions[REACTIONS_ORDER[name]] = {
      "name": capitalizeFirstLetter(name),
      "amount": amount,
      "icon": assets[name]
    }
    let percentage = (amount! / totalReactionsAmountCopy!) * 100;
    percentage = percentage < 1 ? +percentage.toFixed(1) : +percentage.toFixed(0);
    return {
      description: `${amount} ${name.charAt(0).toUpperCase()}${name.slice(1)}`,
      percentage,
      imgSrc: assets[name] ?? '',
    };
  });
  const CustomTooltip = ({ active, payload, label }: any): any => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0].payload.name.charAt(0).toUpperCase() + payload[0].payload.name.slice(1)}`}</p>
          <p className="value">{`Amount : ${payload[0].payload.amount}`}</p>
          {payload[0].payload.posts > 1 && <p className="posts_count">{`posts count : ${payload[0].payload.posts}`}</p>}
        </div>
      );
    }

    return null;
  }

  const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload } = props;
    const assetName = (payload.value as String).toLocaleLowerCase();
    return (
      <g transform={`translate(${x},${y})`}>
        <image xlinkHref={assets[assetName]} x={-13} y={-4} height="27px" width="27px" textAnchor="middle" fill="#666" />
      </g>
    );
  };

  let maximum = getMaximumForBarGraph(dataReactions, 'amount');
  return (
    <Wrapper isReportPage={isReportPage} id={'more-reactions'}>
      <ChartHeadline >Reactions <img src={assets['LikeGray']} style={{ marginLeft: '25px' }} alt="" /> </ChartHeadline>
      <Container>
        <ComposedChart width={450} height={360} data={dataReactions}>
          <XAxis tickLine={false} dataKey="name" axisLine={false} xAxisId={1}  style={{ fontSize: '10px', color: colors.darkBlue }}  />
          <XAxis tickLine={false} interval={0} dataKey="name" axisLine={{ stroke: '#4D566F', opacity:'0.2' }} xAxisId={0} tick={<CustomizedAxisTick/>} />
          <YAxis tick={false} axisLine={false} domain={[0, maximum]} />
          <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" />
          <Bar shape={(shapeProps) => renderAvgStripedBarForSameColorMultBars(shapeProps, 'Reactions')} dataKey="amount" barSize={24} fill={randomizeColorsChart[0]}>
            <LabelList offset={10} dataKey="amount" position="top" content={renderCustomLabel} />
          </Bar>
          <Tooltip cursor={false} content={<CustomTooltip />} />
        </ComposedChart>
        <img src="" alt="" />
      </Container>
    </Wrapper>
  );
};

export default BrandMoreReactions;
