import { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../../../store/context';
import Breakdown from './breakdown/Breakdown';
import LikesBar from './likes/LikesBar';
import MoreReactions from './more-reactions/MoreReactions';
import ReactionsBar from './reactions-bar/ReactionsBar';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: none;
  margin-right: 147px;
  margin: 0 147px 0 0;
  height: 80%;
  margin-top: 28px;
`;

export const Container = styled.div`
  display: flex;
  height: 434px;
`;

export const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 9px;
  width: 70%;
`;

const Reactions = () => {
  const {
    state: {
      report: { currentPost },
    },
  } = useContext(AppContext);

  const { reactions } = currentPost!;

  return (
    <Wrapper>
      <ReactionsBar reactionsSentiments={currentPost!.reactionsSentiments} isReportPage={false} />
      <Container>
        <LeftCol>
          <LikesBar
            likeAmount={reactions.like}
            totalReactions={reactions.totalReactions}
            isReportPage={false}
          />
          <MoreReactions reactions={reactions} />
        </LeftCol>
        <Breakdown reactions={reactions} />
      </Container>
    </Wrapper>
  );
};

export default Reactions;
