import { useContext } from "react";
import { AppContext } from "../../../store/context";
import { assets } from "../utils/assets";
import { AlertContainer, CenterText } from "./Styles";

const Alert = ({ msg }: { msg: string }) => {
  const {
    state: {
      report: { name },
    },
  } = useContext(AppContext);
  return (
    <AlertContainer>
      <img src={assets["homePage"]} alt="" />
      <CenterText>
        <span className="title">{msg}</span>
        {!name && <span className="thin">Enter a report name</span>}
      </CenterText>
    </AlertContainer>
  );
};

export default Alert;
