import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import styled from "styled-components";
import { REACTIONS } from "../../../../../store/interface";
import { colors } from "../../../../../utils";

export const Wrapper = styled.div`
  width: 30%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: ${colors.white};
  padding: 20px 0 0 16px;
  min-height: 381px;
`;

const Breakdown = ({ reactions }: { reactions: REACTIONS }) => {
  let reactionsCopy = { ...reactions };
  delete reactionsCopy.like;
  delete reactionsCopy.shares;
  delete reactionsCopy.thankful;
  delete reactionsCopy.pride;
  delete reactionsCopy.totalReactions;

  const reactionsArray = Object.entries(reactionsCopy).map(([name, amount]) => {
    return {
      amount,
      name: `${name.charAt(0).toUpperCase()}${name.slice(1)}`,
    };
  });
  const COLORS = ["#25B8F8", "#2C97F9", "#DF7D00", "#F4546F", "#FBCD5A", "#F44967"];
  return (
    <Wrapper>
      <h3 style={{ fontSize: "15px" }}>More reactions breakdown</h3>
      <p style={{ color: "#2A2F4A", fontSize: "12px", marginTop: "5px" }}>Likes excluded</p>
      <ResponsiveContainer height={"100%"} width={"100%"}>
        <PieChart>
          <Pie
            data={reactionsArray}
            cy={180}
            cornerRadius={8}
            innerRadius={50}
            labelLine={false}
            dataKey="amount"
          >
            {reactionsArray.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip cursor={false} />
        </PieChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

export default Breakdown;
