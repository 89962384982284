import { Page, StyleSheet, Image, View, Text } from "@react-pdf/renderer";
import { SINGLE_POST_DETAILED_RESPONSE } from "../../../../store/interface";
import Footer from "../Footer";
import Header from "../Header";
import { DATA_URL } from "../interface";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "rgb(251, 253, 255)",
    height: "85%",
  },

  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "100px",
  },

  commentsSentiment: {
    width: 500,
    height: 70,
  },
  paddingContent: {
    padding: "40px 57px",
  },
  sentimentText: {
    fontFamily: "Poppins-Bold",
  },
});

const Page5 = ({
  commentsSentiment,
  currentPost,
  reportName,
}: {
  currentPost: SINGLE_POST_DETAILED_RESPONSE;
  commentsSentiment: DATA_URL;
  reportName: string;
}) => {
  const MyDoc = (
    <Page size="A4" style={styles.page} orientation="landscape">
      <View style={styles.paddingContent}>
        <Header
          reportName={reportName}
          current_page={5}
          total_pages={5}
          page_title={"Positivity/Negativity & Sentiment"}
        />
        <View style={styles.content}>
          <View style={styles.sentimentText}>
            <Text style={{ fontFamily: "Poppins" }}>Organic Posts</Text>
            <Text style={{ marginTop: "15px" }}>Comments Sentiment</Text>
            <Text>Breakdown</Text>
          </View>
          <Image style={styles.commentsSentiment} src={commentsSentiment.url}></Image>
        </View>
      </View>
      <Footer currentPost={currentPost} />
    </Page>
  );

  return MyDoc;
};

export default Page5;
