import { Fragment, useMemo } from 'react';
import { useTable, Column } from 'react-table';
import { PARSED_POST } from '../../../../store/interface';
import { assets } from '../../utils/assets';
import { Icon } from '../../utils/CommonStyles';
import { TABLE_PROPS } from './interface';
import { TableStyled } from './Styles';

const PostInputTable = ({
  data,
  handleDeletePost,
  handleCheckBox,
  setActiveStatusForAllPosts,
}: TABLE_PROPS) => {
  const columns: Column<PARSED_POST>[] = useMemo(
    () => [
      {
        Header: <input type="checkbox" />,
        accessor: 'isActive',
        Cell: ({ value, row }) => (
          <input
            type="checkbox"
            checked={value}
            style={{ marginTop: '7px' }}
            onChange={(e) => {
              handleCheckBox(row.original.id, e.target.checked);
            }}
          />
        ),
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ value }) => <Icon src={assets[value]} />,
      },
      {
        Header: 'ID',
        accessor: 'processedId',
      },
      {
        Header: 'Post Title',
        accessor: 'description',
      },
    ],
    [handleCheckBox]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<PARSED_POST>({
    columns,
    data,
  });

  return (
    <TableStyled {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, i) => {
              const { key } = { ...column.getHeaderProps() };
              if (key !== 'header_isActive')
                return (
                  <th {...column.getHeaderProps()} key={i}>
                    {column.render('Header')}
                  </th>
                );
              return (
                <th key={i}>
                  <input
                    style={{ marginTop: '6px', marginLeft: '13px' }}
                    type="checkbox"
                    onChange={(e) => {
                      setActiveStatusForAllPosts(e.target.checked);
                    }}
                  />
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={rowIndex}>
              {row.cells.map((cell, i) => {
                return (
                  <Fragment key={i}>
                    <td {...cell.getCellProps()}>{cell.render('Cell')} </td>
                    {i === 3 && (
                      <td onClick={() => handleDeletePost(rowIndex)}>
                        <Icon className="trash" src={assets['trash']} />
                      </td>
                    )}
                  </Fragment>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </TableStyled>
  );
};

export default PostInputTable;
