import styled from 'styled-components';
import { colors } from '../../../../../utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  .chart-headline {
    font-size: 21px;
    padding-left: 26px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .custom-tooltip {
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    padding: 10px;
    background-color: white;
    .label{
      color: ${colors.darkBlue};
      font-size: 12px;
    }
    .posts_count{
      font-size: 10px;
      color: #333B5099;
    }
  }

`;
