import axios from "axios";
const getBaseURL = (ENV: string | undefined) => {
  switch (ENV) {
    case "production":
      return process.env.REACT_APP_API_URL_PROD;
    case "develop":
      return process.env.REACT_APP_API_URL_DEV;
    default:
      return "http://localhost:3000/api/v1";
  }
};

const ENV = process.env.REACT_APP_ENV;
const BASE_URL = getBaseURL(ENV);
export const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.token,
    email: localStorage.email,
  },
  baseURL: BASE_URL,
});

export default instance;
