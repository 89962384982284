import { Document } from "@react-pdf/renderer";
import { SINGLE_POST_DETAILED_RESPONSE, WORD_TAGS } from "../../../store/interface";
import { DATA_URL } from "./interface";
import Page1 from "./page1/Page1";
import Page10 from "./page10/Page10";
import Page11 from "./page11/Page11";
import Page12 from "./page12/Page12";
import Page2 from "./page2/Page2";
import Page3 from "./page3/Page3";
import Page4 from "./page4/Page4";
import Page5 from "./page5/Page5";
import Page6 from "./page6/Page6";
import Page7 from "./page7/Page7";
import Page8 from "./page8/Page8";
import Page9 from "./page9/Page9";

const CompletedPdf = ({
  dataUrls,
  currentPost,
  reportName,
  wordBank
}: {
  reportName: string;
  currentPost: SINGLE_POST_DETAILED_RESPONSE;
  dataUrls: DATA_URL[];
  wordBank: WORD_TAGS | null
}) => {
  const [likesBarData, moreLikesBar, userTagsBar, userRepliesChart, commentsSentiment] = dataUrls;

  const MyDoc = (
    <Document>
      <Page1 currentPost={currentPost} reportName={reportName} />
      <Page2
        reportName={reportName}
        currentPost={currentPost}
        likesBarData={likesBarData}
        moreLikesBar={moreLikesBar}
      />
      <Page3 reportName={reportName} currentPost={currentPost} userTagsBar={userTagsBar} />
      <Page4 reportName={reportName} currentPost={currentPost} userRepliesChart={userRepliesChart} />
      <Page5 reportName={reportName} currentPost={currentPost} commentsSentiment={commentsSentiment} />
      <Page6 reportName={reportName} currentPost={currentPost} />
      <Page7 reportName={reportName} currentPost={currentPost} wordBank={wordBank} />
      <Page8 reportName={reportName} currentPost={currentPost} wordBank={wordBank} />
      <Page9 reportName={reportName} currentPost={currentPost} wordBank={wordBank} />
      <Page10 reportName={reportName} currentPost={currentPost} wordBank={wordBank} />
      <Page11 reportName={reportName} currentPost={currentPost} wordBank={wordBank} />
      <Page12 reportName={reportName} currentPost={currentPost} wordBank={wordBank} />
    </Document>
  );

  return MyDoc;
};

export default CompletedPdf;
