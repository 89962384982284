import { useContext, useState } from 'react';
import { Redirect } from 'react-router';
import { assets } from '../../../utils/assets';
import { AppContext } from '../../../../../store/context';
import { addCompare } from './Styles';
import { InputCompare } from '../../../utils/CommonStyles';
import CompareBrandsPick from './CompareSteps/CompareBrandsPick';
import ComparePostsPick from './CompareSteps/ComparePostsPick';
import { fetchPostsDetails, validateManualyPageId } from "../../../../../api/facebookApi";
import { CONSTANTS } from '../../../../../store/constants';
import { getAveragesDictionery } from './helper';

const { 
  Button,
  CancelButton,
  ProcessButton,
  CompareFooter,
  CompareFooterContainer,
  BrandsContainer,
  PlusInput,
  CompareLoader
} = addCompare

const CompareProcess = ({
    isCompareOpen,
    setIsCompareOpen,
    setIsCompare
}:{
    isCompareOpen:boolean,
    setIsCompareOpen:React.Dispatch<React.SetStateAction<boolean>>
    setIsCompare:React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const {
    state: { report },
    dispatch
  } = useContext(AppContext);
  
  const [isToNextStep, setToNextStep] = useState(false);
  const [error, setError] = useState({ message: "", type: "" });
  const [manualyPageId, setManualyPageId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  
  const { currentPost, brands } = report;
  if (!currentPost) {
    return <Redirect to={`/${report.name}`} />;
  }

  const toggleNext = () => {
    setToNextStep((prev) => !prev)
    if (!isToNextStep && report.brandsToCompare.length === 0){
      setToNextStep(false)
      alert('Choose at least one brand for the compare process')
    }
  } 
  

  const toggleCompare = () => {
    if ( isToNextStep && report.postsToCompare.length  === 0 ) {
      setToNextStep(true)
      alert('No posts to compare')
    }  else {
      const getPostDetails = async ()=>{
        setIsLoading(true)
        const postsToCompare = report.postsToCompare
        const getDetails = await fetchPostsDetails(postsToCompare, report.wordBank)
        const postsAveragesByBrands = getAveragesDictionery(getDetails);
        dispatch({
          type: CONSTANTS.SetDeatilsComparePosts,
          payload:{
            detailsPostsToCompare: postsAveragesByBrands
          }
        })
        setIsLoading(false)
        setIsCompareOpen(false)
        setIsCompare(false)
        dispatch({
          type: CONSTANTS.SetAfterCompare,
          payload: {afterCompare: true}
        });
      }
      getPostDetails()
     
    }
  } 


  const handleMouseClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleAddBrand();
  };

  const handleAddBrand = async () => {
   if (!manualyPageId) return;

    // validate and add to database, also apply {,isActive:true} to the single brand response

    const { status, message, from } = await validateManualyPageId(manualyPageId);

    if (status !== "success") {
      setError({ message, type: "danger" });
      setTimeout(() => {
        setError({ message: "", type: "" });
      }, 4000);
    } else {
      const { name, id } = from;
      const isBrandExists = brands.find((brand) => brand.page_id === id);

     if (!isBrandExists){
      //add new brand to store
      dispatch({
        type: CONSTANTS.AddSingleBrand,
        payload:{
          brand_name:name,
          page_id:id,
          isActive:true
        }
      })

     }
        
      else {
        setError({ message: `${name} already exists in the list`, type: "success" });
        setTimeout(() => {
          setError({ message: "", type: "" });
        }, 4000);
      }
    }

    setManualyPageId("");
  };

  
  const onCancelProcess = () => {
    setIsCompareOpen(false)
    setIsCompare(false)
    dispatch({
      type: CONSTANTS.ResetBrandsAndPostsToCompare,
      payload: { reset:true },
    })
    dispatch({
      type: CONSTANTS.SetAfterCompare,
      payload: { afterCompare: false },
    })
  } 

  return (
    <>
     { !isToNextStep ? (
       <>
        <Button> 
          <form style={{display : 'flex'}} autoComplete="on" onSubmit={handleMouseClick}>
            <PlusInput type="submit" className="pointer">
             <img src={assets["add"]} alt="add btn" />
            </PlusInput>
            <InputCompare
              value={manualyPageId}
              type="text"
              name="brandId"
              placeholder="Enter brand ID here or choose one"
              onChange={(e) => setManualyPageId(e.target.value)}
              //onKeyPress={handleKeyPress}
              autoFocus={true}
            />
          </form>
        </Button>
       <BrandsContainer>
       <CompareBrandsPick/> 
       </BrandsContainer>
       <CompareFooter>
        <CompareFooterContainer>
        <ProcessButton onClick={toggleNext}>
           <p>Next  </p>
         </ProcessButton>
        <CancelButton onClick={onCancelProcess}>
           <p>Cancel</p>
         </CancelButton>

        </CompareFooterContainer>
        </CompareFooter>
       </>

     ) : (
       <>
    
      <ComparePostsPick/>
      <CompareFooter>
        <CompareFooterContainer>
        {!isLoading ?
        <>
        <ProcessButton onClick={toggleCompare}>
           <p>Compare {report.postsToCompare.length} posts  </p>
         </ProcessButton>
        <CancelButton onClick={onCancelProcess}>
           <p>Cancel</p>
         </CancelButton>
        </>
        : 
        <CompareLoader className="loader" src={assets["loader"]} alt="" />
        }
        

        </CompareFooterContainer>
        </CompareFooter>
      </>
     ) }
      
       </>
  );
};

export default CompareProcess;
