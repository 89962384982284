export const tabs = [
  {
    title: 'General',
    type: 'general',
  },
  {
    title: 'Competitors',
    type: 'competitors',
  },
  {
    title: 'Products',
    type: 'products',
  },
  {
    title: 'Action Persuation',
    type: 'actionPersuation',
  },
  {
    title: 'Purchase Intent',
    type: 'purchaseIntent',
  },
  {
    title: 'Concerns',
    type: 'concerns',
  },
  {
    title: 'Other words',
    type: 'other',
  },
];
