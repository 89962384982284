import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../../store/context';
import { addCompare, Tabs, Tab } from '../Styles';
import { assets } from '../../../../utils/assets';
import { InputCompare } from '../../../../utils/CommonStyles';
import ComparePostsTable from './ComparePostsTable';

const {
  Button,
  PlusInput,
  WrapperTab
} = addCompare
const ComparePostsPick = () => {
  const {
    state: { report }
  } = useContext(AppContext);
  const { brandsToCompare } = report;
  const [currentTab, setCurrentTab] = useState("");
  const [currentPageId, setCurrentPageId] = useState("")
  const [currentIsActive] = useState(true)
  useEffect(() => {
    if (brandsToCompare) {
      setCurrentTab(brandsToCompare[0].brand_name)
      setCurrentPageId(brandsToCompare[0].page_id)
    }

  }, [brandsToCompare]);

  const onSelectTab = (brandName: string, pageId: string) => {
    setCurrentTab(brandName)
    setCurrentPageId(pageId)
  }

  const handleMouseClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //handleAddBrand();
  };

  return (
    <>
      <WrapperTab>
        <Button>
          <form autoComplete="on" onSubmit={handleMouseClick}>
            <PlusInput type="submit" className="pointer">
              <img src={assets["add"]} alt="add btn" />
            </PlusInput>
            <InputCompare
              type="text"
              name="brandId"
              placeholder="Enter Post ID here or choose one"
              autoFocus={true}
            />
          </form>
        </Button>
        <Tabs>
          {brandsToCompare.map(({ brand_name, isActive, page_id }, index) => (
            <Tab
              className="pointer"
              isSelected={currentTab === brand_name}
              onClick={(e) => onSelectTab(brand_name, page_id)}
              key={index}
            >

              <span>{brand_name}</span>
            </Tab>
          ))}

        </Tabs>
      </WrapperTab>
      {currentPageId.length && <ComparePostsTable brandName={currentTab} pageId={currentPageId} dateRange={report.dateRange} isActive={currentIsActive} />}
    </>
  );
};

export default ComparePostsPick;
