import styled from 'styled-components';
import { colors } from '../../../../utils';
import { Icon } from '../../utils/CommonStyles';

export const TableStyled = styled.table<{ isActive?: boolean }>`
  border-collapse: separate;
  text-align: left;
  margin-bottom: auto;
  border-spacing: 0 8px;
  width: 1000px !important;
  td {
    background-color: 'rgba(52, 115, 226, 0.1)';
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-style: solid none;
    position: relative;
    padding: 0 25px 0 0px;
  }

  td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 12px;
    min-width: 40px;

    input {
      position: absolute;
      top: 13px;
    }
  }

  td:nth-child(2) {
    width: 750px !important;
    .word {
      display: flex;
      margin-left: 8px;
      max-width: 300px !important;

      ${Icon} {
        margin-right: 20px;
      }
    }
  }

  td:last-child {
    width: 20%;
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  tr {
    font-size: 0.8125rem;
    text-align: left;
    height: 40px;
    margin-bottom: 8px;
    position: relative;
  }

  th {
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.0625rem;
    color: rgba(51, 59, 80, 0.6);
  }

  th:first-child {
    padding-left: 13px;
  }
`;

export const CommentsTableStyled = styled.table`
  border-collapse: separate;
  text-align: left;
  margin-bottom: auto;
  margin-top: -25px;
  border-spacing: 0 8px;
  td {
    position: relative;
    height: 40px;
    padding: 11px;
    font-size: 0.75rem;
    font-weight: 400;
    color: ${colors.darkBlue};
    background: ${colors.white};
  }

  td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  tr {
    font-size: 0.8125rem;
    text-align: left;
    height: 40px;
    margin-bottom: 8px;
  }

  th {
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.0625rem;
    color: rgba(51, 59, 80, 0.6);
  }

  th:first-child {
    display: none;
  }
`;
