import styled from 'styled-components';
import { colors } from '../../../utils';

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const CenterText = styled.div`
  text-align: center;
  & .title {
    display: block;
    font-weight: 600;
    font-size: 1.5625rem;
    color: ${colors.darkGrey};
  }

  & .thin {
    font-weight: 500;
    font-size: 13px;
    color: ${colors.titleDescription};
  }
`;
