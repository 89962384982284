import moment from "moment";
import { POSTS_GROUP_AVERAGES, SINGLE_POST_DETAILED_RESPONSE } from "../../../../store/interface";

export const parsePostByGroup = (averages: POSTS_GROUP_AVERAGES): SINGLE_POST_DETAILED_RESPONSE => {
  const newPostObj = getEmptyPostObj();

  for (let key in averages) {
    if (newPostObj.hasOwnProperty(key)) {
      newPostObj[key] = averages[key];
    }
  }

  return newPostObj;
}

const getEmptyPostObj = (): SINGLE_POST_DETAILED_RESPONSE => {
  return {
    brand: {
      id: '',
      name: '',
    },
    comments: [],
    commentsLength: 0,
    reactions: {
      angry: 0,
      care: 0,
      haha: 0,
      like: 0,
      love: 0,
      pride: 0,
      sad: 0,
      shares: 0,
      thankful: 0,
      totalReactions: 0,
      wow: 0,
    },
    picture: '',
    totalComments: 0,
    totalCommentsWithNameTags: 0,
    totalCommentsWithReplies: 0,
    averageSentimentScore: 0,
    positiveComments: 0,
    neutralComments: 0,
    negativeComments: 0,
    wordFrequencies: {},
    reactionsSentiments: {
      positiveReactions: 0,
      veryPositiveReactions: 0,
      negativeReactions: 0,
    },
    postDetails: {
      story: '',
      created_time: '',
      message: '',
      id: '',
    },
    type: 'facebook',
    mentionsAndIntent: {
      productsCount: 0,
      purchaseCount: 0,
      competitorsCount: 0
    }
  }
}

export const dateToAmericanFormat = (localDateString: string): Date => {
  const dateMomentObject = moment(localDateString, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
  const dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
  return dateObject;
}