import { DATE_RANGE, PARSED_POST, SINGLE_POST_DETAILED_RESPONSE } from '../interface';
import { fetchPostsBasicInfo, fetchPostsByAdAccountsIdsFromApi, fetchPostsByBrandsIdsFromApi, fetchPostsDetails } from '../../api/facebookApi';
import { CONSTANTS } from '../constants';
import { Dispatch } from 'react';
import { WORD_TAGS } from '../interface';
import { PARSED_ACCOUNT, PARSED_BRAND } from '../../components/ui/sidebar/interface';

export const fetchAndSetPostsByBrands = async (
  brands: PARSED_BRAND[],
  dateRange: DATE_RANGE,
  dispatch: Dispatch<{
    type: CONSTANTS.AddPosts;
    payload: {
      posts: PARSED_POST[];
    };
  }>
) => {
  try {
    const postsByBrandsIds = await fetchPostsByBrandsIdsFromApi(brands, dateRange);
    let parsedPostsArray = [] as PARSED_POST[];
    postsByBrandsIds.forEach((response) => {
      response.data.forEach((post) => {
        parsedPostsArray.push({ ...post, isActive: false });
      });
    });

    dispatch({
      type: CONSTANTS.AddPosts,
      payload: { posts: parsedPostsArray },
    });
  } catch (error) { }
};

export const fetchAndSetPostsByAdAccounts = async (
  accounts: PARSED_ACCOUNT[],
  dateRange: DATE_RANGE,
  dispatch: Dispatch<{
    type: CONSTANTS.AddPosts;
    payload: {
      posts: PARSED_POST[];
    };
  }>
) => {
  try {
    const postsByAdAccountIds = await fetchPostsByAdAccountsIdsFromApi(accounts, dateRange);
    let parsedPostsArray = [] as PARSED_POST[];
    postsByAdAccountIds.forEach((response) => {
      response.data.forEach((post) => {
        parsedPostsArray.push({ ...post, isActive: false });
      });
    });

    dispatch({
      type: CONSTANTS.AddPosts,
      payload: { posts: parsedPostsArray },
    });
  } catch (error) { }
};

export const addPost = async (
  postId: string,
  dispatch: Dispatch<{
    type: CONSTANTS.AddPost;
    payload: {
      post: PARSED_POST;
    };
  }>
) => {
  try {
    const singlePostResponse = await fetchPostsBasicInfo(postId);
    dispatch({
      type: CONSTANTS.AddPost,
      payload: { post: { ...singlePostResponse, isActive: true } },
    });
  } catch (error) { }
};

export const deletePost = async (
  postIndex: number,
  dispatch: Dispatch<{
    type: CONSTANTS.RemovePost;
    payload: {
      postIndex: number;
    };
  }>
) => {
  try {
    dispatch({
      type: CONSTANTS.RemovePost,
      payload: { postIndex },
    });
  } catch (error) { }
};

export const updateData = async (
  posts: PARSED_POST[],
  word_tags: WORD_TAGS,
  dispatch: Dispatch<{
    type: CONSTANTS.SetPostsData;
    payload: {
      posts: SINGLE_POST_DETAILED_RESPONSE[];
    };
  }>
) => {
  try {
    const detailedPosts = await fetchPostsDetails(posts, word_tags);
    dispatch({
      type: CONSTANTS.SetPostsData,
      payload: { posts: detailedPosts },
    });
  } catch (error) { }
};
