import styled from 'styled-components';
import { colors } from '../../../utils';
import { Input } from '../utils/CommonStyles';

const MainWrapper = styled.div<{ collapsed: boolean }>`
  background-color: ${colors.white};
  border-right: 1px solid ${colors.sidebarBackground};
  display: flex;
  height: 100%;
  width: ${({ collapsed }) => (collapsed ? '60px' : '323px')};

  .logos {
    margin-top: 100px;

    .logo {
      &.collapsed {
        margin-top: 40px;
        cursor: pointer;
      }
      &.background {
        background-color: #D2E4FC;
        width: 24px;
        height: 26px;
        border-radius: 10px;
      }
    }
  }
  .seperator {
    border-bottom: 1px solid rgb(77, 86, 111, 0.2);
    margin-bottom: 20px;

    &.black {
      border-bottom: 1px solid black;
    }

    &.white {
      border-bottom: 1px solid white;
      margin-top: 28px;
    }
  }
`;

const Container = styled.div<{ collapsed: boolean }>`
  position: relative;
  display: ${({ collapsed }) => (collapsed ? 'none' : 'flex')};
  flex-direction: column;
  width: 263px;
  height: 100%;
  padding: 0 12px;
  & .fetch {
    margin-top: auto;
  }

  .loader {
    width: 30px;
    margin: auto;
  }
`;

const Header = styled.div`
  margin: 25px 21px 3px;
  height: 92px;

  & .minimize {
    position: absolute;
    left: 100%;
    transform: translateX(-50%);
    top: 28px;
    width: 24px;
    height: 24px;
    z-index: 2;
  }
`;

const Title = styled.span`
  font-size: 1.5625rem;
  color: ${colors.darkBlue};
`;

const TitleDescription = styled.span`
  display: block;
  margin-top: 7px;
  font-size: 0.75rem;
  line-height: 1.0625rem;
  color: ${colors.titleDescription};
`;

const InputContainer = styled.div<{ disabled?: boolean; selected?: boolean }>`
  display: flex;
  align-items: center;
  width: 239px;
  height: 40px;
  margin-bottom: 16px;
  border: 1px solid ${colors.greyLight};
  border-radius: 5px;
  padding: 0 14.42px 0 21.5px;
  background-color: ${({ selected }) => selected && colors.lightBlue};
  opacity: ${({ disabled }) => disabled && 0.2};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  & ${Input} {
    height: 100%;

    &.sidebar {
      margin-left: 11.5px;
      font-size: 0.8125rem;
      line-height: 1.5rem;
      color: ${colors.darkBlue};
    }
  }

  & span {
    margin-left: 11.5px;
    font-size: 0.8125rem;
    line-height: 1.5rem;
    color: ${colors.darkBlue};
  }

  & .arrow-right {
    margin-left: auto;
    cursor: pointer;
  }

  .center {
    margin: 0 auto;
  }

  .date-input {
    background-color: inherit;
    width: 170px;
    margin: 0 5px;
    border: none;
  }

  .loader {
    background: ${colors.layoutBackground};
    width: 30px;
    margin: auto;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SignOutContainer = styled.div`
  height: 81px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgb(77, 86, 111, 0.2);
  width: 100%;
  padding: 0 10px;
  & .sign-out {
    margin-left: auto;
  }
`;

const Rectangle = styled.div<{ collapsed: boolean }>`
  width: 60px;
  background-color: ${colors.rectangle};
  position: relative;
  .logo {
    margin: 28px auto 0;
    display: block;
  }

  & .minimize {
    transform: rotate(120deg) !important;
    -webkit-transform: rotate(180deg) !important;
    -moz-transform: rotate(180deg) !important;
    -ms-transform: rotate(180deg) !important;
    -o-transform: rotate(180deg) !important;

    display: ${({ collapsed }) => (collapsed ? 'block' : 'none')};
    position: absolute;
    left: 80%;
    transform: translateX(-50%);
    top: 28px;
    width: 24px;
    height: 24px;
    z-index: 2;
  }
`;

const UserSquare = styled.div`
  font-family: Museo Sans; //missing
  position: absolute;
  bottom: 23px;
  left: 13px;
  width: 30px;
  height: 30px;
  font-weight: 600;
  font-size: 0.95rem;
  line-height: 1.875rem;
  text-align: center;
  color: ${colors.darkGrey};
  border-radius: 50%;
  background-color: ${colors.lightBlue};
`;

export const stylesAndAssets = {
  MainWrapper,
  Container,
  Header,
  Title,
  TitleDescription,
  InputContainer,
  SignOutContainer,
  Rectangle,
  UserSquare,
};
