import styled from 'styled-components';
import { colors } from '../../../../utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 700px;
`;

export const SearchWrapper = styled.div`
  margin-left: auto;
  position: absolute;
  top: -84px;
  left: 450px;
  input{
    width: 260px;
    padding: 6px;
    border-radius: 5px;
    border: 1px solid ${colors.greyLight};
    background: ${colors.layoutBackground};
  }
`
export const IconSearch = styled.img`
    position: relative;
    top: 1px;
    right: 28px;
    cursor: pointer;
`