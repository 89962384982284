import styled from "styled-components";
import { colors } from "../../../../../utils";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .chart-headline {
    font-size: 21px;
    padding-left: 36px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

export const SentimentBreakDownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  .chart-headline {
    font-size: 21px;
    padding-left: 36px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

export const SentimentWrapper = styled.div`
  width: 90%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  .sentiment-title {
    font-size: 12px;
    font-weight: bold;
    color: #2A2F4A;
    margin-top: 26px;
    margin-left: 26px;
  }
  margin-bottom: 55px;

  .score-and-btn-container{
    display: flex;
    padding-bottom: 30px;
    
    .collapse-btn{
      width: 10%;
      margin: 20px auto auto auto;
      display: flex;
      height: 30px;
      color: #2A2F4A;

      .btn-text{
        font-size: 13px;
        margin: auto 16px auto 0;
        color: #2A2F4A;
      }
    }
  }
`;

export const IconRotationWrapper = styled.div<{collapse: boolean}>`
  transform: ${({collapse}) => collapse ? '0' : 'rotate(0.5turn)'};
  display: flex;

  >.collapse-icon{
    margin: auto;
    padding: 0 7px;
  }
`;

export const ScoreWrapper = styled.div<{maxHeight: string}>`
  display: flex;
  flex-wrap: wrap;
  margin-left: 26px;
  width: 90%;
  max-height: ${({maxHeight}) => maxHeight};
  overflow: hidden;
  /* margin-top: 28px; */
`;

export const ScoreDiv = styled.div`
  display: flex;
  margin-right: 25px;
  margin-top:20px;
  p {
    font-size: 13px;
    margin: auto;
    padding: 0;
    margin-left: 10px;
    font-weight: 500;
    color: #2A2F4A;
  }
`;

export const WrapperFlex = styled.div`
  display: flex;
  width: 96%;
  margin-left: 45px;
  margin-top: 30px;
  margin-bottom: 80px;
  padding-top: 30px;
`;

export const ScoreBrand = styled.div`
  display: flex;
  margin-top: 20px;
  margin-right: 25px;
  p {
    font-size: 13px;
    margin: auto;
    padding: 0;
    margin-left: 10px;
    font-weight: 500;
    color: #2A2F4A;
  }
`;

export const ScoreBrandSentiment = styled.div`
  border: 1px solid #2a2f4a;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px;
  font-size: 17px;
  display: flex;
  height: 30px;

  .star-img-wrapper{
    margin: auto 5px;
    display: flex;
  }

  .average-img-wrapper{
    display: flex;
  }
`;

export const ProductMentionsWrapper = styled.div`
  display: block;
`;

export const PurchaseIntentWrapper = styled.div`
  display: block;
`;

export const BrandMentionsWrapper = styled.div`
  display: block;
`;

export const ChartHeadline = styled.p`
  color: ${colors.darkBlue};
  font-weight: 600;
  font-size: 18px;
  display: flex;
`;

export const Seperator = styled.div`
    width: 95%;
    height: 1px;
    opacity: 0.2;
    border-top: 1px solid #4D566F;
    margin: auto;
`;