import { useContext, useState, useEffect, ChangeEvent } from "react";
import { useHistory } from "react-router";
import EditAlertsTable from "./EditAlertTable";
import { assets } from "../../../ui/utils/assets";
import { TitleWrapper, IconBack, PostDetailsWrapper, 
    PostImg, IconImg, PostContent, ViewSource, FreqWordWrapper,
    AlertDetailsWrapper, SmallDetails, MainDetails, TitleTotal,
    TitleText, TitleCount, SingleTotalIconAlert,
    FreqWord, TotalAlerts, Wrapper, CommentsActions,
    Button, Icon, IconEye, PopUpWrapper, PopUpBox, PopUpInner, Actions,
    Cancel, YesVerify, YesHide,
    InnerSmallDetails, InnerTypeIcon, TotalAlertsDetails,SingleTotalAlertDetails,
    SingleTotalAlertName, SingleTotalAlertCount,SearchWrapper,IconSearch
} from './Styles'
import { AppContext } from "../../../../store/context";
import { CONSTANTS } from "../../../../store/constants";
import { POST_ALERTS, POST_ALERT_WORD, ALERT_ICON, COMMENT_ALERT, EDIT_POST_ALERT_WORD } from "../../../../store/interface";
import { fetchVerifyAndHideAlerts, fetchWordBank } from "../../../../api/facebookApi";
import { CompareLoader } from "../Styles";
const EditAlert = (props : any) => {
    const history = useHistory();
    const {
        state: { alert },
        dispatch
      } = useContext(AppContext);
    const { editPostAlert, alertEditPostCommentsCollection } = alert
    const [openPopUp,setOpenPopUp] = useState(false)
    const [actionType, setActionType] = useState('')
    const [postAlertedWords, setPostAlertedWords] = useState<POST_ALERT_WORD[]>()
    const [alertIcons, setAlertIcons] = useState<ALERT_ICON[]>();
    const [loadingVerifyHide, setLoadingVerifyHide] = useState(false);
    const postDetails = editPostAlert.post_details as POST_ALERTS
    const [editPostData, setEditPostData] = useState<POST_ALERTS>(postDetails);
    const [editPostAlertsData, setEditPostAlertsData] = useState<COMMENT_ALERT[]>()
    const [originalEditPostAlertsData, setOriginalEditPostAlertsData] = useState<COMMENT_ALERT[]>()
    const [totalAlertsCount, setTotalAlertsCount] = useState<number>()
    const [searchField, setSearchField] = useState("");
    const postSource = `https://facebook.com/${editPostAlert.post_id}`
    let AlertIconsHelper:ALERT_ICON[] = [
      {
        iconName:'Dangerous',
        id:3,
        count:0
      },
      {
        iconName:'Offensive',
        id:2,
        count:0
      },
      {
        iconName:'Other',
        id:1,
        count:0
      },
      {
        iconName:'Contact',
        id:0,
        count:0
      },
    ]
    useEffect(()=>{
      const getWordBank = async () => {
        
        const wordBank:POST_ALERT_WORD[] = await fetchWordBank()
        const alertedWords:EDIT_POST_ALERT_WORD[] = []
       
        
        postDetails.alerted_words.forEach((wordAlert)=>{
           wordBank.forEach((word)=>{
             if ( word.word === wordAlert ){
               alertedWords.push({
                 word:word.word,
                 category:word.category,
                 count:0
               })
             }
           })
        })

        postDetails.alert.forEach((alert)=>{
          for (let i=0; i< alertedWords.length; i++){
            let word:string = alertedWords[i].word
            if (alert.message.includes(word)){
              alertedWords[i].count++
            }
          }
        })
    
        let alertCount = 0
        alertedWords.forEach((word,index) => {
          AlertIconsHelper.forEach((alertIcon)=>{
             if ( word.category === alertIcon.id ){
                alertIcon.count = word.count
                alertCount +=word.count
             }
           })
        })
        setEditPostData(postDetails)
        setPostAlertedWords(alertedWords)
        setEditPostAlertsData(postDetails.alert)
        setOriginalEditPostAlertsData(postDetails.alert)
        setAlertIcons(AlertIconsHelper)
        setTotalAlertsCount(alertCount)
      }
      getWordBank()
    },[]) 

   

    const actionTypes = {
        Verify: 'Verify',
        HiddenEye: 'HiddenEye'
      }
    const verifyAndHiddenHandle = (actionType:string)=>{
        setOpenPopUp(true)
        setActionType(actionType)
    }

    const sendVerifyAndHide = async (actionType: string) => {
        const ids: string[] = [];
        alertEditPostCommentsCollection.forEach((alertComment) => {
          ids.push(alertComment.alertComment_id);
        });
        setLoadingVerifyHide(true);
        const sendAction = await fetchVerifyAndHideAlerts(actionType,ids)
    
        if ( sendAction ) { 
         editPostAlertsData?.map((comment,indx)=>{
             if (ids.indexOf(comment.comment_id) !== -1) {
               return editPostAlertsData.splice(indx,1)
             }
          })
          setEditPostAlertsData(editPostAlertsData);
          setLoadingVerifyHide(false);
          dispatch({
            type: CONSTANTS.ResetAllCommentCollection,
            payload: null,
          });
          window.alert(`${actionType} action had passed successfully!`)
          setOpenPopUp(false);
        } else {
          window.alert('Something Went wrong')
          window.location.reload()
        }
          
    };

      const closePopUp = () =>{
        setOpenPopUp(false)
      }

    
    const handleBack = ()=> {
      dispatch({
        type: CONSTANTS.ResetEditPostAlert,
        payload: null,
      });
      dispatch({
        type: CONSTANTS.ResetAllCommentCollection,
        payload: null,
      });
     
      history.push('/alerts')
    }

    const handleSearchChange = (e:ChangeEvent<HTMLInputElement>) => {
      setSearchField(e.target.value);
    };

    const callSearch = () =>{
      if ( searchField.length > 0 ) {
        const SearchAlerts = editPostAlertsData?.reduce((prevAlerts:COMMENT_ALERT[],alert)=>{
          if ( alert.comment.message.includes(searchField) ) {
            prevAlerts.push(alert)
          }
          return prevAlerts
        },[])
        setEditPostAlertsData(SearchAlerts)
       
        if ( SearchAlerts?.length === 0) {
          const originalSearchAlerts = originalEditPostAlertsData?.reduce((prevAlerts:COMMENT_ALERT[],alert)=>{
            if ( alert.comment.message.includes(searchField) ) {
              prevAlerts.push(alert)
            }
            return prevAlerts
          },[])
          setEditPostAlertsData(originalSearchAlerts)
        }
      } else {
        setEditPostAlertsData(originalEditPostAlertsData)
      }
    }

    const CompareAllHandleCheckBox = (checkBoxValue: boolean) => {
      editPostAlertsData?.forEach((alert)=>{
        dispatch({
          type: CONSTANTS.AddEditPostAlertComment,
          payload: { alertComment_id: alert._id, isActive: checkBoxValue },
        });
      })
    };

    const resetSearch = () => {
      setSearchField('')
      setEditPostAlertsData(originalEditPostAlertsData)
    }

    return ( 
        <>
        <TitleWrapper>
          <div className="back-btn" onClick={handleBack}>
                <IconBack src={assets['arrowLeft']}/>
            </div>
            <div className="title">
             Edit post alerts
            </div>
            <SearchWrapper>
              <input 
              type="text" 
              placeholder = "Search comments" 
              onChange = {handleSearchChange}
              value={searchField}
              name="" 
              id="" />
              {searchField && (
                <IconSearch onClick={resetSearch} style={{marginLeft:'-8px',right:'34px'}} src={assets['close']} />
              )}
              <IconSearch onClick={callSearch} src={assets['searchIcon']}/>
            </SearchWrapper>
        </TitleWrapper>

        <Wrapper>
            <AlertDetailsWrapper>
            <PostDetailsWrapper>
                <SmallDetails>
                <InnerSmallDetails date >
                        {editPostData!.created_time}
                    </InnerSmallDetails>
                    <InnerSmallDetails icon>
                        <InnerTypeIcon src={assets[editPostData!.type]} />
                    </InnerSmallDetails>
                    <InnerSmallDetails icon>
                        {editPostData!.brand_name}
                    </InnerSmallDetails>
                    <InnerSmallDetails postId>
                        {editPostAlert.post_id}
                    </InnerSmallDetails>
                </SmallDetails>
                <MainDetails>
                    <PostImg src={editPostData!.picture} />
                    <PostContent>
                        {editPostData!.message ? editPostData!.message : 'This post has no title'}
                    </PostContent>
                    <ViewSource href={postSource} target="_blank">
                        <IconImg src={assets['ViewSource']} /> View source
                    </ViewSource>
                </MainDetails>
                
            </PostDetailsWrapper>
            <FreqWordWrapper>
                {postAlertedWords && (
                  <>
                    {postAlertedWords.map((word,i)=>(
                      <FreqWord key={i} category={word.category}>{word.word}</FreqWord>
                    ))}
                  </>
                )}
                
            </FreqWordWrapper>
            
            </AlertDetailsWrapper>

            <TotalAlerts>
                <TitleTotal>
                    <SingleTotalIconAlert src={assets['AlertBlack']}/>
                    <TitleText>
                    Total alerts
                    </TitleText>
                    <TitleCount>
                        {totalAlertsCount}
                    </TitleCount>
                </TitleTotal>
                <TotalAlertsDetails>
                  {alertIcons && (
                    <>
                    {alertIcons.map((icon, index)=>{
                    return (
                      <SingleTotalAlertDetails>
                       <SingleTotalIconAlert src={assets[icon.iconName]}/> 
                       <SingleTotalAlertName> 
                         {icon.iconName} 
                         </SingleTotalAlertName>
                       <SingleTotalAlertCount>
                         {icon.count}
                         </SingleTotalAlertCount>
                     </SingleTotalAlertDetails>
                    )
                  })} 
                    </>
                  )}
                </TotalAlertsDetails>
            </TotalAlerts>
        </Wrapper>
        
        {alertEditPostCommentsCollection.length > 0 && (
        <CommentsActions>
          <p>
            {alertEditPostCommentsCollection.length} comments selected
          </p>
          <Button onClick={()=>verifyAndHiddenHandle(actionTypes.Verify)}>
          <Icon src={assets[actionTypes.Verify]} />
          </Button>
          <Button onClick={()=>verifyAndHiddenHandle(actionTypes.HiddenEye)} >
          <IconEye src={assets[actionTypes.HiddenEye]}  />
          </Button>
          
        </CommentsActions>
        )} 
        { (postAlertedWords && editPostAlertsData) && (
        <>
          <EditAlertsTable
          words={postAlertedWords}
          data={editPostAlertsData}
          handleAllCommentCheck={CompareAllHandleCheckBox}
          />
        </>
      )}
       

        {openPopUp && alertEditPostCommentsCollection.length >0 && (
        <PopUpWrapper>
         {!loadingVerifyHide ? (
              <>
                {actionType === actionTypes.Verify && (
                  <PopUpBox>
                    <PopUpInner>
                      <p>
                        Verify ({alertEditPostCommentsCollection.length}) Comments?{" "}
                      </p>
                      <Actions>
                        <Cancel onClick={closePopUp}> Cancel </Cancel>
                        <YesVerify onClick={() => sendVerifyAndHide("verify")}>
                          <Icon
                            className="icon-img"
                            src={assets["verifyGreen"]}
                          />{" "}
                          Yes Verify
                        </YesVerify>
                      </Actions>
                    </PopUpInner>
                  </PopUpBox>
                )}

                {actionType === actionTypes.HiddenEye && (
                  <PopUpBox>
                    <PopUpInner>
                      <p>Hide ({alertEditPostCommentsCollection.length}) Comments? </p>
                      <Actions className="yes-hide-wrapper">
                        <Cancel onClick={closePopUp}> Cancel </Cancel>
                        <YesHide onClick={() => sendVerifyAndHide("hide")}>
                          <Icon className="icon-img" src={assets["HideRed"]} />{" "}
                          Yes Hide
                        </YesHide>
                      </Actions>
                    </PopUpInner>
                  </PopUpBox>
                )}
              </>
            ) : (
              <>
                <CompareLoader
                  className="loader"
                  src={assets["loader"]}
                  alt=""
                />
              </>
            )}
        </PopUpWrapper>
      )}    
        
        </>
    )
}

export default EditAlert