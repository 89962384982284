import { useState, useContext, useEffect } from "react"
import { AppContext } from '../../../../../../store/context';
import { PARSED_POST } from '../../../../../../store/interface';
import { assets } from '../../../../utils/assets';
import { addCompare } from '../Styles';
import { fetchPostsByBrandsIdsFromApi } from '../../../../../../../src/api/facebookApi'
import { PARSED_BRAND } from "../../../../sidebar/interface";
import { CONSTANTS } from "../../../../../../store/constants";
import ComparePostsDisplay from './ComparePostsDisplay';

const {
    TableOverflow,
    CompareLoader
} = addCompare


const ComparePostsTable = ({
    brandName,
    pageId,
    dateRange,
    isActive
}: {
    brandName: string,
    pageId: string,
    dateRange: { endDate: Date, startDate: Date } | null,
    isActive: boolean
}) => {
    const [brandsPosts, setBrandsPosts] = useState<{ data: PARSED_POST[] }[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [isAllStateChecked, setIsAll] = useState(false);
    const {
        state: { report },
        dispatch
    } = useContext(AppContext);

    const checkIsAllActive = (array : PARSED_POST[]) => {
        const activePosts = array.find(post=> !post.isActive)
        return !!!activePosts    
    }


    useEffect(() => {
        if (dateRange) {
            const brandData = async () => {
                const brandResponse: PARSED_BRAND = {
                    page_id: pageId,
                    brand_name: brandName,
                    isActive: isActive
                }
                setIsLoading(true)
                const fetchPostsBrand = await fetchPostsByBrandsIdsFromApi([brandResponse], dateRange)
                if (fetchPostsBrand[0] && fetchPostsBrand[0].data && report.postsToCompare) {
                    for (let brandPost of fetchPostsBrand[0].data) {
                        for (let post of report.postsToCompare) {
                            if (post.id === brandPost.id && post.isActive) {
                                brandPost.isActive = true
                                break;
                            }
                        }
                        if (checkIsAllActive(fetchPostsBrand[0].data)){
                            setIsAll(true)
                        } else {
                            setIsAll(false)
                        }
                    }
                }

                setBrandsPosts(fetchPostsBrand);
                setIsLoading(false)
            }
            brandData()
        }
    }, [pageId, dateRange, brandName, isActive])

    const CompareHandleCheckBox = (postId: string, checkBoxValue: boolean) => {
        let DisActiveBrandsPosts = brandsPosts
        const postsToCompare = report.postsToCompare
        if (DisActiveBrandsPosts && postsToCompare) {
            DisActiveBrandsPosts[0].data.forEach((brandPost) => {
                if (postId === brandPost.id) {
                    brandPost.isActive = checkBoxValue
                }
            })
            setIsAll(DisActiveBrandsPosts[0].data?.every((brandPost) => brandPost.isActive))
        }
        dispatch({
            type: CONSTANTS.ComparePosts,
            payload: { id: postId, isActive: checkBoxValue },
        });

        setBrandsPosts(DisActiveBrandsPosts)
    };



    const CompareAllHandleCheckBox = (checkBoxValue: boolean) => {
        let ActiveAllBrandsPosts = brandsPosts

        if (ActiveAllBrandsPosts) {
            if (!isAllStateChecked) {
                ActiveAllBrandsPosts[0].data.forEach((brandPost) => {
                    if (!brandPost.isActive) {
                        brandPost.isActive = checkBoxValue
                        dispatch({
                            type: CONSTANTS.ComparePosts,
                            payload: { id: brandPost.id, isActive: checkBoxValue },
                        });
                    }
                })
                setIsAll(!isAllStateChecked)
            } else {
                ActiveAllBrandsPosts[0].data.forEach((brandPost) => {
                    brandPost.isActive = false
                    dispatch({
                        type: CONSTANTS.ComparePosts,
                        payload: { id: brandPost.id, isActive: checkBoxValue },
                    });
                })
                setIsAll(!isAllStateChecked)
                
            }

        }
        setBrandsPosts(ActiveAllBrandsPosts)
    };

    return (
        <>
            {brandsPosts ? (
                <TableOverflow>
                    {isLoading ? (
                        <CompareLoader className="loader" src={assets["loader"]} alt="" />
                    ) : (
                        <ComparePostsDisplay
                            data={brandsPosts[0].data}
                            handleCheckBox={CompareHandleCheckBox}
                            handleAllCheckBox={CompareAllHandleCheckBox}
                            isAllActive={isAllStateChecked}
                        />
                    )}

                </TableOverflow>

            ) : (
                <CompareLoader className="loader" src={assets["loader"]} alt="" />
            )}
        </>
    )
}


export default ComparePostsTable