import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "moment";
import { assets } from "../../../ui/utils/assets";
import Footer from "../Footer";
import { SINGLE_POST_DETAILED_RESPONSE } from "../../../../store/interface";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(251, 253, 255)",
    fontFamily: "Poppins",
    justifyContent: "space-between",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "30px 20px",
  },
  catImage: {
    height: "25px",
    width: "25px",
    marginRight: "15px",
  },
  catText: {
    fontSize: "30px",
  },
  content: {
    width: "100%",
    fontSize: "28px",
    paddingLeft: "50px",
    marginTop: "30px",
  },
  reportName: {
    fontFamily: "Poppins-Bold",
  },
  date: {
    fontSize: "14px",
  },
});

const Page1 = ({
  reportName,
  currentPost,
}: {
  currentPost: SINGLE_POST_DETAILED_RESPONSE;
  reportName: string;
}) => {
  const MyDoc = (
    <Page size="A4" style={styles.page} orientation="landscape">
      <View style={styles.header}>
        <Image style={styles.catImage} src={assets["catReport"]}></Image>
        <Text style={styles.catText}>Cat</Text>
      </View>
      <View style={styles.content}>
        <Text>Comment Analysis Tool</Text>
        <Text style={styles.reportName}>{reportName || "Report Name Here"}</Text>
        <Text style={styles.date}>{moment(new Date()).format("MMMM Do YYYY")}</Text>
      </View>
      <Footer currentPost={currentPost} />
    </Page>
  );

  return MyDoc;
};

// interface GET_PAGE_DATA {
//   reportName: string;
// }

// const getPage1Data = ({ reportName }: GET_PAGE_DATA) => {
//   const date = new Date();
//   const dateParsed = moment(date.toString()).format("MM-YYYY");
//   return {
//     text: "Comment Analysis Tool",
//     reportName,
//     dateParsed,
//   };
// };

export default Page1;
