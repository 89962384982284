import styled from 'styled-components';
import { colors } from '../../../utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const Container = styled.div`
  height: 526px;
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 530px;
  background-color: #f5f6f8;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Input = styled.input`
  background-color: white;
  border: none;
  &:focus {
    outline: none;
  }
  width: 70%;
  padding: 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 87px 0 0 0;
`;

export const Title = styled.h2`
  font-size: 27px;
  font-weight: 400;
`;

export const Icon = styled.img`
  height: 22px;
  width: 20px;
  margin: 0 4px 0 0;
`;

export const LoginButton = styled.button<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  margin-top: 44px;
  background-color: ${colors.tabTitle};
  width: 30%;
  height: 35px;
  color: white;
  font-size: 14px;
  border-radius: 6px;
  opacity: ${({ disabled }) => disabled && 0.4};
  cursor: ${({ disabled }) => disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};

  &:hover {
    cursor: pointer;
    background-color: ${colors.blue};
  }
  .add {
    width: 14px;
  }
`;

export const BorderText = styled.p`
  position: relative;
  top: 55px;
  font-size: 12px;
  right: 110px;
  color: #2a2f4a;
`;

export const InputContainer = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  border: 1px solid #e6edf6;
  width: 80%;
  max-width: 330px;
  background-color: white;
  margin-top: 44px;
  ${Input} {
    height: 100%;
    width: 100%;
  }

  & .badge {
    margin: 0 10px 0 0;
    width: 16px;
    height: 16px;
  }
`;

// export const InputContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   width: 100%;
// `;
