import React, { useContext, useEffect } from 'react';
import { ACCOUNNTS_POPUP_PROPS } from './interface';
import { FetchedAccountsContainer, SubAccountsContainer } from './Styles';
import styles from './AccountsPopup.module.css';
import { Input, GreenBox, Icon, PrimaryButton } from "./../../../ui/utils/CommonStyles";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './AccountsPopup.module.css';
import { CONSTANTS } from '../../../../store/constants';
import { AppContext } from '../../../../store/context';
import { ButtonsWrapper } from '../PopupGrid/Styles';
import { Link } from 'react-router-dom';
import { PostsType } from '../../sidebar/enums';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccountsPopup = ({
  setAccounts,
  setIsPopupOpen,
  accounts,
  setIsAccountsSelected,
  isAccountsSelected,
  setPostsTypeSelection,
}: ACCOUNNTS_POPUP_PROPS) => {
  const { dispatch } = useContext(AppContext);
  const hasOneAccountSelected = !!accounts.find(account => account.isActive || account.accounts.find(acc => acc.isActive))

  useEffect(() => {
    setIsAccountsSelected(hasOneAccountSelected);
    return () => { };
  }, [hasOneAccountSelected, setIsAccountsSelected]);
  const handleNext = async () => {
    if (!accounts.length) return;
    setPostsTypeSelection(PostsType.AD_ACCOUNTS);
    setIsAccountsSelected(hasOneAccountSelected);
    setIsPopupOpen(false);
  };

  const handleReset = () => {
    setAccounts((accounts => {
      const newAccounts = [...accounts];
      newAccounts.forEach((item, index) => {
        if (item.accounts.length > 0) {
          item.accounts.forEach(acc => {
            acc.isActive = false;
          })
        } else {
          item.isActive = false;

        }

      })
      return newAccounts;


    }));
    dispatch({ type: CONSTANTS.Reset, payload: null });
    setIsAccountsSelected(false);
    setIsPopupOpen(false);
  };

  const handleCheckBox = (params: any) => {
    console.log(params);
    setAccounts((accounts => {
      const newAccounts = [...accounts];
      newAccounts.forEach((item, index) => {
        if (item.accounts.length > 0) {
          item.accounts.forEach(acc => {
            if (acc.accountId === params.accountId) {
              acc.isActive = params.checkBoxValue
            }
          })
        } else {
          if (item.accountId === params.accountId) {
            item.isActive = params.checkBoxValue
          }

        }

      })
      return newAccounts;


    }));
  }


  return (
    <div className={styles.AccountsPopup}>
      <FetchedAccountsContainer>

        {accounts.map((account, index) => (
          <div key={index}>
            <div >
              {account.accounts.length ? (<Accordion className={styles.Accordion}
                elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={styles.AccordionSummary} >

                  {account.accountName}

                </AccordionSummary>
                <AccordionDetails className={styles.AccordionDetails}>
                  {account.accounts.map((subaccount) => (
                    <SubAccountsContainer key={subaccount.accountId}>
                      <input

                        type="checkbox"
                        checked={subaccount.isActive}
                        style={{ marginRight: "10px" }}
                        onChange={(e) => {
                          const params = {
                            accountId: subaccount.accountId,
                            checkBoxValue: e.target.checked,
                          };

                          handleCheckBox(params);
                        }}
                      />
                      {subaccount.accountName}

                    </SubAccountsContainer>
                  ))}
                </AccordionDetails>
              </Accordion>) : (<div ><input
                type="checkbox"
                checked={account.isActive}
                style={{ marginRight: "10px", marginBottom: 32 }}
                onChange={(e) => {
                  const params = {
                    accountId: account.accountId,
                    checkBoxValue: e.target.checked,
                  };


                  handleCheckBox(params);
                }}
              />
                {account.accountName} </div>)}


            </div>
          </div>
        ))}
      </FetchedAccountsContainer>
      {accounts.length > 0 && (
        <ButtonsWrapper>
          <Link to="/" onClick={handleReset} className="reset pointer">
            Reset
          </Link>
          {
            <PrimaryButton disabled={!isAccountsSelected} onClick={handleNext}>
              Next
            </PrimaryButton>
          }
        </ButtonsWrapper>
      )}
    </div>
  );

}

export default AccountsPopup;
