import { assets } from "../../../ui/utils/assets";
import { percentageCalc } from "../helper";
import { AlertIcon, BottomLine, Category, FreqWord, TotalAlerts, Wrapper, PaddingWrapper } from "./Styles";
import { TOP_CATEGORIES_WORDS } from "../../../../store/interface";

export const AlertHighlights = ({
  widget,
  widgetCount,
  topCategoryWord,
  totalAlerts,
}: {
  widget:string,
  widgetCount:number,
  topCategoryWord:TOP_CATEGORIES_WORDS
  totalAlerts: number;
}) => {
  const capitalizeFirstLetter = (str:string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const capitalizedWord = topCategoryWord && topCategoryWord.word && capitalizeFirstLetter(topCategoryWord.word);
  const capitalizedWidget = widget && capitalizeFirstLetter(widget);

  return (
    <>
      <Wrapper>
        <PaddingWrapper>
          <TotalAlerts>
          <span style={{ fontWeight: 800 }}>{widgetCount}</span> / 
          <span className="precentage-num">{percentageCalc(widgetCount, totalAlerts)}%</span>
          </TotalAlerts>
          <span className='total-amount-text' style={{ marginBottom: 15, fontSize: 12 }}>Total alerts</span>
          <Category>
            <AlertIcon src={assets[capitalizedWidget]} alt={capitalizedWidget} />
            <span style={{ fontSize: 14 }}>{capitalizedWidget}</span>
          </Category>
        </PaddingWrapper>
        <div
          className='top-words-wrapper'
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            backgroundColor: "white",
            marginTop:'12px'
          }}
        >
          <FreqWord category={capitalizedWidget}>
            <span style={{ fontSize: 13, fontWeight:400 }}>{topCategoryWord ? capitalizedWord : '-'} </span>
            <span>{topCategoryWord ? topCategoryWord.count : '0'}</span>
          </FreqWord>
          <BottomLine category={capitalizedWidget}></BottomLine>
        </div>
      </Wrapper>
    </>
  );
};
