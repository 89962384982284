import styled from 'styled-components';
import { colors } from './../../../../utils';
import { Input } from './../../../ui/utils/CommonStyles';




export const FetchedBrandsContainer = styled.div`
  padding: 19px 15px;
`;

export const Brand = styled.div`
  margin-bottom: 32px;
  display: inline-block;
`;


export const ErrorMessage = styled.div<{ type: string }>`
  color: ${({ type }) => (type === 'danger' ? colors.negative : colors.greenLight)};
  margin-left: 15px;
`;
