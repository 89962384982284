import { useContext, useState } from "react";
import { Redirect } from "react-router";
import { AppContext } from "../../../../../store/context";
import { Wrapper, Container, ContainerReactions } from "./Styles";

import CompareStart from "../CompareProcess/CompareStart";
import OverallSentiment from "../Sentiment/OverallSentiment";
import CommentsCompare from "../Comments/Comments";
import SentimentBreakDown from "../Sentiment/SentimentBreakDown";
import BrandMoreReactions from "../Reactions/MoreReactions";
import ReactionsBreakDown from "../Reactions/ReactionsBreakDown";
import MultipleBrandMoreReactions from "../Reactions/MultipleBrandMoreReactions";

const Brands = () => {
  const {
    state: { report },
  } = useContext(AppContext);
  const { currentPost } = report;
  const { detailsPostsToCompare } = report;
  const [isCompare, setIsCompare] = useState<boolean>(false);
  if (!currentPost) {
    return <Redirect to={`/${report.name}`} />;
  }

  return (
    <Wrapper>
      <div>
        <CompareStart setIsCompare={setIsCompare} />
        {!isCompare && (
          <>
            <OverallSentiment />
            <Container>
              <CommentsCompare total={currentPost!.totalComments} />
              <SentimentBreakDown
                positiveComments={currentPost!.positiveComments}
                negativeComments={currentPost!.negativeComments}
                neutralComments={currentPost!.neutralComments}
                isReportPage={true}
              />
            </Container>

            <ContainerReactions>
              {Object.keys(detailsPostsToCompare).length ? (
                <MultipleBrandMoreReactions isReportPage={true} />
              ) : (
                <BrandMoreReactions reactions={currentPost!.reactions} isReportPage={true} />
              )}

              <ReactionsBreakDown
                positiveComments={currentPost!.positiveComments}
                negativeComments={currentPost!.negativeComments}
                neutralComments={currentPost!.neutralComments}
                isReportPage={true}
              />
            </ContainerReactions>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default Brands;
