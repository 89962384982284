import { FunctionComponent, useContext, useEffect } from "react";
import Dropdown, { Option } from 'react-dropdown';
import { assets } from "../../../ui/utils/assets";
import { Category, WrapperTotal, TotalAlerts, Icon, PaddingWrapperTotal } from "./Styles";
import 'react-dropdown/style.css';
import { AppContext } from "../../../../store/context";
import { CONSTANTS } from "../../../../store/constants";
import { options } from "../helper";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { POST_ALERTS } from "../../../../store/interface";
import { DateChart } from "./interface";
import moment from "moment";
import { dateToAmericanFormat } from '../../../ui/post/utils/helper';

export const AlertTotal = ({
  totalAlerts,
  data
}: {
  totalAlerts: number;
  data: POST_ALERTS[] | undefined;
}) => {
  let chartData: { [index: string]: DateChart } = {};
  let chartDataArr: DateChart[] = [];
  const {
    dispatch
  } = useContext(AppContext);
  const defaultOption = options[0];

  useEffect(() => {
    const pickADate = (e: Option) => {
      dispatch({
        type: CONSTANTS.SetDatePickAlerts,
        payload: {
          datePick: e.value
        },
      });
    }
    pickADate(options[0])
  }, [dispatch])

  const pickADate = (e: Option) => {
    dispatch({
      type: CONSTANTS.SetDatePickAlerts,
      payload: {
        datePick: e.value
      },
    });
  }

  if (data && data.length) {
    data.forEach((alert) => {
      console.log(alert.created_time);
      const date = moment(dateToAmericanFormat(alert.created_time));
      const newDateFormat = date.format('MMM D')
      console.log(newDateFormat);
      if (chartData[alert.created_time]) {
        chartData[alert.created_time].amount++;
      } else {
        chartData[alert.created_time] = {
          name: newDateFormat,
          amount: 1,
          date: new Date(date.toISOString())
        }
      }
    })

    chartDataArr = Object.keys(chartData).map((item) => {
      return chartData[item];
    })

    chartDataArr.sort((o1, o2) => {
      return o1.date.getTime() - o2.date.getTime();
    });


  }

  const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={-15} y={10} 
          textAnchor="start"
          style={{fontSize:'10px'}}
          fill="#2B304A">{payload.value}</text>      </g>
    );
  };

  return (
    <>
      <WrapperTotal>
        <PaddingWrapperTotal>
          <TotalAlerts style={{ fontWeight: 'bold' }}>
            {totalAlerts}
          </TotalAlerts>

          <span style={{ marginBottom: 20, fontSize: 12 }}>Total alerts</span>
          <Icon src={assets["calendar"]} />
          <Dropdown className="alerts-dates"
            options={options}
            value={defaultOption.value}
            onChange={pickADate}
            placeholder="Select an option" />
          {chartDataArr && chartDataArr.length ? <BarChart className='date-chart' width={180} height={80} data={chartDataArr}>
            <XAxis interval={'preserveStartEnd'} dataKey="name" tick={<CustomizedAxisTick/>} axisLine={false} tickLine={{stroke: "#00000080", strokeWidth:'1px'}} />
            <Bar dataKey="amount" fill="#FF5A26" barSize={2} label="Height" />
          </BarChart> : ''}
        </PaddingWrapperTotal>



      </WrapperTotal>
    </>
  );
};
