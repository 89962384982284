import React, { createContext, useReducer, Dispatch } from 'react';
import { reportReducer } from './reducers/report-reducer';
import { alertReducer } from './reducers/alerts-reducer';
import {
  REPORT_STATE_TYPE,
  POST_ACTIONS,
  AUTH_STATE_TYPE,
  AUTH_ACTIONS,
  ALERT_STATE_TYPE,
  ALERT_ACTIONS
} from './interface';
import { actionWordsBank, concernWordsBank, purchaseWordsBank } from './wordbankHardCodedWords';
import { authReducer } from './reducers/auth-reducer';

type InitialStateType = {
  report: REPORT_STATE_TYPE;
  auth: AUTH_STATE_TYPE;
  alert: ALERT_STATE_TYPE;
};

const initialState = {
  report: {
    name: '',
    dateRange: null,
    posts: [],
    postsData: [],
    currentPost: null,
    currentPostsGroup: {groupName: '', posts:[]},
    groupExpended: false,
    allPostsChecked: false,
    isInitialized: false,
    wordBank: {
      competitors: [],
      products: [],
      others: [],
      actionPersuation: actionWordsBank,
      purchaseIntent: purchaseWordsBank,
      concerns: concernWordsBank,
    },
    brands:[],
    brandsToCompare:[],
    postsToCompare:[],
    detailsPostsToCompare:{},
    averageDetailsPostsToCompare: {},
    averagedPostCount:0,
    afterCompare: false,
    navBarCollaped: false
  },
  auth: {
    email: '',
    token: '',
    isTokenValid: false,
  },
  alert:{
    alertCommentsCollection:[],
    alertPostCommentsCollection:[],
    alertEditPostCommentsCollection:[],
    editPostAlert:{
      post_id:'',
      post_details:{
        _id:'',
        alert:[],
        story: '',
        created_time:'',
        brand_id:'',
        brand_name:'',
        totalComments:0,
        category:0,
        type:'',
        alerted_words:[]
      } 
    },
    datePick:'',
    wordBank:[]
  }
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<POST_ACTIONS | AUTH_ACTIONS | ALERT_ACTIONS>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = ({ report,auth, alert }: InitialStateType, action: POST_ACTIONS | AUTH_ACTIONS | ALERT_ACTIONS) => ({
  report: reportReducer(report, action),
  auth: authReducer(auth, action),
  alert: alertReducer (alert,action)
});

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export { AppProvider, AppContext };
