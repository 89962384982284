import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CONSTANTS } from "../../../../store/constants";
import { AppContext } from "../../../../store/context";
import Alert from "../../no-posts-alert/Alert";
import FrequenciesTable from "../../table/frequencies/FrequenciesTable";
import { tabs } from "../../tabs/word-freq/tabsData";
import WordFrequenicesTabs from "../../tabs/word-freq/WordFrequenicesTabs";
import { assets } from "../../utils/assets";
import { Icon } from "../../utils/CommonStyles";
import { getCurrentWordsByTab, toArray } from "./helper";
import { PARSED_WORD_FREQUENCY } from "./interface";
import { Wrapper, IconsContainer } from "./Styles";

const WordFrequenciesManager = () => {
  const [currentTab, setCurrentTab] = useState("general");
  const [currentWords, setCurrentWords] = useState<PARSED_WORD_FREQUENCY[]>([]);

  const {
    state: {
      report: { currentPost, wordBank },
    },
    dispatch,
  } = useContext(AppContext);

  const { wordFrequencies } = currentPost!;
  useEffect(() => {
    const newCurrentWords = getCurrentWordsByTab(wordFrequencies, currentTab, wordBank);
    const currentWordsAsArray = toArray(newCurrentWords);
    setCurrentWords(currentWordsAsArray);
  }, [currentTab, wordFrequencies, wordBank]);

  const handleCheckBoxForSingleWord = (currentWord: string, checkBoxValue: boolean) => {
    dispatch({
      type: CONSTANTS.SetSingleWordIsActive,
      payload: { word: currentWord, isActive: checkBoxValue },
    });
  };

  const setActiveStatusForAllWords = (checkboxValue: boolean, words: PARSED_WORD_FREQUENCY[]) => {
    dispatch({
      type: CONSTANTS.SetAllWordsIsACtive,
      payload: { isActive: checkboxValue, words },
    });
  };

  const handleDelete = () => {
    dispatch({
      type: CONSTANTS.DeleteActionForWordFrequencies,
      payload: {},
    });
  };

  const getActiveItems = useCallback(() => {
    let sum = 0;

    const { wordFrequencies } = currentPost!;
    for (const [key, value] of Object.entries(wordFrequencies)) {
      if (wordFrequencies[key].isActive) {
        sum += value.comments.length;
        continue;
      } else {
        const filteredCommentsCount = value.comments.filter(({ isActive }) => isActive).length;
        sum += filteredCommentsCount;
      }
    }

    return sum;
  }, [currentPost]);

  const activeItems = useMemo(() => getActiveItems(), [getActiveItems]);
  const currentTitle = tabs.filter((tab) => tab.type === currentTab)[0].title;
  return (
    <Wrapper>
      <IconsContainer>
        <span>selected items : {activeItems}</span>
        <Icon className="pointer" src={assets["addToReport"]} alt="" />
        <Icon className="pointer trash" src={assets["trash"]} onClick={handleDelete} />
      </IconsContainer>
      <WordFrequenicesTabs setCurrentTab={setCurrentTab} currentTab={currentTab} />
      {currentWords.length > 0 ? (
        <FrequenciesTable
          data={currentWords}
          handleCheckBoxForSingleWord={handleCheckBoxForSingleWord}
          setActiveStatusForAllWords={setActiveStatusForAllWords}
        />
      ) : (
        <Alert msg={`No words to display for ${currentTitle} `} />
      )}
    </Wrapper>
  );
};

export default WordFrequenciesManager;
