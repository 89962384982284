import { Wrapper, Container, PostContainer } from "./Styles";
import PostBox from "../../../ui/post/PostBox";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "../../../../store/context";
import { updateData } from "../../../../store/actions/report-actions";
import { Redirect } from "react-router";
import { assets } from "../../../ui/utils/assets";
import { SINGLE_POST_DETAILED_RESPONSE } from "../../../../store/interface";
import { CONSTANTS } from "../../../../store/constants";
import { CenterText } from "../../../ui/no-posts-alert/Styles";
import CheckBox from "../../../ui/checkbox/CheckBox";

const Posts = () => {
  const {
    state: { report },
    dispatch,
  } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);

  const { posts, postsData } = report;
  useEffect(() => {
    const shouldFetch = !postsData.length;
    if (!shouldFetch) return;
    const fetchData = async () => {
      setIsLoading(true);
      const selecetdPosts = posts.filter(({ isActive }) => isActive);
      await updateData(selecetdPosts, report.wordBank, dispatch);
      setIsLoading(false);
    };

    fetchData();
  }, [posts, dispatch, report.wordBank, postsData]);

  useEffect(()=>{
    dispatch({ type: CONSTANTS.EmptyCurrentPostsGroup, payload:{} })
  }, [dispatch])

  if (!posts.length) {
    return <Redirect to={`/${report.name}`} />;
  }

  const handlePostDelete = (postIndex: number, e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch({ type: CONSTANTS.RemovePostData, payload: { postIndex } });
  };

  const showCheckBoxes = postsData.length > 1;

  return (
    <Wrapper>
      <Container>
      {showCheckBoxes ? <CheckBox key={'checkbox-all'} checkBoxText={'select all'} value={false} posts={postsData} /> : ''}
        {isLoading ? (
          <img className="loader" src={assets["loader"]} alt="" />
        ) : postsData.length ? (
          postsData.map((post: SINGLE_POST_DETAILED_RESPONSE, i) => (
            // TODO: checkbox
            <PostContainer key={i}>
              {showCheckBoxes ? <CheckBox key={`checkbox-${i}`} checkBoxText={''} value={false} posts={[post]} /> : ''}
              <PostBox index={i} handlePostDelete={handlePostDelete} key={i} post={post} />
            </PostContainer>
          ))
        ) : (
          <CenterText style={{ margin: "auto" }}>
            <span className="title">No posts to display</span>
          </CenterText>
        )}
      </Container>
    </Wrapper>
  );
};

export default Posts;
