import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import Footer from "../Footer";
import { COMMENT, SINGLE_POST_DETAILED_RESPONSE } from "../../../../store/interface";
import Header from "../Header";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(251, 253, 255)",
    fontFamily: "Poppins",
    justifyContent: "space-between",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "30px 20px",
  },
  catImage: {
    height: "25px",
    width: "25px",
    marginRight: "15px",
  },
  catText: {
    fontSize: "30px",
  },
  content: {
    width: "100%",
    fontSize: "28px",
    paddingLeft: "50px",
    marginTop: "30px",
  },
  reportName: {
    fontFamily: "Poppins-Bold",
  },
  date: {
    fontSize: "14px",
  },
  paddingContent: {
    padding: "40px 57px",
  },

  userTaggedText: {
    fontFamily: "Poppins-Bold",
    marginTop: "15px",
    fontSize: "20px",
  },



  userTags: {
    width: 410,
    height: 230,
  },
});

const Page6 = ({
  reportName,
  currentPost,
}: {
  currentPost: SINGLE_POST_DETAILED_RESPONSE;
  reportName: string;
}) => {

  let currComments = currentPost.comments.filter(comment => comment.isActive);
  let total = currentPost.comments.length;

  //sortPostscurrentPost.comments.sort((a, b) => a.result - b.result);
  let negativeComments = currentPost.comments.filter(comment => comment.result < 0.2);
  let totalNegative = negativeComments.length;
  let negativePercents = Intl.NumberFormat("en-GB", { style: "percent" }).format((totalNegative / total));
  negativeComments = currComments.filter(comment => comment.result < 0.2);
  //negativeComments = negativeComments.splice(0, 3).sort((a, b) => a.result - b.result);
  let positiveComments = currentPost.comments.filter(comment => comment.result > 0.9).sort((a, b) => b.result - a.result);
  let totalPositive = positiveComments.length;
  positiveComments = positiveComments.splice(0, 3).sort((a, b) => a.result - b.result);
  let positivePercents = Intl.NumberFormat("en-GB", { style: "percent" }).format((totalPositive / total));
  positiveComments = currComments.filter(comment => comment.result >= 0.4).sort((a, b) => b.result - a.result);
  let commentsUx = (comments: COMMENT[], title: string, backgroundColor: string, percents: string, sentiment: string) => {
    let commentsUx = comments.map(comment => {
      return (
        <View style={{ border: '1px solid #e6e6e6', marginBottom: 5, borderRadius: 6, display: 'flex', flexDirection: 'row' }}>
          <Text style={{ flex: 1, padding: 6 }}>{comment.message}</Text>
          <Text style={{ padding: 6 }} > {comment.result.toFixed(1)}</Text>

          <View style={{ backgroundColor, borderTopRightRadius: 6, borderBottomRightRadius: 6, flex: 0.05 }}>

          </View>
        </View>);
    });

    return (
      <View style={{ marginBottom: 20 }}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={{ fontWeight: 'bold', fontFamily: 'Poppins-Bold' }}>Very {sentiment} comments </Text>
          <Text style={{ marginLeft: 10, fontWeight: 'bold', fontFamily: 'Poppins-Bold' }}>{title}</Text>
          <Text style={{ marginLeft: 10 }}>  {percents}</Text>

        </View>

        {commentsUx}

      </View>
    );

  }



  const MyDoc = (
    <Page size="A4" style={styles.page} orientation="landscape">
      <View style={styles.paddingContent}>
        <Header reportName={reportName} current_page={6} total_pages={6} page_title={"Comments Examples"} />
        <View style={{
          // ...styles.content,
          fontSize: 10,

          display: 'flex', flexDirection: 'row', width: '100%',
        }}>

          <View style={{ flex: 0.5 }}>
            <Text>Organic Posts</Text>

          </View>
          <View style={{
            flexWrap: 'wrap',

            flex: 1
          }}>
            {commentsUx(positiveComments, `${totalPositive} out of ${currentPost.comments.length}`, '#60C57C', positivePercents, 'positive')}

            {commentsUx(negativeComments, `${totalNegative} out of ${currentPost.comments.length}`, '#FF783F', negativePercents, 'negative')}

          </View>


        </View>





      </View>




      <Footer currentPost={currentPost} />
    </Page >
  );

  return MyDoc;
};


export default Page6;
