import { MINI_POST_PROPS } from './interface';
import { assets } from '../utils/assets';
import { Icon, Sentiment } from '../utils/CommonStyles';
import { postStyles } from './MiniBoxStyles';
import { AppContext } from '../../../store/context';
import { useContext, useEffect, useState } from 'react';
import { CONSTANTS } from '../../../store/constants';
import { getTextByAverageScore } from '../utils/helper';
import { POSTS_GROUP_AVERAGES } from '../../../store/interface';
import { parsePostByGroup } from './utils/helper';
import { useHistory } from 'react-router-dom';

const {
  Box,
  PostImg,
  MiddleCol,
  LeftColHeader,
  RightCol,
  Title,
  ReactionsContainer,
  TitleAndId,
  BottomRow,
  BoxContainer
} = postStyles;

const MiniPostBox = ({ index, post, src }: MINI_POST_PROPS) => {
  const {
    totalComments,
    postDetails: { id },
    type,
    picture,
    reactions,
    averageSentimentScore,
    brand:{ name: brandName},
  } = post;

  const history = useHistory();

  const {
    state: { report },
    dispatch,
  } = useContext(AppContext);

  const { currentPost } = report;

  useEffect(() => {
    setSelected(currentPost?.postDetails.id === id);
    return () => {
      setSelected(false);
    }
  }, [currentPost,id])

  useEffect(() => {
    setSelected(currentPost?.postDetails.id === id);
  }, [currentPost,id])

  const [isSelected, setSelected] = useState(false);

  const { currentPostsGroup, groupExpended } = report;
 
  const isGroup = post.postDetails.id === '';

  const replacePost = () => {
    if(groupExpended){
      setSelected(currentPost?.postDetails.id === id);
      dispatch({ type: CONSTANTS.SetCurrentPost, payload: { post: currentPostsGroup.posts[index] } });
    }
  };

  const toggleExpandGroup = () => {
    
    dispatch({
      type: CONSTANTS.ToggleGroupExpention,
      payload: {}
    })
    const post = groupExpended ? parsePostByGroup(currentPostsGroup.average as POSTS_GROUP_AVERAGES) : currentPostsGroup.posts[0];
    const { groupName } = currentPostsGroup;
    const pathToRedirect = groupExpended ?  `/${report.name}/posts-group/${groupName}/reactions` : `/${report.name}/posts-group/${groupName}/comments`; 
    history.push(pathToRedirect);
    dispatch({ type: CONSTANTS.SetCurrentPost, payload: { post } });
  } 

  const [background] = getTextByAverageScore(averageSentimentScore);
  return (
    <>
    <BoxContainer>
      {index === 0 ? <Icon className='group-arrow expended' onClick={toggleExpandGroup} src={assets["expendArrow"]} alt="expend arrow" /> : ''}
      <Box onClick={replacePost} className={`pointer ${isGroup ? 'group' : ''} ${isSelected ? 'selected' : ''}`} isPostRouter={src === 'post-router'}>
        <TitleAndId className="name-id-conatiner">
          <Title className="ellipsis">{brandName}</Title>
          <LeftColHeader>
            <img src={assets[type]} alt="" loading="lazy" />
            <span id='post-id'>{`ID ${id}`}</span>
          </LeftColHeader>
        </TitleAndId>
        <PostImg src={picture} alt="post-image" />
        <BottomRow>
          <MiddleCol>
            <ReactionsContainer className={`${isGroup ? 'group' : ''}`}>
              <div>
                <Icon src={assets['comment']} />
                <span>{totalComments}</span>
              </div>
              <div>
                <Icon src={assets['share']} />
                <span>{reactions.shares}</span>
              </div>
              <div>
                <Icon src={assets['heart']} />
                <Icon src={assets['like']} />
                <span>{reactions.totalReactions}</span>
              </div>
            </ReactionsContainer>
          </MiddleCol>
          <RightCol>
              <Sentiment className="single" background={background}>
                <Icon className='sentiment-star' src={assets["star"]} alt="star" />
                <span>{+averageSentimentScore.toFixed(5).slice(0, -3)}</span>
              </Sentiment>
          </RightCol>
        </BottomRow>
      </Box>
      </BoxContainer>
    </>
  );
};

export default MiniPostBox;
