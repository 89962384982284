import { Page, StyleSheet, Image, View, Text } from "@react-pdf/renderer";
import { SINGLE_POST_DETAILED_RESPONSE } from "../../../../store/interface";
import Footer from "../Footer";
import Header from "../Header";
import { DATA_URL } from "../interface";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "rgb(251, 253, 255)",
    height: "85%",
  },

  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "90%",
    marginTop: "70px",
  },

  userReplies: {
    width: 410,
    height: 230,
  },
  paddingContent: {
    padding: "40px 57px",
  },

  userRepliesText: {
    fontFamily: "Poppins-Bold",
    marginTop: "15px",
    fontSize: "20px",
  },
});

const Page4 = ({
  userRepliesChart,
  currentPost,
  reportName,
}: {
  currentPost: SINGLE_POST_DETAILED_RESPONSE;
  userRepliesChart: DATA_URL;
  reportName: string;
}) => {
  const MyDoc = (
    <Page size="A4" style={styles.page} orientation="landscape">
      <View style={styles.paddingContent}>
        <Header
          reportName={reportName}
          current_page={4}
          total_pages={5}
          page_title={"Specific Social Reference"}
        />
        <View style={styles.content}>
          <View>
            <Text>Organic Posts</Text>
            <Text style={styles.userRepliesText}>User Replies</Text>
          </View>
          <Image style={styles.userReplies} src={userRepliesChart.url}></Image>
        </View>
      </View>
      <Footer currentPost={currentPost} />
    </Page>
  );

  return MyDoc;
};

export default Page4;
