import facebook from "./Facebook.svg";
import instagram from "./Instagram.svg";
import like from "./Like.svg";
import heart from "./Heart.svg";
import comment from "./Comment.svg";
import share from "./Share.svg";
import trash from "./Trash.svg";
import trashBlack from "./Trash-black.svg";
import star from "./Star.svg";
import remove from "./Remove.svg";
import badge from "./Badge.svg";
import add from "./Add.svg";
import report from "./Report.svg";
import arrowLeft from "./ArrowLeft.svg";
import search from "./Search.svg";
import logo from "./Logo.svg";
import crown from "./Crown.svg";
import post from "./Post.svg";
import calendar from "./Calendar.svg";
import tag from "./Tag.svg";
import arrowRight from "./ArrowRight.svg";
import signOut from "./SignOut.svg";
import minimize from "./Minimize.svg";
import loader from "./Loader.gif";
import homePage from "./HomePageImage.svg";
import watch from "./Watch.svg";
import chart from "./Chart.svg";
import view from "./View.svg";
import thumbsUp from "./ThumbsUp.svg";
import commentTab from "./CommentTab.svg";
import close from "./close.svg";
import dropDown from "./DropDown.svg";
import addToReport from "./AddToReport.svg";
import loginLogo from "./LoginLogo.svg";
import password from "./Password.png";
import haha from "./Haha.svg";
import sad from "./Sad.svg";
import love from "./Love.svg";
import angry from "./Angry.svg";
import wow from "./Wow.svg";
import care from "./Care.svg";
import likeColored from "./LikeColored.svg";
import logoBlack from "./LogoBlack.png";
import catReport from "./CAT-Report.png";
import facebookPNG from "./FacebookPNG.png";
import catForReport from "./catForReport.png";
import whiteCalendar from "./whiteCalendar.svg";
import comments from "./comments.svg";
import LikeGray from "./LikeGray.svg";
import Avreage from "./Avreage.svg";
import compareStarSentiment from "./CompareStarSentiment.svg";
import compareStarSentimentWhite from "./CompareStarSentimentWhite.svg";
import expendArrow from "./expendArrow.svg";
import averageIcon from "./averageIcon.svg";
import trashCan from "./trashCan.svg";
import alarm from "./Alarm.svg";
import Dangerous from "./Dangerous.svg";
import Offensive from "./Offensive.svg";
import Other from "./Other.svg";
import Contact from "./Contact request.svg";
import tableHeadIcon from './tableHeadIcon.svg'
import Verify from './Verify.svg'
import HiddenEye from './HiddenEye.svg'
import BlueEye from './BlueEye.svg'
import EditPost from './EditPost.svg'
import verifyGreen from './verifyGreen.svg'
import HideRed from './HideRed.svg'
import Alerts from './Alerts.svg'
import ViewSource from './ViewSource.svg'
import AlertBlack from './AlertBlack.svg'
import ShapeStarwhite from './Shape-star-white.svg';
import CalenderWhite from './Calendar-white.svg';
import CrownWhite from './Crown-white.svg';
import TagCutWhite from './Tag-cut-white.svg';
import AlarmWhite from './Alarm-white.svg';
import searchIcon from './searchIcon.svg';
import CollapseArrow from './CollapseArrow.svg';
import Like from './Like.svg';
import GrayLike from './GrayLike.svg';

export const assets: { [key: string]: string } = {
  facebook,
  Offensive,
  Other,
  Contact,
  alarm,
  Dangerous,
  catForReport,
  catReport,
  close,
  facebookPNG,
  password,
  instagram,
  loginLogo,
  like,
  heart,
  comment,
  share,
  trash,
  trashBlack,
  star,
  remove,
  badge,
  add,
  report,
  arrowLeft,
  search,
  logo,
  crown,
  post,
  calendar,
  tag,
  arrowRight,
  signOut,
  minimize,
  loader,
  homePage,
  commentTab,
  chart,
  watch,
  view,
  thumbsUp,
  dropDown,
  addToReport,
  haha,
  sad,
  love,
  angry,
  wow,
  care,
  likeColored,
  logoBlack,
  whiteCalendar,
  comments,
  LikeGray,
  Avreage,
  compareStarSentiment,
  compareStarSentimentWhite,
  expendArrow,
  averageIcon,
  trashCan,
  tableHeadIcon,
  Verify,
  HiddenEye,
  BlueEye,
  EditPost,
  verifyGreen,
  HideRed,
  Alerts,
  ViewSource,
  AlertBlack,
  CalenderWhite,
  ShapeStarwhite,
  CrownWhite,
  TagCutWhite,
  AlarmWhite,
  searchIcon,
  CollapseArrow,
  Like,
  GrayLike
};
