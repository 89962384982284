import React, { useContext, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { CONSTANTS } from "../../../../store/constants";
import { AppContext } from "../../../../store/context";
import { assets } from "../../../ui/utils/assets";
import { Icon, Input, PrimaryButton, WrapperWhite, WrapperWhiteHeader } from "../../../ui/utils/CommonStyles";
import {
  WordsAndInputContainer,
  Category,
  CategoriesContainer,
  GreenBox,
  HeaderBottom,
  HeaderTop,
  InputContainer,
  Container,
  Tag,
  TagsContainer,
} from "./Styles";
import uniq from "lodash.uniq";
import { WordTag } from "./interface";

const WordBank = () => {
  const [word, setWord] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("products");
  const {
    state: { report },
    dispatch,
  } = useContext(AppContext);

  const history = useHistory();
  const hasPosts = report.posts.length > 0;

  const handleAddWord = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (word.trim() === "") return;
    const loweredCaseWord = word.toLowerCase();
    let currentWordBank = { ...report.wordBank };
    switch (selectedCategory) {
      case "products":
        currentWordBank.products.push(loweredCaseWord);
        currentWordBank.products = uniq(currentWordBank.products);
        break;
      case "competitors":
        currentWordBank.competitors.push(loweredCaseWord);
        currentWordBank.competitors = uniq(currentWordBank.competitors);
        break;
      case "others":
        currentWordBank.others.push(loweredCaseWord);
        currentWordBank.others = uniq(currentWordBank.others);
        break;
      case "actionPersuation":
        currentWordBank.actionPersuation.push(loweredCaseWord);
        currentWordBank.actionPersuation = uniq(currentWordBank.actionPersuation);
        break;
      case "purchaseIntent":
        currentWordBank.purchaseIntent.push(loweredCaseWord);
        currentWordBank.purchaseIntent = uniq(currentWordBank.purchaseIntent);
        break;
      case "concerns":
        currentWordBank.concerns.push(loweredCaseWord);
        currentWordBank.concerns = uniq(currentWordBank.concerns);
        break;

      default:
        break;
    }

    dispatch({ type: CONSTANTS.AddToWordBank, payload: { wordBank: currentWordBank } });
    setWord("");
  };

  const handleRemoveWord = (tag: string) => {
    let currentWordBank = { ...report.wordBank };
    currentWordBank[selectedCategory as WordTag] = currentWordBank[selectedCategory as WordTag].filter(
      (word: string) => word !== tag
    );
    dispatch({ type: CONSTANTS.RemoveFromWordBank, payload: { wordBank: currentWordBank } });
  };

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
  };

  const handleAnalyzeData = () => {
    history.push(`posts-display`);
  };

  const handleBackClick = () => {
    history.push(`/${report.name}`);
  };

  if (!report.posts.length) {
    return <Redirect to={`/${report.name}`} />;
  }

  return (
    <WrapperWhite style={{ padding: 0 }}>
      <WrapperWhiteHeader style={{ borderBottom: "none" }}>
        <HeaderTop style={{ padding: "24px 28px 39px 27px" }}>
          <span className="title">Enter word tags for your report {report.name}</span>
          <Icon hover onClick={handleBackClick} className="remove" src={assets["remove"]} />
        </HeaderTop>
        <HeaderBottom>
          <Container>
            <CategoriesContainer>
              <Category
                selected={selectedCategory === "products"}
                onClick={() => handleCategoryChange("products")}
              >
                <span> Products</span>
                <span>{report.wordBank.products.length ? `(${report.wordBank.products.length})` : ""}</span>
              </Category>
              <Category
                selected={selectedCategory === "competitors"}
                onClick={() => handleCategoryChange("competitors")}
              >
                <span> Competitors</span>
                <span>
                  {report.wordBank.competitors.length ? `(${report.wordBank.competitors.length})` : ""}
                </span>
              </Category>
              <Category
                selected={selectedCategory === "others"}
                onClick={() => handleCategoryChange("others")}
              >
                <span> Other words </span>
                <span>{report.wordBank.others.length ? `(${report.wordBank.others.length})` : ""}</span>
              </Category>
            </CategoriesContainer>
            <WordsAndInputContainer>
              <InputContainer>
                <Icon className="badge" src={assets["tag"]} />
                <form autoComplete="on" onSubmit={handleAddWord}>
                  <Input
                    value={word}
                    type="text"
                    name="word-bank"
                    placeholder="Enter words here"
                    onChange={(e) => setWord(e.target.value)}
                  />
                  <GreenBox type="submit" className="pointer">
                    <Icon className="add" src={assets["add"]} />
                  </GreenBox>
                </form>
              </InputContainer>
              <TagsContainer>
                {report.wordBank[selectedCategory as WordTag].map((tag, i) => (
                  <Tag key={i}>
                    {tag}
                    <img
                      onClick={() => handleRemoveWord(tag)}
                      style={{ marginLeft: "5px" }}
                      src={assets["close"]}
                      className="pointer"
                      alt="remove tag"
                    />
                  </Tag>
                ))}
              </TagsContainer>
            </WordsAndInputContainer>
          </Container>
        </HeaderBottom>
      </WrapperWhiteHeader>
      <PrimaryButton
        style={{ margin: "24px 28px 39px 27px" }}
        className="update-data"
        onClick={handleAnalyzeData}
        disabled={!hasPosts}
      >
        Analyze Data
      </PrimaryButton>
    </WrapperWhite>
  );
};

export default WordBank;
