import styled, { keyframes } from "styled-components";
import { colors } from "../../../../utils";

export const TableStyled = styled.table`
  border-collapse: separate;
  text-align: left;
  margin-bottom: auto;
  margin-top: 80px;
  border-spacing: 0 8px;
  width: 100%;
  border-top: 0.5px solid ${colors.greyLight};
  /* margin-top: 130px; */
  padding: 15px 10px 0px 10px;
  thead{
    tr{
      color:  ${colors.darkGreyOpacity};
    }
  }
  tr:first-child {
    position: relative;
    top: 0px;
  }
  tr {
    position: relative;
    background-color: ${colors.white};
  }

  .collapsed-content{
    position: absolute;
    left: 6px;
    top: 50px;
    width: 100%;

    >.comment-content{
      margin: 2% 3%;
      display: flex;
      display: flex;
      width: 100%;
      justify-content: space-between;

      .post-details{
        display:flex;
      }

    }
  }

  td {
    background-color: "rgba(52, 115, 226, 0.1)";
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-style: solid none;
    position: relative;
    height: 40px;
    padding: 0 25px 0 0;

    
    &.last-cell{
      border-right-style: solid;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      img{
        float: right;
        margin-right: 19px;
      }
    }
    
    &.first-cell{
      border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    }

    .alerted-word-container{
      width: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .alerted-word{
        width: fit-content;
        padding: 4px 10px;
        .word{
              padding: 6px 10px;
              border: 1px solid rgba(0, 0, 0, 0.12);
              box-sizing: border-box;
              border-radius: 30px;
          }
        }
      }
  } 

  td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 12px;
    padding-right: 2px;
    width: 0%;
  }

  td:nth-child(2) {
    width: 5%;
  }
  td:nth-child(3){
    width: 7.5%;
    img {
    margin-left: 15px;
    display: block;
    }
  } 
  td:nth-child(4) {
    width: 15%;
  }

  td:nth-child(5) {
    width: 15%;
  }

  td:nth-child(6) {
    width: 25%;
  }

  td:nth-child(7) {
    width: 25%;
  }

  td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    width: 20%;
  }

  .trash {
    cursor: pointer;
  }

  tr {
    font-size: 0.8125rem;
    text-align: left;
    height: 40px;
    margin-bottom: 8px;
    &:hover {
      background-color: #fff0d3;
    }
  }

  th {
    line-height: 1.0625rem;
    color: "rgba(51, 59, 80, 0.6)";
    font-weight: 300;
    text-align: left;
    height: 40px;
    &:hover {
      background-color: none;
    }
  }

  th:first-child {
    border-bottom: 0;
  }
`;

export const CommentsTableStyled = styled.table<{ navBarOpen: boolean }>`
  /* position: absolute;
  width: ${({ navBarOpen }) => (navBarOpen ? '69vw' : '69vw')}; ;
  top: -20px;
  border-collapse: separate;
  text-align: left;
  margin-bottom: auto;
  margin-top: 80px;
  border-spacing: 0 8px; */

  /* border-top: 0.5px solid ${colors.greyLight}; */

  /* margin-top: 130px;
  padding: 15px 10px 0px 10px;  */
  
  thead{
    tr{
      color:  ${colors.darkGreyOpacity};
      background: none !important;
      &:hover {
        background-color: none !important;
      }
    } 
  }
  tbody{
    tr {
      td:first-child{
        background: ${colors.layoutBackground};
        border:none
      }
      td:nth-child(2){
        border-left-style: solid;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-left: 11px;
        color:  ${colors.darkGreyOpacity};
      }
    }
  }
  tr {
    position: relative;
    top: 0px;
    height: 45px !important;
  }
  td {
    background-color: "rgba(52, 115, 226, 0.1)";
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-style: solid none;
    position: relative;
    height: 40px;
    padding: 0 25px 0 0;

    &.last-cell{
      padding-right: 14px;
      padding: 0;

      img{
        float:right;
      }
    }


  }

  td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 12px;
    padding-right: 2px;
    width: 0%;
  }

  td:nth-child(2) {
    width: 7%;
  }
  td:nth-child(3){
    width: 36%;
    img {
    margin-left: 15px;
    display: block;
    }
  } 
  td:nth-child(4){
    div {
      margin: auto;
      width: fit-content;
    }
  } 
  

  td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    width: 5.5%;
  }

  .trash {
    cursor: pointer;
  }

  tr {
    font-size: 0.8125rem;
    text-align: left;
    height: 40px;
    margin-bottom: 8px;
    &:hover {
      background-color: #fff0d3;
    }
  }

  th {
    line-height: 1.0625rem;
    color: "rgba(51, 59, 80, 0.6)";
    font-weight: 300;
    text-align: left;
    height: 40px;
    &:hover {
      background-color: none;
    }
  }

  th:first-child {
    border-bottom: 0;
  }
  
`;

export const Spreader = styled.div`
  height: 250px;
`
const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

export const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;

  &:checked + div {
    background: #3d85f7 !important;
  }
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 4px 18px 9px 7px;
  color: #ffff;
  padding-left: 0px;
`;

export const Indicator = styled.div`
  width: 18px;
  height: 18px;
  background: ${colors.white};
  position: absolute;
  top: 0.2em;
  left: -13px;
  border: 2px solid #3d85f7;
  border-radius: 0.2em;

  .head-indicator {
    left: 0.5;
  }

  ${Input}:not(:disabled):checked & {
    background: #3d85f7;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + div {
    background: #3d85f7 !important;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 0em;
    left: 0.3em;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    /* animation-duration: 0.3s; */
    animation-fill-mode: forwards;
  }

  &::disabled {
    cursor: not-allowed;
  }
`;


export const CommentsActions = styled.div`
    padding-right: 4%;
    height: fit-content;
    display: inline-flex;
    text-align: right;
    color: ${colors.tabTitle};
    float: right;
    p {
      font-size: 13px;
    }
`

export const IconCategory = styled.img`
  position: relative;
  left: 27px;
`
export const IconImg = styled.img`
    margin-left: 12px;
    margin-right: 12px;
`

export const IconEye = styled.img`
    height: 20px;
    margin-left: 7px;
    margin-right: 9px;
`

export const Button = styled.div`
    display: inline-flex;
    cursor: pointer;
    z-index: 2;
    color: rgb(0, 0, 238);

    p,a{
      font-weight: 500;
    }
`
export const IconEdit = styled.img`
    position: relative;
    top: 1.2px;
    height: 16px;
    margin-left: 7px;
    margin-right: 9px;
`

export const SingleComment = styled.div`
  padding: 10px;
  line-height: 30px;
`

export const Spacer = styled.div`
  height: 8px !important;
`;

export const PostDetailsRowWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 55px;
  width: 500px;
`
export const PostImg = styled.img`
   width: 50px;
   height: 50px;
   top: 43px;
   left: 5px;
`

export const PostContent = styled.div`
  margin-left: 20px;
`

export const IconRotatingWrapper = styled.div<{ rotate: boolean, rowId: string }>`
    margin-top: ${({ rowId }) => (rowId ? '8px' : '0')};
    transform: ${({ rotate }) => (rotate ? '0' : 'rotate(0.5turn)')}; ;
`;