import { PostBoxRow } from './Styles';
import { POSTS_ROW_PROPS } from './interface';
import MiniPostBox from '../post/MiniPostBox';


const PostsRow = ({ posts, src, groupName }: POSTS_ROW_PROPS) => {
  return (
  <PostBoxRow>
   { posts.map((post, index)=>{
      return <MiniPostBox index={index} post={post} src={src} groupName={groupName} />
    })}   
  </PostBoxRow>
  );
};

export default PostsRow;
