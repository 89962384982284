import React from "react";

interface IStripedBarProps {
  background: string;
  x: number;
  y: number;
  width: number;
  height: number;
  source: string;
  index: number;
}

export const StripedBar: React.FC<IStripedBarProps> = ({ background, x, y, width, height, source, index }) => {
  const id = `${source}-${index.toString()}`;
  return (
    <svg>
      <pattern
        id={`${id}-pattern-stripe`}
        width="8"
        height="8"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(300)"
      >
        <rect width="1" height="8" transform="translate(0,0)" fill={background}></rect>
      </pattern>
      <mask id={`${id}-mask-stripe`}>
        <rect width="100%" height="100%" fill={`url(#${id}-pattern-stripe)`} />
      </mask>

      <rect x={x} y={y} width={width} height={height} fill={background} />
      <rect x={x} y={y} width={width} height={height} mask={`url(#${id}-mask-stripe)`} />
    </svg>
  );
};
