import styled from 'styled-components';
import { colors } from '../../../utils';
import { Icon } from '../utils/CommonStyles';

interface BOX_PROPS {
  isPostRouter: boolean;
}

const Box = styled.div<BOX_PROPS>`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  max-width: 276px;
  flex-wrap: wrap;
  margin: ${({ isPostRouter }) => (isPostRouter ? '0 27px 31px 0' : '0 0 10px 0')};
  padding: 14px;
  background-color: ${colors.white};
  position:relative;
  height: 140px;

  &.selected{
    border: 2px solid #3D85F7;
  }
`;


const PostImg = styled.img`
  width: 63px;
  height: 63px;
  background-color: black;
`;


const MiddleCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0;
`;

const TitleAndId = styled.div`
      display: flex;
    width: 100%;
    justify-content: space-between;
`;

const LeftColHeader = styled.div`
  display: flex;
  width:40%;

  & img {
    margin: auto;
    margin-right: 12px !important;
    width: 7px;
    height: 7px;
  }

  & span {
    margin: auto;
    font-weight: 300;
    font-size: 0.8125rem;
    color: ${colors.darkGrey};
  }

  #post-id{
    font-size: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const RightColHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .campaign {
    white-space: nowrap;
    font-size: 0.8125rem;
    color: ${colors.darkBlue};
    margin-right: 10px;
    max-width: 200px;
  }

  & .thin {
    color: ${colors.darkGrey};
    font-weight: 300;
    font-size: 0.8125rem;
    margin-left: auto;
  }

  & .divider {
    margin-right: 10px;
  }

  ${Icon} {
    width: 24px;
    height: 24px;
    margin-left: 40px;
  }
`;

const RightCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  cursor: auto;
`;

const Title = styled.span`
  max-width: 400px;
  font-weight: 600;
  font-size: 0.9375rem;
  color: ${colors.darkGrey};

  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 45%;

  @media screen and (max-width: 1024px) {
    &.ellipsis {
      max-width: 270px;
    }
  }

  @media screen and (min-width: 1350px) {
    &.ellipsis {
      max-width: 400px;
    }
  }

  @media screen and (min-width: 1500px) {
    &.ellipsis {
      max-width: 500px;
    }
  }
`;

const ReactionsContainer = styled.div`
  display: flex;
  margin: auto;
  div {
    display: flex;
    align-items: center;
    margin-right: 3px;
  }

  span {
    margin-left: 3px;
    font-size: 11px;
    color: ${colors.darkBlue};
  }
  ${Icon} {
    width: 12px;
    height: 12px;
  }
`;

const BottomRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const BoxContainer = styled.div`
  position: relative
`;

export const postStyles = {
  Box,
  PostImg,
  MiddleCol,
  LeftColHeader,
  RightColHeader,
  RightCol,
  Title,
  ReactionsContainer,
  TitleAndId,
  BottomRow,
  BoxContainer
};
