import { useContext } from "react";
import { AppContext } from "../../../../../store/context";
import { ChartHeadline, SentimentBreakDownWrapper } from "./Styles";
import { POSTS_GROUP_AVERAGES, SINGLE_POST_DETAILED_RESPONSE } from "../../../../../store/interface";
import BrandBreakDown from "../BreakDown/BreakDown";

const SentimentBreakDown = ({
  positiveComments,
  negativeComments,
  neutralComments,
}: {
  positiveComments: SINGLE_POST_DETAILED_RESPONSE["positiveComments"];
  negativeComments: SINGLE_POST_DETAILED_RESPONSE["negativeComments"];
  neutralComments: SINGLE_POST_DETAILED_RESPONSE["neutralComments"];
  isReportPage: boolean;
}) => {
  const {
    state: { report },
  } = useContext(AppContext);
  const { averageDetailsPostsToCompare, averagedPostCount, currentPost } = report;
  
  const reactionsSentiment = [
    {
      name: currentPost && currentPost.brand ? currentPost?.brand.name : '',
      total:'1',
      totalPosts: 1,
      positive: positiveComments,
      neutral: neutralComments,
      negative: negativeComments,
    }
  ];
  const detailsPostsToCompare = report.detailsPostsToCompare
  if ( detailsPostsToCompare && Object.keys(detailsPostsToCompare).length > 0  ){
   Object.keys(detailsPostsToCompare).forEach((key)=>{
      const newBar = {
        name: key,
        total: detailsPostsToCompare[key].averages.totalComments.toString(),
        totalPosts: detailsPostsToCompare[key].postsCount,
        positive : detailsPostsToCompare[key].averages.positiveComments,
        neutral : detailsPostsToCompare[key].averages.neutralComments,
        negative: detailsPostsToCompare[key].averages.negativeComments
      }

      reactionsSentiment.push(newBar)
    })
  }

  if (Object.keys(averageDetailsPostsToCompare).length > 0) {
    reactionsSentiment.splice(1,reactionsSentiment.length);
    const averageDetailsPostsToCompareTemporal =
      averageDetailsPostsToCompare as POSTS_GROUP_AVERAGES;
    const newBar = {
      name: "Competitive Average",
      total: '',
      totalPosts: averagedPostCount,
      positive:
        averageDetailsPostsToCompareTemporal.positiveComments,
      neutral:
        averageDetailsPostsToCompareTemporal.neutralComments,
      negative:
        averageDetailsPostsToCompareTemporal.negativeComments,
    };
    reactionsSentiment.push(newBar);
  }

  return (
    <SentimentBreakDownWrapper>
      <div >
        <ChartHeadline>Sentiment Breakdown</ChartHeadline>
        <BrandBreakDown
          reactionsSentiment={reactionsSentiment}
          isReportPage={true}
          relatedBreakdown="sentiment"
        />
      </div>
    </SentimentBreakDownWrapper>
  );
};

export default SentimentBreakDown;
