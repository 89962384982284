import { POSTS_GROUP_AVERAGES } from '../../../../../store/interface';
import { assets } from '../../../utils/assets';
import { colors, colorsCharts, randomizeColorsChart } from '../../../../../utils';
import { Wrapper, ContainerGraphs, ContainerGraph, TitleAndCollapser } from './Styles';
import { ComposedChart, Cell, LabelList, YAxis, XAxis, Bar, Tooltip } from "recharts";
import { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../../../../store/context';
import { CollectReactions } from './Interface';
import { capitalizeFirstLetter, getMaximumForBarGraph } from '../utils/helper';
import { emojiDict } from './emojiDict';
import { IconRotationWrapper } from '../Sentiment/Styles';
import { Icon } from '../../../utils/CommonStyles';
import { useCustomChartRenderers } from '../utils/useCustomChartRenderers';



const MultipleBrandMoreReactions = ({ isReportPage }: { isReportPage?: boolean }) => {
  const {
    state: { report }
  } = useContext(AppContext);
  const [maximum, setMaximum] = useState(0);
  const [collapsed, setCollapsed] = useState(true);
  const { currentPost, currentPostsGroup, detailsPostsToCompare, averageDetailsPostsToCompare, averagedPostCount,navBarCollaped, groupExpended } = report
  const { renderCustomLabel, renderCustomAvgTick, renderPostsCountTick, renderAvgStripedBar } = useCustomChartRenderers();

  let collectReactions: CollectReactions = {
    like: [{
      name: currentPost!.brand.name,
      reactionCount: currentPost!.reactions.like as number,
      postsCount: 1
    }],
    love: [{
      name: currentPost!.brand.name,
      reactionCount: currentPost!.reactions.love,
      postsCount: 1
    }],
    haha: [{
      name: currentPost!.brand.name,
      reactionCount: currentPost!.reactions.haha,
      postsCount: 1
    }],
    wow: [{
      name: currentPost!.brand.name,
      reactionCount: currentPost!.reactions.wow,
      postsCount: 1
    }],
    care: [{
      name: currentPost!.brand.name,
      reactionCount: currentPost!.reactions.care,
      postsCount: 1
    }],
    sad: [{
      name: currentPost!.brand.name,
      reactionCount: currentPost!.reactions.sad,
      postsCount: 1
    }],
    angry: [{
      name: currentPost!.brand.name,
      reactionCount: currentPost!.reactions.angry,
      postsCount: 1
    }]
  }


  const handleCollapse = () => {
    setCollapsed(!collapsed);
  }

  /// After Compare 
  if (detailsPostsToCompare && Object.keys(detailsPostsToCompare).length > 0) {
    Object.keys(detailsPostsToCompare).forEach((key) => {
      collectReactions.like.push({
        name: key,
        reactionCount: detailsPostsToCompare[key].averages.reactions.like as number,
        postsCount: detailsPostsToCompare[key].postsCount
      })

      collectReactions.love.push({
        name: key,
        reactionCount: detailsPostsToCompare[key].averages.reactions.love,
        postsCount: detailsPostsToCompare[key].postsCount
      })

      collectReactions.haha.push({
        name: key,
        reactionCount: detailsPostsToCompare[key].averages.reactions.haha,
        postsCount: detailsPostsToCompare[key].postsCount
      })

      collectReactions.sad.push({
        name: key,
        reactionCount: detailsPostsToCompare[key].averages.reactions.sad,
        postsCount: detailsPostsToCompare[key].postsCount
      })

      collectReactions.angry.push({
        name: key,
        reactionCount: detailsPostsToCompare[key].averages.reactions.angry,
        postsCount: detailsPostsToCompare[key].postsCount
      })

      collectReactions.wow.push({
        name: key,
        reactionCount: detailsPostsToCompare[key].averages.reactions.wow,
        postsCount: detailsPostsToCompare[key].postsCount
      })

      collectReactions.care.push({
        name: key,
        reactionCount: detailsPostsToCompare[key].averages.reactions.care,
        postsCount: detailsPostsToCompare[key].postsCount
      })
    })
  }

  /// After Toggle competitive
  if (averagedPostCount > 0 && Object.keys(averageDetailsPostsToCompare).length > 0) {
    Object.keys(collectReactions).map((key, index) => {
      return collectReactions[key] = collectReactions[key].slice(0, 1)
    })
    const averageDetailsPostsToCompareTemporal =
      averageDetailsPostsToCompare as POSTS_GROUP_AVERAGES;

    collectReactions.like.push({
      name: 'Average Competitive',
      reactionCount: averageDetailsPostsToCompareTemporal.reactions.like as number,
      postsCount: averagedPostCount
    })

    collectReactions.love.push({
      name: 'Average Competitive',
      reactionCount: averageDetailsPostsToCompareTemporal.reactions.love,
      postsCount: averagedPostCount
    })

    collectReactions.haha.push({
      name: 'Average Competitive',
      reactionCount: averageDetailsPostsToCompareTemporal.reactions.haha,
      postsCount: averagedPostCount
    })

    collectReactions.sad.push({
      name: 'Average Competitive',
      reactionCount: averageDetailsPostsToCompareTemporal.reactions.sad,
      postsCount: averagedPostCount
    })

    collectReactions.angry.push({
      name: 'Average Competitive',
      reactionCount: averageDetailsPostsToCompareTemporal.reactions.angry,
      postsCount: averagedPostCount
    })

    collectReactions.wow.push({
      name: 'Average Competitive',
      reactionCount: averageDetailsPostsToCompareTemporal.reactions.wow,
      postsCount: averagedPostCount
    })

    collectReactions.care.push({
      name: 'Average Competitive',
      reactionCount: averageDetailsPostsToCompareTemporal.reactions.care,
      postsCount: averagedPostCount
    })
  }

  const getRandomColor = (index: number) => {
    return Object.values(colorsCharts)[index];
  }
  const CustomTooltip = ({ active, payload, label, index }: any): any => {
    if (active && payload && payload.length) {
      const isGroup = currentPostsGroup.posts.length && !groupExpended && currentPostsGroup.groupName === payload[0].payload.name;
      const x = index;
      if((payload[0].payload.posts <= 1) && !isGroup) return <></>
      const name = isGroup ? currentPostsGroup.groupName : payload[0].payload.name;
      const postsCount = isGroup ? currentPostsGroup.posts.length : payload[0].payload.posts;
      return (
        <div className="custom-tooltip">
          <p className="label" style={{ fontWeight: 'bold' }}>{`${capitalizeFirstLetter(name)}`}</p>
          <p className="posts_count">{`posts count : ${postsCount}`}</p>
        </div>
      );
    }

    return null;
  }

  let dataReactions = useMemo(() => {
    let dataReactionsList: any[] = []
    Object.keys(collectReactions).forEach((key: any, index: number) => {
      for (let brand of collectReactions[key]) {
        const name = !brand.name.length && currentPostsGroup.groupName.length > 0 ? currentPostsGroup.groupName : brand.name;
        const postsCount = brand.postsCount;
        if (!dataReactionsList[key] || !dataReactionsList[key].length) {
          dataReactionsList[key] = [];
        }
        dataReactionsList[key].push({
          "name": name,
          "amount": brand.reactionCount,
          "posts": postsCount
        })
        setMaximum(getMaximumForBarGraph(dataReactionsList[key], 'amount', maximum));
      }
    })
    return dataReactionsList
  }, [averageDetailsPostsToCompare])


  return (
    <Wrapper isReportPage={isReportPage} id={'more-reactions'}>
      <TitleAndCollapser collapsed={collapsed}>
        <p className="chart-headline">More Reactions <img src={assets['LikeGray']} style={{ marginLeft: '25px' }} alt="" /> </p>
        <div className="collapse-btn-wrapper">
          <div className="collapse-btn">
            <span className="btn-text">{collapsed ? 'Show All' : 'Show Less'}</span>
            <IconRotationWrapper collapse={collapsed}>
              <Icon
                shouldTransform={collapsed}
                className="pointer collapse-icon"
                src={assets['CollapseArrow']}
                onClick={(e) => {
                  handleCollapse();
                }}
              />
            </IconRotationWrapper>
          </div>
        </div>
      </TitleAndCollapser>
      <ContainerGraphs>
        {Object.keys(collectReactions).map((key: any, index) => {
          return (
            <ContainerGraph collapsed={collapsed} index={index} key={index}>
              <ComposedChart
                margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
                width={!navBarCollaped ? 230 : 280} height={200} data={dataReactions[key]}>
                <XAxis dataKey="posts" hide={false} tick={renderPostsCountTick} tickLine={false} xAxisId={1} axisLine={{ strokeWidth: 0 }} />
                <XAxis dataKey="posts" axisLine={{ stroke: '#4D566F', opacity:'0.2' }} tick={renderCustomAvgTick} xAxisId={0} tickLine={false}/>
                <YAxis tick={false} axisLine={false} domain={[0, maximum]} />
                <Tooltip cursor={false} content={<CustomTooltip />} />
                <Bar dataKey="amount" barSize={20} fill="#413ea0" shape={(shapeProps) => renderAvgStripedBar(shapeProps, 'MultBrandReactions')}>
                  {
                    dataReactions[key].map((entry: { postsCount: number }, index: number) => (
                      <Cell key={`cell-${index}`} fill={randomizeColorsChart[index % randomizeColorsChart.length]} strokeDasharray="3 3" stroke={entry.postsCount > 1 ? colors.black : getRandomColor(index) === 'black' ? colors.white : ''} />
                    ))
                  }
                  <LabelList offset={10} dataKey="amount" position="top" content={renderCustomLabel}/>
                </Bar>

              </ComposedChart>
              <div className='reaction-name-icon-container'>
                <div className='emoji'><img width="27px" height="27px" src={assets[key]} alt={key} /></div>
                <div className="graphTitle" >{capitalizeFirstLetter(key)}</div>
              </div>
            </ContainerGraph>
          )
        })
        }

      </ContainerGraphs>
    </Wrapper>
  );
};

export default MultipleBrandMoreReactions;
