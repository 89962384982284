import styled from 'styled-components';
import { colors } from '../../../../utils';
import { Input } from '../../../ui/utils/CommonStyles';

export const Wrapper = styled.div`
  margin: 0 147px 0 73px;
  height: 100%;

  @media screen and (max-width: 1250px) {
    margin: 0 50px 0 73px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .loader {
    display: block;
    width: 100px;
    height: 100px;
    margin: auto;
    background: ${colors.layoutBackground};
  }
`;

export const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderBottom = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  .loader {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: 70%;
  margin: auto 0 0 0;
  border: 1px solid rgba(43, 48, 74, 0.3);
  border-radius: 5px;
  padding-left: 14.5px;

  ${Input} {
    height: 100%;
    width: 100%;
  }

  & .badge {
    margin: 0 10px 0 0;
    width: 16px;
    height: 16px;
  }

  & form {
    display: flex;
    height: 100%;
    width: 100%;
  }
`;

export const PostContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
