import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  .custom-tooltip {
    border: 1px solid black;
    padding: 10px;
    background-color: white;
  }
  .recharts-legend-wrapper {
    top: 280px;
  }
`;

export const ToolTip = styled.div`
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  padding: 10px;
  background-color: white;
`;
export const Value = styled.div`
  .bold {
    font-weight: bold;
  }
`;
