import React from 'react';


import { useEffect, useRef } from "react";



import {
  Wrapper
} from './Styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AccountsPopup from "../AccountsPopup/AccountsPopup";
import BrandsPopup from '../BrandsPopup/BrandsPopup';
import { POPUP_GRID_PROPS } from './interface';







interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;

}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function BasicTabs(props: any) {
  console.log({ BasicTabs: props })
  const [value, setValue] = React.useState(props.value);
  const { children } = props;


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    props.setPostsTypeSelection(newValue);
    setValue(newValue);
  };




  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Brands"  {...a11yProps(0)} />
          <Tab label="Ad Accounts" {...a11yProps(1)} />

        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        {children[0]}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {children[1]}
      </TabPanel>

    </Box >
  );
}


function useOutside(ref: any, callback: Function) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      let { current } = ref;
      if (!current?.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const PopupGrid = ({
  brands,
  setBrands,
  setIsPopupOpen,
  setIsBrandsSelected,
  isBrandsSelected,
  setAccounts,
  isAccountsSelected,
  setIsAccountsSelected,
  accounts,
  setPostsTypeSelection,
  postsTypsSelection,
}: POPUP_GRID_PROPS) => {


  const wrapperRef = useRef(null);
  useOutside(wrapperRef, () => setIsPopupOpen(false));


  const hasOneBrandSelected = brands.filter((brand) => brand.isActive).length > 0;

  useEffect(() => {
    setIsBrandsSelected(hasOneBrandSelected);
    return () => { };
  }, [hasOneBrandSelected, setIsBrandsSelected]);





  return (
    <Wrapper ref={wrapperRef} >
      <BasicTabs
        value={postsTypsSelection}
        setPostsTypeSelection={setPostsTypeSelection}
      >
        <div>
          <BrandsPopup
            setIsPopupOpen={setIsPopupOpen}
            setIsBrandsSelected={setIsBrandsSelected}
            isBrandsSelected={isBrandsSelected}
            brands={brands}
            setBrands={setBrands}
            setPostsTypeSelection={setPostsTypeSelection}

          />

        </div>
        <div>
          <AccountsPopup
            isAccountsSelected={isAccountsSelected}
            setIsAccountsSelected={setIsAccountsSelected}
            setIsPopupOpen={setIsPopupOpen}
            accounts={accounts}
            setAccounts={setAccounts}
            setPostsTypeSelection={setPostsTypeSelection}
          />

        </div>

      </BasicTabs>

    </Wrapper>
  );
};



export default PopupGrid;
