import styled from 'styled-components';
import { colors } from '../../../../utils';
import { Icon } from '../../utils/CommonStyles';

export const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid rgb(77, 86, 111, 0.2);
  width: 91%;
`;

interface TAB_PROPS {
  isSelected: boolean;
}

export const Tab = styled.div<TAB_PROPS>`
  font-weight: 500;
  font-size: 0.875rem;
  color: ${({ isSelected }) => (isSelected ? colors.tabTitle : colors.darkGrey)};
  border-bottom: ${({ isSelected }) => isSelected && `2px solid ${colors.tabTitle}`};
  white-space: nowrap;
  height: 30px;
  margin-right: 30px;
  & ${Icon} {
    width: 12px;
    height: 12px;
    margin-right: 10px;
  }

  &.disabled{
    color: rgba(51,59,80,0.6);
    cursor: auto;
  }
`;
