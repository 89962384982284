import styled from 'styled-components';
import { colors } from '../../../../../utils';
import { Title } from '../CommonStyles';

export const Wrapper = styled.div<{ isReportPage?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  min-height: 311px;
  background-color: ${colors.white};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  min-width: ${({ isReportPage }) => isReportPage && '591px'};

  ${Title} {
    margin-top: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

export const ReactionCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
  justify-content: space-around;
  img {
    width: 40px;
    height: 40px;
  }
`;
