import { Fragment, useContext, useMemo, useState } from "react";
import { useTable, Column, useSortBy } from "react-table";
import { ALERTS_TABLE_PROPS } from "../../../ui/table/post-input/interface";
import { assets } from "../../../ui/utils/assets";
import { Icon } from "../../../ui/utils/CommonStyles";
import { useHistory } from 'react-router-dom';
import {
  TableStyled, Spreader, CommentsActions, Button, IconEdit, IconCategory, PostImg,
  PostDetailsRowWrapper, PostContent, IconRotatingWrapper
} from "./Styles";
import AlertsCommentsTable from "./AlertCommentsTable";
import { COMMENT_ALERT, POST_ALERTS } from "../../../../store/interface";
import { AppContext } from "../../../../store/context";
import { applyCategoryToTable } from "../helper";
import { CONSTANTS } from "../../../../store/constants";
import { colors } from "../../../../utils";
const AlertsTable = ({
  data,
}: ALERTS_TABLE_PROPS) => {
  const {
    dispatch
  } = useContext(AppContext);
  const [openComments, setOpenComments] = useState(false)
  const [currentPost, setCurrentPost] = useState('');
  const [spreaderHeight, setSpreaderHeight] = useState(0)

  const history = useHistory()
  const handleDropDown = (post_id: string) => {
    if (post_id === currentPost) {
      setOpenComments((prev) => !prev);
    } else {
      setOpenComments(true)
      setCurrentPost('')
    }

    setCurrentPost(post_id)

  }

  const redirectToEditAlert = (post_id: string, post_details: POST_ALERTS) => {
    dispatch({
      type: CONSTANTS.SetEditPostAlert,
      payload: { post_id, post_details },
    });
    const pathToRedirect = `/edit-alert/${post_id}`
    history.push(pathToRedirect)
  }

  const columns: Column<{
    _id: string;
    alert: COMMENT_ALERT[];
    story: string;
    created_time: string;
    brand_id: string;
    brand_name: string;
    totalComments: number;
    category: number;
    type: string;
    alerted_words: string[]
  }>[] = useMemo(
    () => [
      {
        id: 'post',
        Header: '',
        sortType: (a: any, b: any) => {
          return a.original.post_id > b.original._id ? -1 : 1;
        },
        Cell: ({ row }: { row: any }) => {
          return (
            <div >
              <IconRotatingWrapper
                key={row.index}
                className="ellipsis word"
                rowId={row.original._id}
                rotate={row.original._id === currentPost && openComments}
              >
                <Icon
                  shouldTransform={row.original._id}
                  className="pointer dropdown"
                  src={assets['dropDown']}
                  onClick={(e) => {
                    handleDropDown(row.original._id)
                  }}
                />
              </IconRotatingWrapper>

            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "created_time",
        Cell: ({ row }: { row: any }) => {
          console.log(row.original)
          return (
            <div>

              <span style={{ marginLeft: '10px' }}>{row.original.created_time}</span>
              <div key={row.index}>

              </div>
            </div>
          );
        },
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ value, row }: { value: any, row: any }) => {
          return (
            <>

              {openComments && row.original._id === currentPost ? (
                <>
                  <Icon src={assets[value]} />

                </>
              ) : (
                <>
                  <Icon src={assets[value]} />
                </>
              )}


            </>

          )
        },
      },
      {
        Header: "Brand Name",
        accessor: "brand_name",
      },
      {
        Header: "Post ID",
        accessor: "_id",
      },
      {
        Header: "Words",
        accessor: "alerted_words",
        Cell: ({ row }) => {
          const words = row.original.alerted_words
          return (
            <div className="alerted-word-container">{words.map((word) => {
              return <div className="alerted-word"><span className='word'>{word}</span></div>
            })}</div>
          )
        },
      },
      {
        Header: "Amount",
        accessor: "totalComments",
        Cell: ({ row }) => {
          return (
            <p>{row.original.alert.length} in {row.original.totalComments} </p>
          )
        },
      },
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ row }) => {
          return (
            <IconCategory src={assets[applyCategoryToTable(row.original.category)]} />
          )
        },
      },
    ],
    [openComments, currentPost]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<{
    _id: string;
    alert: COMMENT_ALERT[];
    story: string;
    created_time: string;
    brand_id: string;
    brand_name: string;
    totalComments: number;
    category: number;
    type: string;
    alerted_words: string[]
    picture?: string;
    message?: string;
  }>({
    columns,
    data,
  },
    useSortBy
  );

  return (
    <TableStyled {...getTableProps()} >
      <thead >
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, i) => {
              const { key } = { ...column.getHeaderProps() };
              const keys = ["header_isActive", "header_post", "header__id", "header_alerted_words", "header_totalComments"]
              if (!keys.includes(key.toString()))
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} key={i} style={{ background: colors.layoutBackground }}>
                    <span style={{ marginLeft: '8px' , height:'fit-content', display:'flex', justifyContent:'space-between'}}>
                      {column.render("Header")}
                      <img src={assets['tableHeadIcon']} />
                    </span>
                  </th>
                );

              return (
                <th {...column.getHeaderProps()} key={i} style={{ background: colors.layoutBackground }}>
                  {column.render("Header")}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, rowIndex) => {
          prepareRow(row);
          const postSource = `https://facebook.com/${row.original._id}`
          return (
            <>
              {openComments && row.original._id === currentPost ? (
                <>
                  <tr {...row.getRowProps()} key={rowIndex} >
                    {row.cells.map((cell: any, i: number) => {
                      let className = row.cells.length - 1 === i ? 'last-cell' : '';
                      className = row.cells.length - 2 === i ? 'amount-cell' : '';
                      return (
                        <Fragment key={i}>
                          <td className={row.cells.length - 1 === i ? 'last-cell' : ''} {...cell.getCellProps()} >
                            {cell.render("Cell")}
                          </td>
                        </Fragment>
                      );
                    })}
                    <div className='collapsed-content'>

                      <div className='comment-content'>
                        <div className="post-details">
                          <PostImg src={row.original.picture} />
                          <PostContent>
                            {row.original.message ? row.original.message : 'This post has no title'}
                          </PostContent>
                        </div>
                        <CommentsActions>

                          <Button style={{ marginRight: '10px' }}>
                            <Icon src={assets["BlueEye"]} style={{ marginRight: '7px' }} />
                            <a href={postSource} target="_blank" rel="noreferrer">
                              View Source
                            </a>
                          </Button>

                          <Button onClick={() => redirectToEditAlert(row.original._id, row.original)}>
                            <IconEdit src={assets["EditPost"]} />
                            <p>
                              Edit in post
                            </p>
                          </Button>

                        </CommentsActions>
                      </div>

                      <AlertsCommentsTable
                        setSpreaderHeight={setSpreaderHeight}
                        words={row.original.alerted_words}
                        data={row.original.alert}
                      />
                    </div>
                  </tr>
                  <Spreader style={{ height: spreaderHeight + 50 + 'px' }} />
                </>
              ) : (
                <tr {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell: any, i: number) => {
                    return (
                      <Fragment key={i}>
                        <td {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      </Fragment>
                    );
                  })}
                </tr>
              )
              }
            </>

          );
        })}
      </tbody>
    </TableStyled>
  );
};

export default AlertsTable;
