import instance from "../../api";
import { CONSTANTS } from "../constants";
import { AUTH_STATE_TYPE, AUTH_ACTIONS, POST_ACTIONS, ALERT_ACTIONS } from "../interface";

export const authReducer = (state: AUTH_STATE_TYPE, action: AUTH_ACTIONS | POST_ACTIONS | ALERT_ACTIONS) => {
  const { payload, type } = action;
  switch (type) {
    case CONSTANTS.Login:
      const { email, token } = payload as AUTH_STATE_TYPE;
      localStorage.email = email;
      localStorage.token = token;
      instance.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.token,
        email: localStorage.email,
      };
      return {
        ...state,
        email,
        token,
        isTokenValid: true,
      };
    case CONSTANTS.Logout:
      instance.defaults.headers = {
        "Content-Type": "application/json",
      };
      localStorage.clear();
      return {
        email: "",
        token: "",
        isTokenValid: false,
      };
    default:
      return state;
  }
};
