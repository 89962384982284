import { useContext, useState } from "react";
import { AppContext } from "../../../../../store/context";
import { addCompare } from "./Styles";
import CompareProcess from "./CompareProcess";
import { assets } from "../../../utils/assets";
import { CONSTANTS } from "../../../../../store/constants";
import _ from "lodash";
import { calcGroupAverages } from "../utils/helper";

const { Button, ButtonClear, Wrapper, CompareContainer, CompareText, SwitcherWrapper } = addCompare;

const CompareStart = ({ setIsCompare }:{ setIsCompare:React.Dispatch<React.SetStateAction<boolean>> }) => {
  const {
    state: { report },
    dispatch,
  } = useContext(AppContext);
  const { brandsToCompare, detailsPostsToCompare, afterCompare } = report;
  const [isComapreOpen, setIsComapreOpen] = useState(false);
  const [theme, setTheme] = useState<string>("off");
  const toggleCompare = (isFirstCompare:boolean) => {
    setIsComapreOpen((prev) => !prev);
    setIsCompare((prev: boolean) => !prev)!
    dispatch({
      type: CONSTANTS.SetAfterCompare,
      payload: {afterCompare:!isFirstCompare}
    });
  };

  const onCancelProcess = () => {
    dispatch({
      type: CONSTANTS.ResetBrandsAndPostsToCompare,
      payload: {}
    });
    setIsComapreOpen(false);
    dispatch({
      type: CONSTANTS.SetAfterCompare,
      payload: {afterCompare: false}
    });
    toggleSwitcher(true);
  };
  const toggleSwitcher = (isClear: boolean = false) => {
    setTheme((prevTheme) => (prevTheme === "off" ? "on" : "off"));
    if (!isClear && detailsPostsToCompare && theme === 'off') {
      let numOfPosts = 0
      const flattenArrayDetailsPostsToCompare = _.flatMapDeep(detailsPostsToCompare, (brand) => {
        numOfPosts += brand.postsCount
        return [brand.averages];
      });
      let calcesAverageDetailsPostsToCompare = calcGroupAverages(flattenArrayDetailsPostsToCompare);
      dispatch({
        type: CONSTANTS.SetAverageDetailsComparePosts,
        payload: { 
          averageDetailsPostsToCompare: calcesAverageDetailsPostsToCompare,
          averagedPostCount: numOfPosts
         },
      });
    } else {
      if(isClear){
        setTheme('off');
      }
      dispatch({
        type: CONSTANTS.ResetAverageDetailsComparePosts,
        payload: { },
      });
    }

  };

  return (
    <Wrapper>
      {!isComapreOpen && !afterCompare && (
        <Button onClick={() => toggleCompare(true)}>
          <img src={assets["add"]} alt="add btn" />
          <p>Add to compare</p>
        </Button>
      )}
        {isComapreOpen && (
        <CompareContainer>
          <CompareProcess
            setIsCompareOpen={setIsComapreOpen}
            setIsCompare={setIsCompare}
            isCompareOpen={isComapreOpen}
          />
        </CompareContainer>
      )}

      {!isComapreOpen && afterCompare && (
        <>
          <Button onClick={() => toggleCompare(false)}>
            <img src={assets["add"]} style={{ marginRight: "1px" }} alt="add btn" />
          </Button>

          <ButtonClear onClick={onCancelProcess}>
            <img src={assets["trashCan"]} alt="add btn" />
            <p>Clear</p>
          </ButtonClear>

          <CompareText>
            <p>Compare to {brandsToCompare.length} brands </p>
          </CompareText>

          <SwitcherWrapper theme={theme}>
            <p>Show Competitive Average</p>
            <div className="switcher-button">
              <input type="checkbox" onClick={() => toggleSwitcher(false)} id="switcher" />
              <label onClick={() =>toggleSwitcher(false)}></label>
            </div>
          </SwitcherWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default CompareStart;
