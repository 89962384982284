export enum CONSTANTS {
  SetBrands = 'SET_BRANDS',
  SetBrandIsActive = "SET_BRAND_IS_ACTIVE",
  AddPost = 'ADD_POST',
  RemovePost = 'REMOVE_POST',
  SetPostsData = 'SET_POSTS_DATA',
  ResetPosts = 'RESET_POSTS',
  ResetPostsData = 'RESET_POSTS_DATA',
  SetCurrentPost = 'SET_CURRENT_POST',
  SetCurrentPostsGroup = 'SET_CURRENT_POSTS_GROUP',
  SetAllPostsChecked = 'SET_ALL_POSTS_CHECKED',
  SetCurrentPostGroupAverages = 'SET_CURRENT_POST_GROUP_AVERAGES',
  EmptyCurrentPostsGroup = 'EMPTY_CURRENT_POSTS_GROUP',
  SetCurrentPostsGroupName = 'SET_CURRENT_POSTS_GROUP_NAME',
  RemovePostData = 'REMOVE_POST_DATA',
  AddToWordBank = 'ADD_TO_WORD_BANK',
  RemoveFromWordBank = 'REMOVE_FROM_WORD_BANK',
  SetDateRange = 'SET_DATE_RANGE',
  SetReport = 'SET_REPORT',
  AddPosts = 'ADD_POSTS',
  SetIsInitialzied = 'SET_IS_INITIALIZED',
  Reset = 'RESET',
  SetPostIsActive = 'SET_POST_IS_ACTIVE',
  SetSingleCommentIsActive = 'SET_SINGLE_COMMENT_IS_ACTIVE',
  SetAllPostsIsActive = 'SET_ALL_POSTS_IS_ACTIVE',
  SetAllCommentsIsActive = 'SET_ALL_COMMENTS_IS_ACTIVE',
  SetSingleWordIsActive = 'SET_SINGLE_WORD_IS_ACTIVE',
  SetSingleCommentInWordIsActive = 'SET_SINGLE_COMMENT_IN_WORD_IS_ACTIVE',
  SetAllWordsIsACtive = 'SET_ALL_WORDS_IS_ACTIVE',
  DeleteActionForWordFrequencies = 'DELETE_ACTION_FOR_WORD_FREQUENCIES',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  EditSentiment = 'EDIT_SENTIMENT',
  AddSelectedBrandsToCompare = 'ADD_SELECTED_BRANDS_TO_COMPARE',
  CompareSetPostIsActive  = 'COMPARE_SET_POST_IS_ACTIVE',
  CompareSetAllPostsIsActive = 'COMPARE_SET_ALL_POSTS_IS_ACTIVE',
  ComparePosts = 'COMPARE_POSTS',
  SetComparePostActive = 'SET_COMPARE_POST_ACTIVE',
  AddSingleBrand = 'ADD_SINGLE_BRAND',
  ResetBrandsAndPostsToCompare = 'RESET_BRANDS_AND_POSTS_TO_COMPARE',
  SetDeatilsComparePosts = 'SET_DEATILS_COMPARE_POSTS',
  ToggleGroupExpention = 'TOGGLE_GROUP_EXPENTION',
  SetGroupExpention = 'SET_GROUP_EXPENTION',
  SetAverageDetailsComparePosts = 'SET_AVERAGE_DETAILS_COMPARE_POSTS',
  ResetAverageDetailsComparePosts = 'RESET_AVERAGE_DETAILS_COMPARE_POSTS',
  AddAlertComment = 'ADD_ALERT_COMMENT',
  ResetAllCommentCollection = 'RESET_ALL_COMMENT_COLLECTION',
  AddPostAlertComment = 'ADD_POST_ALERT_COMMENT',
  AddEditPostAlertComment = 'ADD_EDIT_POST_ALERT_COMMENT',
  SetEditPostAlert = 'SET_EDIT_POST_ALERT',
  ResetEditPostAlert = 'RESET_EDIT_POST_ALERT',
  SetDatePickAlerts = 'SET_DATE_PICK_ALERTS',
  SetWordBankAlerts = 'SET_WORD_BANK_ALERTS',
  SetAfterCompare = 'SET_AFTER_COMPARE',
  SetNavBarCollapsed = 'SET_NAVNBAR_COLLAPSED',
}

export const REACTIONS_ORDER: {[index:string]: number} = {
  like: 0,
  love: 1,
  haha: 2,
  wow: 3,
  care: 4,
  sad: 5,
  angry: 6,
}