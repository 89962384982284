import { useContext, useEffect } from "react";
import { Switch, Route, useRouteMatch, useHistory, Redirect } from "react-router-dom";
import PostBox from "../components/ui/post/PostBox";
import { PostRouterContainer } from "./styles";
import { AppContext } from "../store/context";
import Comments from "../components/ui/post/comments/Comments";
import Compare from "../components/ui/post/Compare/Compare";
import PostRouterTabs from "../components/ui/tabs/post-router/PostRouterTabs";
import Reactions from "../components/ui/post/reactions/Reactions";
import WordFrequenciesManager from "../components/ui/post/word-frequencies/WordFrequenciesManager";
import Graphs from "../components/ui/post/graphs/Graphs";
import Alerts from "../components/ui/post/alerts/Alerts";
import PostsRow from "../components/ui/posts-row/PostsRow";

interface POST_ROUTER_PROPS {
  setNestedRouteMatch: React.Dispatch<React.SetStateAction<null | string>>;
}

const PostRouter = ({ setNestedRouteMatch }: POST_ROUTER_PROPS) => {
  const routeMatch = useRouteMatch();
  const history = useHistory();
  let groupName: string = '';
  const {
    state: {
      report: { currentPost, name: reportName, currentPostsGroup, groupExpended }
    }
  } = useContext(AppContext);

  useEffect(() => {
    setNestedRouteMatch("/:report/post/:postId");
    setNestedRouteMatch("/:report/posts-group/:groupName");
    return () => {
      setNestedRouteMatch(null);
    };
  }, [setNestedRouteMatch]);

  const getFullPath = (path: string) => {
    const routeMatchPath = routeMatch.path;
    return routeMatchPath + path;
  };

  const redirect = (path: string) => {
    const newPath = routeMatch.url + path;
    history.push(newPath);
  };

  const isGroup = currentPostsGroup.average && currentPostsGroup.posts ? true : false;
  if (!currentPost) {
    if(!isGroup){
      return <Redirect to={`/{${reportName}}`} />;
    } else {
     // turn group into post
     groupName = currentPostsGroup.groupName;
    }
  }

  return (
    <>
      <PostRouterContainer>
        { isGroup && groupExpended ? 
         (currentPostsGroup.posts && currentPost) && <PostsRow posts={currentPostsGroup.posts} groupName={groupName} src="post-router" />
          : (currentPost) && 
          <PostBox post={currentPost} groupName={groupName} src="post-router" />
        }
        <PostRouterTabs redirect={redirect} isGroup={isGroup && !groupExpended} />
        <Switch>
          <Route exact path={`${getFullPath("/comments")}`} component={Comments} />
          <Route exact path={`${getFullPath("/reactions")}`} component={Reactions} />
          <Route path={`${getFullPath("/frequencies")}`} component={WordFrequenciesManager} />
          <Route exact path={`${getFullPath("/graphs")}`} component={Graphs} />
          <Route exact path={`${getFullPath("/report")}`} component={Comments} />
          <Route exact path={`${getFullPath("/compare")}`} component={Compare} />
          <Route exact path={`${getFullPath("/Alerts")}`} component={Alerts} />
          <Redirect from="*" to="/" />
        </Switch>
      </PostRouterContainer>
    </>
  );
};

export default PostRouter;
