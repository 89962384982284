import moment from "moment";
import { CATEGORIES } from "../../../pages/alerts/helper";
export const mockAlertsData = {
    totalAlerts: 1000,
    alerts: [
      {
        category: CATEGORIES.dangerous,
        totalAlerts: 250,
        HighestFreqWord: "Explosive",
        wordFreq: 42,
      },
      {
        category: CATEGORIES.offensive,
        totalAlerts: 190,
        HighestFreqWord: "Hate",
        wordFreq: 13,
      },
      {
        category: CATEGORIES.other,
        totalAlerts: 300,
        HighestFreqWord: "Tasty",
        wordFreq: 10,
      },
      {
        category: CATEGORIES.contactRequests,
        totalAlerts: 260,
        HighestFreqWord: "Support",
        wordFreq: 8,
      },
    ],
  };

export const mockAlertsTable = [
    {
      date: moment(new Date()).add(2).format("DD.MM.YY"),
      type: "instagram",
      post_id: "1658649031115908_3091940927786704",
      words: ["Wow, Hello", "Amazing"],
      amount: "5 in 16 comments",
      brand_name: "So Yummy",
      category: CATEGORIES.dangerous,
      comments:[
        {
          date:'22.05.21',
          content:'So Yummy 1 Denny use  Danger  watermelon all the time !',
          category:CATEGORIES.dangerous,
          comment_id:'0501212'
        },
        {
          date:'04.05.21',
          content:'So Yummy 1 Why  waste  the great taste of bomb bombs word here melon with all those bombs artificial flavors?',
          category:CATEGORIES.offensive,
          comment_id:'02227'
        },
        {
          date:'09.10.20',
          content:'So Yummy 1 Why  waste  the great taste of bomb bombs word here melon with all those bombs artificial flavors?',
          category:CATEGORIES.other,
          comment_id:'44433'
        },
      ]
    },
    {
      date: moment(new Date()).add(2).format("DD.MM.YY"),
      type: "facebook",
      post_id: "1658649031115908_3097603033887160",
      words: ["Wow, Hello", "Amazing"],
      amount: "5 in 16 comments",
      brand_name: "So Yummy",
      category: CATEGORIES.offensive,
      comments:[
        {
          date:'21.02.21',
          content:'So Yummy 2 Denny use  Danger  watermelon all the time !',
          category:CATEGORIES.dangerous,
          comment_id:'2102'
        },
        {
          date:'05.07.21',
          content:'So Yummy 2 Why  waste  the great taste of bomb bombs word here melon with all those bombs artificial flavors?',
          category:CATEGORIES.offensive,
          comment_id:'0507'
        },
        {
          date:'12.15.20',
          content:'So Yummy 2 Why  waste  the great taste of bomb bombs word here melon with all those bombs artificial flavors?',
          category:CATEGORIES.other,
          comment_id:'1215'
        },
      ]
    },
    {
      date: moment(new Date()).add(1).format("DD.MM.YY"),
      type: "facebook",
      post_id: "1614251518827491_3650349521884337",
      words: ["Wow, Hello", "Amazing"],
      amount: "5 in 16 comments",
      brand_name: "Tasty",
      category: CATEGORIES.other,
      comments:[
        {
          date:'09.09.21',
          content:'Tasty 1 Denny use  Danger  watermelon all the time !',
          category:CATEGORIES.dangerous,
          comment_id:'0909'
        },
        {
          date:'04.02.21',
          content:'Tasty 1 Why  waste  the great taste of bomb bombs word here melon with all those bombs artificial flavors?',
          category:CATEGORIES.offensive,
          comment_id:'04021'
        },
        {
          date:'1.1511.20',
          content:'Tasty 1 Wy  waste  the great taste of bomb bombs word here melon with all those bombs artificial flavors?',
          category:CATEGORIES.other,
          comment_id:'1511'
        },
      ]
    },
    {
      date: moment(new Date()).add(3).format("DD.MM.YY"),
      type: "facebook",
      post_id: "1614251518827491_3650215418564414",
      words: ["Wow, Hello", "Amazing"],
      amount: "5 in 16 comments",
      brand_name: "So Yummy",
      category: CATEGORIES.dangerous,
      comments:[
        {
          date:'125.05.21',
          content:'So Yummy 3 Denny use  Danger  watermelon all the time !',
          category:CATEGORIES.dangerous,
          comment_id:'125'
        },
        {
          date:'0123.03.21',
          content:'So Yummy 3 Why  waste  the great taste of bomb bombs word here melon with all those bombs artificial flavors?',
          category:CATEGORIES.offensive,
          comment_id:'0123'
        },
        {
          date:'142.11.20',
          content:'So Yummy 3 Why  waste  the great taste of bomb bombs word here melon with all those bombs artificial flavors?',
          category:CATEGORIES.other,
          comment_id:'142'
        },
      ]
    },
    {
      date: moment(new Date()).add(4).format("DD.MM.YY"),
      type: "facebook",
      post_id: "1614251518827491_3646485555604067",
      words: ["Wow, Hello", "Amazing"],
      amount: "5 in 16 comments",
      brand_name: "So Yummy",
      category: CATEGORIES.contactRequests,
      comments:[
        {
          date:'21235.05.21',
          content:'Denny use  Danger  watermelon all the time !',
          category:CATEGORIES.dangerous,
          comment_id:'21235'
        },
        {
          date:'07213.03.21',
          content:'Why  waste  the great taste of bomb bombs word here melon with all those bombs artificial flavors?',
          category:CATEGORIES.offensive,
          comment_id:'07213'
        },
        {
          date:'14123.11.20',
          content:'Why  waste  the great taste of bomb bombs word here melon with all those bombs artificial flavors?',
          category:CATEGORIES.other,
          comment_id:'14123'
        },
      ]
    },
  ];

  export const mockFreqWords = [
    {
      word:'Hate',
      category:CATEGORIES.offensive
    },
    {
      word:'Damage',
      category:CATEGORIES.dangerous
    },
    {
      word:'Jew',
      category:CATEGORIES.offensive
    },
    {
      word:'Hamas',
      category:CATEGORIES.contactRequests
    },
    {
      word:'Vodka Redbull',
      category:CATEGORIES.dangerous
    }
  ]

  export const mockPostComments = [
    {
      date: moment(new Date()).add(2).format("DD.MM.YY"),
      type: "instagram",
      post_id: "1658649031115908_3091940927786704",
      words: ["Wow", "Hello", "Amazing", "Danger", "bombs", "bomb"],
      amount: "5 in 16 comments",
      brand_name: "So Yummy",
      category: CATEGORIES.dangerous,
      comments:[
        {
          date:'22.05.21',
          content:'So Yummy 1 Denny use  Danger  watermelon all the time !',
          category:CATEGORIES.dangerous,
          amount : '3',
          comment_id:'0501212'
        },
        {
          date:'04.05.21',
          content:'So Yummy 1 Why  waste  the great taste of bomb bombs word here melon with all those bombs artificial flavors?',
          category:CATEGORIES.offensive,
          amount : '1',
          comment_id:'02227'
        },
        {
          date:'09.10.20',
          content:'So Yummy 1 Why  waste  the great taste of bomb bombs word here melon with all those bombs artificial flavors?',
          category:CATEGORIES.other,
          amount : '2',
          comment_id:'44433'
        },
      ]
    }
  ]