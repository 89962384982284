import { Fragment, useContext, useMemo, useEffect, useRef } from "react";
import { useTable, Column } from "react-table";
import { AppContext } from "../../../../store/context";
import { COMMENT_ALERT, COMMENT, POST_ALERT_WORD } from "../../../../store/interface";
import { assets } from "../../../ui/utils/assets";
import { Icon } from "../../../ui/utils/CommonStyles";
import { CommentsTableStyled, Label, Input, Indicator,SingleComment, Spacer } from "./Styles";
import { CONSTANTS } from "../../../../store/constants";
import { handleMarkWords, applyCategoryToTable } from '../helper'
import moment from "moment";
const AlertsCommentsTable = ({
  words,
  data,
  setSpreaderHeight
}:{
    setSpreaderHeight:(spreaderHeight:number) =>void,
    words:string[],
    data:COMMENT_ALERT[]
}) => {
  const {
    state: { alert, report },
    dispatch
  } = useContext(AppContext);
  const { alertCommentsCollection, wordBank } = alert
  const { navBarCollaped } = report
 
  const handleCommentCheck = (comment_id:string, checked:boolean) =>{
    dispatch({
      type: CONSTANTS.AddAlertComment,
      payload: { alertComment_id:comment_id, isActive: checked },
    });
  }
  const renderAsHTML = ( content: string ) => { 
    return(
      {__html:content  }
    )
  }

  const spreaderRef = useRef<HTMLTableSectionElement>(null)

  useEffect(()=>{
    if(spreaderRef.current){
      setSpreaderHeight(spreaderRef.current.offsetHeight + 90)
    }
  },[setSpreaderHeight, navBarCollaped])
  
  const columns: Column<{
    _id:string;
    alerted_words:string[];
    comment_id:string;
    category:number;
    createdAt:Date | string;
    updateAt:Date;
    comment:COMMENT;
    message:string
  }>[] = useMemo(
    () => [
      {
        id: 'checkbox',
        Header: '',
        sortType: (a: any, b: any) => {
          return a.original.post_id > b.original.post_id ? -1 : 1;
        },
        Cell: ({ value,row }: { value:any, row: any }) => {
          const isActive = alertCommentsCollection.find((comment) => comment.alertComment_id === row.original.comment_id)?.isActive;
          return (
            <div>
                <Label>
                  <Input
                    id={`checkbox-${row.original.comment_id}`}
                    type="checkbox"
                    checked={isActive}
                    className="pointer dropdown"
                    src={assets['dropDown']}
                    onChange={(e) => {
                      handleCommentCheck(row.original.comment_id,e.target.checked);
                    }}
                  />
                  <Indicator/>
                </Label>
            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ row }: { row: any }) => {
          const date = moment(row.original.createdAt).format("DD-MM-YYYY");
          return date;
        },
      },
      {
        Header: "Content",
        accessor: "message",
        Cell: ({ row }: { value:any, row: any }) => {
          const newPostAlertedWords:POST_ALERT_WORD[] = []

            words.forEach((word) => {
              wordBank.forEach((wordBankWord)=>{
                if ( wordBankWord.word === word ){
                  newPostAlertedWords.push({
                    word:wordBankWord.word,
                    category:wordBankWord.category
                  })
                }
              })
            })
            const comment = handleMarkWords(row.original.message,row.original.category, newPostAlertedWords)
            return (
              <>
              <SingleComment dangerouslySetInnerHTML={renderAsHTML(comment)}>
        
              </SingleComment>
              </>
            );
            
          
          
        },
      },
      {
        Header: "Amount",
        accessor: "alerted_words",
        Cell: ({row}) => {
          return(
            <p>{row.original.alerted_words.length}</p>
          )
        },
      },
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ row }) => {
          return(
            <Icon src={assets[applyCategoryToTable(row.original.category)]} />
          )
        },
      },
    ],
    [alertCommentsCollection,wordBank, words ]
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable<{
    _id:string;
    alerted_words:string[];
    comment_id:string;
    category:number;
    createdAt:Date | string;
    updateAt:Date;
    comment:COMMENT;
    message:string
  }>({
    columns,
    data,
  });

  return (
    <CommentsTableStyled navBarOpen={!navBarCollaped} {...getTableProps()}>
      
      <tbody {...getTableBodyProps()} ref={spreaderRef}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <>
            <tr {...row.getRowProps()} key={rowIndex}>
              {row.cells.map((cell, i) => {          
                let className = row.cells.length - 1 === i ? 'last-cell' : '';
                return (
                  <Fragment key={i}>
                    <td className={className} {...cell.getCellProps()}>
                      <div>
                        {cell.render("Cell")} 
                      </div>
                      </td>
                  </Fragment>
                );
              })}
            </tr>
            <Spacer/>
            </>
          );
        })}
      </tbody>
      
    </CommentsTableStyled>
  );
};

export default AlertsCommentsTable