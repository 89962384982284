import { Fragment, useMemo } from 'react';
import { useTable, Column } from 'react-table';
import { PARSED_POST } from '../../../../../../store/interface';
import { assets } from '../../../../utils/assets';
import { Icon } from '../../../../utils/CommonStyles';
import { TABLE_PROPS } from '../interface';
import { addCompare } from '../Styles';
import DatePickerDisplay from "../../DatePicker/DatePicker";

const { 
    TableStyled,
    Label,
    Input,
    Indicator,
    CompareDateContainer
} = addCompare

const ComparePostsDisplay = ({
  data,
  handleCheckBox,
  handleAllCheckBox,
  isAllActive
}: TABLE_PROPS) => {
  
  const columns: Column<PARSED_POST>[] = useMemo(
    () => [
      {
        Header: <input type="checkbox" />,
        accessor: 'isActive',
        width:'10%',
        Cell: ({ value, row }) => (
          <Label className="post-comapre">
            <Input
            checked={row.original.isActive}
            value={row.original.id}
            type="checkbox"
            style={{marginTop:'7px'}}
            onChange={(e) => {
              handleCheckBox(row.original.id, e.target.checked);
            }}
          />
          <Indicator isComparePage/>
          </Label>
        ),
      },
      {
        Header: 'Type',
        accessor: 'type',
        width:'10%',
        Cell: ({ value }) => <Icon src={assets[value]} />,
      },
      {
        Header: 'ID',
        accessor: 'processedId',
        width:'30%',
      },
      {
        Header: 'Post Title',
        accessor: 'description',
        Cell: ({ value }) => <p style={
          {
          maxHeight: '40px',
          maxWidth: '100%',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }
        } >{value}</p>,
      },
    ],
    [handleCheckBox]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<PARSED_POST>({
    columns,
    data,
  });

  
  return (
    <>  
      <CompareDateContainer >
        <Icon src={assets["whiteCalendar"]} alt="" />
        <DatePickerDisplay />
      </CompareDateContainer>  
    <TableStyled {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, i) => {
              const { key } = { ...column.getHeaderProps() };
              if (key !== 'header_isActive')
                return (
                  <th {...column.getHeaderProps()} key={i}>
                    {column.render('Header')}
                  </th>
                );
              return (
                <th key={i}>
                  <Label >
                    <Input
                      checked={isAllActive}
                      style={{ marginTop: '6px', marginLeft: '13px' }}
                      type="checkbox"
                      onChange={(e) => {
                        handleAllCheckBox(e.target.checked);
                      }}
                    />
                    <Indicator style={{left:'0.1em'}} />
                  </Label>
                </th>
              );
            })}
            
          </tr>
        ))}
        
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={rowIndex}>
              {row.cells.map((cell, i) => {
                return (
                  <Fragment key={i}>
                    <td  {...cell.getCellProps()}>{cell.render('Cell')} </td>
                  </Fragment>
                );
              })}
            </tr>
          );
          
        })}
      </tbody>
    </TableStyled>
    </>
  );
};

export default ComparePostsDisplay;
