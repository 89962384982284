import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';
import { SINGLE_POST_DETAILED_RESPONSE } from '../../../../../store/interface';
import { Total } from '../commentsSentiment/Styles';
import { Wrapper } from './Styles';
const UserReplies = ({
  totalComments,
  totalCommentsWithReplies,
  isReportPage,
}: {
  totalCommentsWithReplies: SINGLE_POST_DETAILED_RESPONSE['totalCommentsWithReplies'];
  totalComments: number;
  isReportPage: boolean;
}) => {
  const totalCommentsWithoutReplies = totalComments - totalCommentsWithReplies;
  const userRepliesForGraph = [
    { amount: totalCommentsWithReplies, name: 'Comments with replies' },
    { amount: totalCommentsWithoutReplies, name: 'Comments without replies' },
  ];
  const precentage = ((totalCommentsWithReplies / totalComments) * 100).toFixed(0);
  const COLORS = ['#FEBBB7', '#DDE0E6'];

  return (
    <Wrapper>
      <h3 style={{ fontSize: '15px' }}>
        User Replies <Total>{totalCommentsWithReplies}</Total>
        <Total style={{ fontWeight: 600 }}> {precentage}%</Total>
      </h3>
      <ResponsiveContainer height={'80%'} width={'100%'}>
        <PieChart>
          <Pie
            isAnimationActive={!isReportPage}
            data={userRepliesForGraph}
            cy={160}
            cornerRadius={29}
            innerRadius={5}
            paddingAngle={5}
            labelLine={false}
            dataKey="amount"
          >
            {userRepliesForGraph.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip isAnimationActive={!isReportPage} />
        </PieChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

export default UserReplies;
