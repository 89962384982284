import { PARSED_BRAND } from "./../../components/ui/sidebar/interface";
import { PARSED_WORD_FREQUENCY } from "../../components/ui/post/word-frequencies/interface";
import { CONSTANTS } from "../constants";
import {
  POST_ACTIONS,
  REPORT_STATE_TYPE,
  SINGLE_POST_DETAILED_RESPONSE,
  WORD_TAGS,
  PARSED_POST,
  AUTH_ACTIONS,
  ALERT_ACTIONS,
  PostsDetailsAverageByBrand,
  POSTS_GROUP_AVERAGES,
  COMMENT,
} from "../interface";

import {
  calcSentimentsScore,
  getFilteredCommentsForStore,
  removeCommentsFromWordObjectAndBuildFilterArray,
} from "./helper";

export const reportReducer = (state: REPORT_STATE_TYPE, action: POST_ACTIONS | AUTH_ACTIONS | ALERT_ACTIONS) => {
  const { payload, type } = action;

  switch (type) {
    case CONSTANTS.Reset: {
      return {
        ...state,
        posts: [],
        dateRange: null,
        postsData: [],
        currentPost: null,
        currentPostsGroup: {groupName:'', posts: []},
        groupExpended: false,
        allPostsChecked: false,
        isInitialized: false,
        wordBank: {
          competitors: [],
          products: [],
          others: [],
          actionPersuation: [],
          purchaseIntent: [],
          concerns: [],
        },
        brands: [],
        afterCompare: false,
        navBarCollaped: false
      };
    }

    case CONSTANTS.SetIsInitialzied: {
      const { isInitialized } = payload as { isInitialized: boolean };
      return {
        ...state,
        isInitialized,
      };
    }

    case CONSTANTS.AddPosts: {
      const { posts } = payload as { posts: PARSED_POST[] };
      return {
        ...state,
        posts,
        postsData: [],
      };
    }
    // case CONSTANTS.AddPostsToCompare: {
    //   const { postsToCompare } = payload as { postsToCompare: PARSED_POST[] };
    //   return {
    //     ...state,
    //     postsToCompare,
    //   };
    // }

    case CONSTANTS.AddPost: {
      const { post } = payload as { post: PARSED_POST };
      return {
        ...state,
        posts: [post, ...state.posts],
        postsData: [],
      };
    }

    case CONSTANTS.SetPostIsActive: {
      let newPosts = [...state.posts];
      const { postId, isActive } = payload as {
        postId: string;
        isActive: boolean;
      };
      let postToEdit = newPosts.findIndex((post) => post.id === postId);
      newPosts[postToEdit].isActive = isActive;
      return {
        ...state,
        posts: newPosts,
      };
    }

    case CONSTANTS.SetSingleCommentIsActive: {
      const { commentId, isActive } = payload as {
        commentId: string;
        isActive: boolean;
      };
      let comments = [...state.currentPost!.comments];
      let commentToEdit: COMMENT;
      for( let comment of comments){
        if(comment.comment_id === commentId){
          commentToEdit = comment
          break;
        }
      }
      commentToEdit!.isActive = isActive;
      return {
        ...state,
        currentPost: { ...state.currentPost!, comments }, 
      };
    }

    case CONSTANTS.SetAllPostsIsActive: {
      let newPosts = [...state.posts];
      const { isActive } = payload as { isActive: boolean };
      newPosts.forEach((post) => (post.isActive = isActive));
      return {
        ...state,
        posts: newPosts,
      };
    }

    case CONSTANTS.SetSingleWordIsActive: {
      const { word, isActive } = payload as { word: string; isActive: boolean };

      let newWordFrequencies = { ...state.currentPost!.wordFrequencies };
      newWordFrequencies[word].isActive = isActive;
      newWordFrequencies[word].comments.forEach((comment) => (comment.isActive = isActive));
      return {
        ...state,
        currentPost: {
          ...state.currentPost!,
          wordFrequencies: newWordFrequencies,
        },
      };
    }

    case CONSTANTS.SetSingleCommentInWordIsActive: {
      const { word, commentId, isActive } = payload as {
        word: string;
        commentId: string;
        isActive: boolean;
      };
      let newWordFrequencies = { ...state.currentPost!.wordFrequencies };
      const wordObject = newWordFrequencies[word];
      const commentIndex = wordObject.comments.findIndex((comment) => comment.id === commentId);
      if (commentIndex >= 0) {
        wordObject.comments[commentIndex].isActive = isActive;
        if (!isActive) {
          //cancel checkbox value for parent checkbox
          wordObject.isActive = false;
        }
      }
      return {
        ...state,
        currentPost: {
          ...state.currentPost!,
          wordFrequencies: newWordFrequencies,
        },
      };
    }

    case CONSTANTS.SetAllWordsIsACtive: {
      const { isActive, words } = payload as {
        isActive: boolean;
        words: PARSED_WORD_FREQUENCY[];
      };
      let newWordFrequencies = { ...state.currentPost!.wordFrequencies };

      for (const wordObject of words) {
        const { word, comments } = wordObject;
        newWordFrequencies[word].isActive = isActive;
        comments.forEach((comment) => {
          comment.isActive = isActive;
        });
      }
      return {
        ...state,
        currentPost: {
          ...state.currentPost!,
          wordFrequencies: newWordFrequencies,
        },
      };
    }

    case CONSTANTS.DeleteActionForWordFrequencies: {
      const { wordFrequencies } = { ...state.currentPost! };

      const { newWordFrequencies, allCommentIdsToRemove } =
        removeCommentsFromWordObjectAndBuildFilterArray(wordFrequencies);
      const currentPostCommentsMock = [...state.currentPost!.comments];
      const filteredComments = getFilteredCommentsForStore(currentPostCommentsMock, allCommentIdsToRemove);
      const newAverageSentimentScore: number = calcSentimentsScore(filteredComments);
      return {
        ...state,
        currentPost: {
          ...state.currentPost!,
          comments: filteredComments,
          wordFrequencies: newWordFrequencies,
          averageSentimentScore: newAverageSentimentScore,
        },
      };
    }
    case CONSTANTS.EditSentiment: {
      const { commentId, sentimentValue } = payload as {
        commentId: string;
        sentimentValue: number;
      };
      
      const newPostCommentsMock = [...state.currentPost!.comments];
      let commentToEdit: any;
      for( let comment of newPostCommentsMock){
        if(comment.comment_id === commentId){
          commentToEdit = comment
          break;
        }
      }
      let newAverageSentimentScore = state.currentPost!.averageSentimentScore;
      // if it didnt have a sentiment analysis, and we still want to set is as natural, no need for edit
      if (commentToEdit) {
        commentToEdit!.result = sentimentValue;
        newAverageSentimentScore = calcSentimentsScore(newPostCommentsMock);
      }

      return {
        ...state,
        currentPost: {
          ...state.currentPost!,
          comments: newPostCommentsMock,
          averageSentimentScore: newAverageSentimentScore,
        },
      };
    }

    case CONSTANTS.RemovePost: {
      const { postIndex } = payload as { postIndex: number };
      const newPosts = [...state.posts];
      newPosts.splice(postIndex, 1);
      return {
        ...state,
        posts: newPosts,
      };
    }

    case CONSTANTS.RemovePostData:
      const { postIndex } = payload as { postIndex: number };
      const newPostsData = [...state.postsData];
      newPostsData.splice(postIndex, 1);
      return {
        ...state,
        postsData: newPostsData,
      };

    case CONSTANTS.SetReport: {
      const { name } = payload as { name: string };
      return {
        ...state,
        name,
      };
    }

    case CONSTANTS.SetPostsData: {
      const { posts: newPostsData } = payload as {
        posts: SINGLE_POST_DETAILED_RESPONSE[];
      };
      return {
        ...state,
        postsData: newPostsData,
        currentPost: null,
      };
    }
    case CONSTANTS.ResetPostsData: {
      return {
        ...state,
        postsData: [],
        currentPost: null,
      };
    }
    case CONSTANTS.ResetPosts: {
      return {
        ...state,
        posts: [],
        currentPost: null,
      };
    }
    case CONSTANTS.AddToWordBank: {
      const { wordBank } = payload as { wordBank: WORD_TAGS };
      return {
        ...state,
        postsData: [],
        wordBank,
      };
    }
    case CONSTANTS.RemoveFromWordBank: {
      const { wordBank } = payload as { wordBank: WORD_TAGS };
      return {
        ...state,
        postsData: [],
        wordBank,
      };
    }
    case CONSTANTS.SetCurrentPost: {
      const { post: newCurrentPost } = payload as {
        post: SINGLE_POST_DETAILED_RESPONSE;
      };
      return {
        ...state,
        currentPost: newCurrentPost,
      };
    }
    case CONSTANTS.SetCurrentPostsGroup: {
      const { post: newCurrentPost, value } = payload as {
        post: SINGLE_POST_DETAILED_RESPONSE;
        value: boolean;
      };
      let currentGroupPosts = state.currentPostsGroup?.posts ? state.currentPostsGroup.posts : [];
      let currentGroupName = state.currentPostsGroup?.groupName ? state.currentPostsGroup.groupName : "";
      if (value) {
        const storedPost = currentGroupPosts.find((post) => {
          return post.postDetails.id === newCurrentPost.postDetails.id;
        });
        if (!storedPost) {
          currentGroupPosts = [...currentGroupPosts, newCurrentPost];
        }
      } else {
        currentGroupPosts = currentGroupPosts.filter((post) => {
          return post.postDetails.id !== newCurrentPost.postDetails.id;
        });
      }

      return {
        ...state,
        currentPostsGroup: { groupName: currentGroupName, posts: currentGroupPosts },
      };
    }
    case CONSTANTS.EmptyCurrentPostsGroup: {
      return {
        ...state,
        currentPostsGroup: { groupName: "", posts: [] },
      };
    }
    case CONSTANTS.SetCurrentPostsGroupName: {
      const { currentGroupName } = payload as {
        currentGroupName: string;
      };
      const currentGroupPosts = state.currentPostsGroup?.posts ? state.currentPostsGroup.posts : [];

      return {
        ...state,
        currentPostsGroup: { groupName: currentGroupName, posts: currentGroupPosts },
      };
    }
    case CONSTANTS.SetDateRange: {
      const { dateRange } = payload as {
        dateRange: { startDate: Date; endDate: Date };
      };
      return {
        ...state,
        dateRange,
      };
    }

    case CONSTANTS.SetBrands: {
      const { brands } = payload as { brands: PARSED_BRAND[] };
      return {
        ...state,
        brands,
        isInitialized: true,
      };
    }

    case CONSTANTS.SetBrandIsActive: {
      const { page_id: pageId, isActive } = payload as {
        page_id: string;
        isActive: boolean;
      };

      const newBrands = [...state.brands];

      const currentBrand = newBrands.find(({ page_id }) => pageId === page_id);

      if (currentBrand) {
        currentBrand.isActive = isActive;
      }

      return {
        ...state,
        brands: newBrands,
      };
    }

    case CONSTANTS.AddSelectedBrandsToCompare: {
      const { brand_name, page_id, isActive } = payload as {
        brand_name: string;
        page_id: string;
        isActive: boolean;
      };
      let brandsToCompare;
      if (isActive) {
        brandsToCompare = [...state.brandsToCompare, { brand_name, page_id, isActive }];
      } else {
        brandsToCompare = state.brandsToCompare.filter((brand) => {
          return brand.page_id !== page_id;
        });
      }

      return {
        ...state,
        brandsToCompare: brandsToCompare,
      };
    }

    case CONSTANTS.AddSingleBrand: {
      const { brand_name, page_id, isActive } = payload as {
        brand_name: string;
        page_id: string;
        isActive: boolean;
      };
      return {
        ...state,
        brands: [...state.brands, { brand_name, page_id, isActive }],
      };
    }

    case CONSTANTS.ResetBrandsAndPostsToCompare: {
      return {
        ...state,
        brandsToCompare: [],
        postsToCompare: [],
        detailsPostsToCompare: {},
        averageDetailsPostsToCompare:{},
        averagedPostCount:0
      };
    }

    case CONSTANTS.ComparePosts: {
      const { id, isActive } = payload as {
        id: string;
        isActive: boolean;
      };
      let newPostsToCompare;
      if (isActive) {
        newPostsToCompare = [...state.postsToCompare, { id, isActive }];
      } else {
        newPostsToCompare = state.postsToCompare.filter((post) => {
          return post.id !== id;
        });
      }

      return {
        ...state,
        postsToCompare: newPostsToCompare,
      };
    }

    case CONSTANTS.SetDeatilsComparePosts: {
      const { detailsPostsToCompare: newPostCompareDetails } = payload as {
        detailsPostsToCompare: PostsDetailsAverageByBrand;
      };

      return {
        ...state,
        detailsPostsToCompare: newPostCompareDetails,
      };
    }

    case CONSTANTS.SetAllPostsChecked: {
      const { allChecked } = payload as { allChecked: boolean };
      return {
        ...state,
        allPostsChecked: allChecked,
      };
    }

    case CONSTANTS.SetCurrentPostGroupAverages: {
      const { currentPostGroupAverages } = payload as { currentPostGroupAverages: POSTS_GROUP_AVERAGES };
      return {
        ...state,
        currentPostsGroup: {
          groupName: state.currentPostsGroup.groupName,
          posts: state.currentPostsGroup.posts,
          average: currentPostGroupAverages,
        },
      };
    }

    case CONSTANTS.SetAverageDetailsComparePosts: {
      const { 
        averageDetailsPostsToCompare: newPostCompareDetails, 
        averagedPostCount: newAveragedPostCount
      } = payload as {
        averageDetailsPostsToCompare: POSTS_GROUP_AVERAGES;
        averagedPostCount:number
      };

      return {
        ...state,
        averageDetailsPostsToCompare: newPostCompareDetails,
        averagedPostCount:newAveragedPostCount
      };
    }

    case CONSTANTS.ResetAverageDetailsComparePosts: {
      return {
        ...state,
        averageDetailsPostsToCompare:{},
        averagedPostCount:0
      };
    }


    case CONSTANTS.ToggleGroupExpention: {
      return {
        ...state,
        groupExpended: !state.groupExpended,
      };
    }
    
    case CONSTANTS.SetGroupExpention: {
      const { groupExpended } = payload as { groupExpended: boolean };
      return {
        ...state,
        groupExpended: groupExpended,
      };
    }
     
    case CONSTANTS.SetAfterCompare: {
      const { afterCompare } = payload as {
        afterCompare: boolean
      }
      return {
        ...state,
        afterCompare: afterCompare
      }
    }
     
    case CONSTANTS.SetNavBarCollapsed: {
      const { navBarCollaped } = payload as {
        navBarCollaped: boolean
      }
      return {
        ...state,
        navBarCollaped: navBarCollaped
      }
    }

    default:
      return state;
  }
};
