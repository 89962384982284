import styled from "styled-components";

export const TableStyled = styled.table`
  border-collapse: separate;
  text-align: left;
  margin-bottom: auto;
  margin-top: -10px;
  border-spacing: 0 8px;
  width: 95%;
  td {
    background-color: "rgba(52, 115, 226, 0.1)";
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-style: solid none;
    position: relative;
    height: 40px;
    padding: 0 25px 0 0;
  }

  td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 12px;
  }
  td:nth-child(2) img {
    margin-left: 15px;
    display: block;
  }

  td:nth-child(4) {
    width: 50%;
  }

  td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .trash {
    cursor: pointer;
  }

  tr {
    font-size: 0.8125rem;
    text-align: left;
    height: 40px;
    margin-bottom: 8px;
    &:hover {
      background-color: #fff0d3;
    }
  }

  th {
    line-height: 1.0625rem;
    color: "rgba(51, 59, 80, 0.6)";
    font-weight: 300;
    text-align: left;
    height: 40px;
    &:hover {
      background-color: none;
    }
  }

  th:first-child {
    border-bottom: 0;
  }
`;
