import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { assets } from '../../utils/assets';
import { Icon } from '../../utils/CommonStyles';
import { Tab, Tabs } from './Styles';

interface ROUTER_TABS_PROPS {
  redirect: (path: string) => void;
  isGroup?: boolean
}

const tabs = [
  {
    title: 'Comments',
    path: '/comments',
    iconSrc: assets['commentTab'],
  },
  {
    title: 'Reactions',
    path: '/reactions',
    iconSrc: assets['thumbsUp'],
  },
  {
    title: 'Word Frequencies',
    path: '/frequencies',
    iconSrc: assets['watch'],
  },
  {
    title: 'Graphs',
    path: '/graphs',
    iconSrc: assets['chart'],
  },
  {
    title: 'Compare',
    path: '/compare',
    iconSrc: assets['view'],
  },
  {
    title: 'Alerts',
    path: '/alerts',
    iconSrc: assets['Alerts'],
  },
];

const PostRouterTabs = ({ redirect, isGroup }: ROUTER_TABS_PROPS) => {
  const [currentTab, setCurrentTab] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const pathnameArray = location.pathname.split('/');
    const pathnameParsed = `/${pathnameArray[pathnameArray.length - 1]}`;
    const indexOfTab = tabs.findIndex((tab) => tab.path === pathnameParsed);
    if (indexOfTab >= 0) {
      if(indexOfTab === 0 && isGroup){
        const nextIndex = indexOfTab + 1;
        setCurrentTab(nextIndex);
      } else {
        setCurrentTab(indexOfTab);
      }
    }
  }, [location, isGroup]);
  const handleNavigate = (index: number, path: string) => {
    if(index === 0 && isGroup){
      const nextIndex = index + 1;
      setCurrentTab(nextIndex);
    } else {
      setCurrentTab(index);
    }
    redirect(path);
  };
  return (
    <Tabs>
      {tabs.map(({ title, path, iconSrc }, i) => (
        <Tab
          className={`pointer ${isGroup  && title === tabs[0].title ? 'disabled' : ''}`}
          isSelected={ currentTab === i }
          onClick={isGroup && title === tabs[0].title ? ()=>{} : (e) => handleNavigate(i, path)}
          key={i}  
        >
          <Icon src={iconSrc} alt="" />
          <span>{title}</span>
        </Tab>
      ))}
    </Tabs>
  );
};

export default PostRouterTabs;
