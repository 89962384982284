import styled from "styled-components";
import { colors } from "../../../utils";
import { Icon } from "../utils/CommonStyles";

interface BOX_PROPS {
  isPostRouter: boolean;
}

const Box = styled.div<BOX_PROPS>`
  flex-wrap: wrap;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: ${({ isPostRouter }) => (isPostRouter ? "0 147px 31px 0" : "0 0 10px 0")};
  padding: 14px;
  background-color: ${colors.white};
  width: 100%;

  &.analyze-page {
    width: fit-content;
    min-width: 900px;
  }

  &.group {
    position: relative;
    .stack-0,
    .stack-1,
    .stack-2 {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      top: -1.6px;
      left: 0;
      height: 102%;
      position: absolute;
    }
    .stack-0 {
      width: 905px;
    }
    .stack-1 {
      width: 910px;
    }
    .stack-2 {
      width: 915px;
    }
  }
`;

const GroupNameContainer = styled.div`
  display: flex;
  flex-direction: column;

  .groupName {
    font-size: 14px;
    line-height: 110%;
    color: #333b50;
    font-weight: bold;
  }

  .numOfPosts {
    font-size: 12px;
    line-height: 110%;
    color: rgba(51, 59, 80, 0.6);
  }
`;

const PostImg = styled.img`
  width: 109px;
  height: 109px;
  background-color: black;
`;

const PostGroupImg = styled.div`
  width: 109px;
  height: 109px;
  display: flex;
  background: #d7e6ff;
  justify-content: flex-end;
  flex-direction: column;

  .groupShortName {
    font-weight: 600;
    font-size: 64px;
    line-height: 100%;
    align-items: center;
    background: none;
    width: fit-content;
    margin: 0 auto;
  }
`;

const MiddleCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 14px;
  max-width: 40%;
`;

const LeftColHeader = styled.div`
  display: flex;

  & img {
    margin-right: 12px;
  }

  & span {
    font-weight: 300;
    font-size: 0.8125rem;
    color: ${colors.darkGrey};
  }
`;

const RightColHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .campaign {
    white-space: nowrap;
    font-size: 0.8125rem;
    color: ${colors.darkBlue};
    margin-right: 10px;
    max-width: 200px;
  }

  & .thin {
    color: ${colors.darkGrey};
    font-weight: 300;
    font-size: 0.8125rem;
    margin-left: auto;
  }

  & .divider {
    margin-right: 10px;
  }

  ${Icon} {
    width: 24px;
    height: 24px;
    margin-left: 40px;
  }
`;

const RightCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  cursor: auto;
`;

const Title = styled.span`
  max-width: 400px;
  font-weight: 600;
  font-size: 0.9375rem;
  color: ${colors.darkGrey};

  @media screen and (max-width: 1024px) {
    &.ellipsis {
      max-width: 270px;
    }
  }

  @media screen and (min-width: 1350px) {
    &.ellipsis {
      max-width: 400px;
    }
  }

  @media screen and (min-width: 1500px) {
    &.ellipsis {
      max-width: 500px;
    }
  }
`;

const ReactionsContainer = styled.div`
  display: flex;
  div {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  span {
    margin-left: 3px;
    font-size: 0.875rem;
    color: ${colors.darkBlue};
  }
  ${Icon} {
    width: 14px;
    height: 14px;
  }

  &.group {
    background: rgba(43, 48, 74, 0.04);
    border-radius: 4px;

    .average-icon {
      padding: 5px 10px;
      border-right: 1px solid #d6d9e0;
    }
  }
`;

const Sentiments = styled.div<{ background?: string }>`
  display: flex;
  margin-left: auto;
  width: 170px;
  line-height: 120%;
  height: 30px;

  &.group {
    background: ${({ background }) => background};
    padding: 2.5px 0;
    border-radius: 4px;
  }
`;

export const postStyles = {
  Box,
  PostImg,
  MiddleCol,
  LeftColHeader,
  RightColHeader,
  RightCol,
  Title,
  ReactionsContainer,
  Sentiments,
  PostGroupImg,
  GroupNameContainer,
};
