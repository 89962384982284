import { LOGIN_RESPONSE } from "../components/ui/sidebar/interface";
import axios from "./index";
export const login = async (email: string, password: string): Promise<LOGIN_RESPONSE> => {
  const response = await axios.post(`/auth/login`, {
    email,
    password,
  });
  return response.data;
};

export const verifyTokenEffect = async (email: string, token: string) => {
  const response = await axios.get("/auth/verify");
  return response.data;
};
