import styled from 'styled-components';
export const FetchedAccountsContainer = styled.div`
  padding: 19px 15px;
  max-height:450px;
  overflow:scroll;
`;
export const SubAccountsContainer = styled.div`
  padding: 10px 40px;
`;

