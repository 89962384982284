import { fetchAlertsWidgetData } from "../../../api/facebookApi";
import { ALERTS_WIDGETS, ALERT_ICON, POST_ALERT_WORD } from "../../../store/interface";
import { colors } from "../../../utils";
export const percentageCalc = (partialValue: number, totalValue: number) => {
  if ( totalValue === 0) {
    return 0
  } else {
    return ((partialValue / totalValue) * 100).toFixed(2);
  }
  
};

export const CATEGORIES = {
  dangerous: "Dangerous",
  offensive: "Offensive",
  other: "Other",
  contactRequests: "Contact",
};

export const handleMarkWords = (comment:string, category:number, words:POST_ALERT_WORD[] ) => {
  let commentRender= comment
  for (let k=0; k<words.length;k++){
  let color:string = ''
  let category:number = words[k].category
  switch ( category ){
    case 3:
      color = colors.dangerousCategory;
      break; 
    case 2:
      color = colors.offensiveCategory;
      break;
    case 1:
      color = colors.otherCategory;
      break;
    case 0:
      color = colors.supportCategory;
      break;

    default:
      color =' '
  }
    let word:string = words[k].word
    if(commentRender.includes(word)){
      commentRender= commentRender.replace(word,`
      <span style=background:${color};border-radius:3px;color:white;padding:4px;margin-right:4px;margin-left:4px;>
        ${word}
      </span>`
      )
    }
  }
  
  return commentRender

}

export const getAllWidgetAlerts = async (date:string) => {
  const alertsWidget: ALERTS_WIDGETS = await fetchAlertsWidgetData(date)

  function dict_reverse(obj:{[key:string]:number}) {
    let new_obj:{[key:string]:number}= {}
    let rev_obj = Object.keys(obj).reverse();
    rev_obj.forEach(function(i) { 
      new_obj[i] = obj[i];
    })
    return new_obj;
  }
  alertsWidget.topCategoriesWords.reverse()
  alertsWidget.widgets = dict_reverse(alertsWidget.widgets)

  return alertsWidget
}

export const applyCategoryToTable = (categoryNum:number) => {
   let categoryType = ''
    switch (categoryNum) {
      case 3:
        categoryType = 'Dangerous'
        break;
      case 2:
        categoryType = 'Offensive'
        break;
      case 1:
        categoryType = 'Other'
        break;
      case 0:
        categoryType = 'Contact'
        break;
    }
    return categoryType
}

export const options = [
  {
    value:'30',
    label:'Last month'
  },
  {
    value:'7',
    label:'Last 7 days'
  },
  {
    value:'1',
    label:'Last 24 hours'
  },
];

export const AlertIcons:ALERT_ICON[] = [
  {
    iconName:'Dangerous',
    id:3,
    count:0
  },
  {
    iconName:'Offensive',
    id:2,
    count:0
  },
  {
    iconName:'Other',
    id:1,
    count:0
  },
  {
    iconName:'Contact',
    id:0,
    count:0
  },
]