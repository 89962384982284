import { Fragment, useContext, useMemo, useState } from 'react';
import { useTable, Column, useSortBy, usePagination } from 'react-table';
import { COMMENT, SINGLE_POST_DETAILED_RESPONSE } from '../../../../store/interface';
import { PrimaryButton } from '../../utils/CommonStyles';
import { ButtonsContainer } from '../CommonStyles';
import { TABLE_PROPS } from './interface';
import { TableStyled, SentimentsContainer } from './Styles';
import { Sentiment } from '../../utils/CommonStyles';
import { getTextByAverageScore } from '../../utils/helper';
import { colors } from '../../../../utils';
import { AppContext } from '../../../../store/context';
import { CONSTANTS } from '../../../../store/constants';
const CommentsTable = ({ data, handleCheckBoxForSingleComment }: TABLE_PROPS) => {
  const {
    state: { report },
    dispatch,
  } = useContext(AppContext);

  const { currentPost, postsData } = report;

  const updateCurrentPostSentiment = (oldResult: number, newResult: number) => {
    const currentSentiment = getTextByAverageScore(oldResult);
    const newSentiment = getTextByAverageScore(newResult);
    if (currentPost) {
      // handle decrease current sentiment
      switch (currentSentiment[0]) {
        case "Positive":
          const newPositive = --currentPost.positiveComments;
          dispatch({
            type: CONSTANTS.SetCurrentPost,
            payload: { post: { ...currentPost, positiveComments: newPositive } }
          });
          break;
        case "Neutral":
          const newNatural = --currentPost.neutralComments;
          dispatch({
            type: CONSTANTS.SetCurrentPost,
            payload: { post: { ...currentPost, neutralComments: newNatural } }
          });
          break;
        case "Negative":
          const newNegative = --currentPost.negativeComments;
          dispatch({
            type: CONSTANTS.SetCurrentPost,
            payload: { post: { ...currentPost, negativeComments: newNegative } }
          });
          break;
      }
  
      switch (newSentiment[0]) {
        case "Positive":
          const newPositive = ++currentPost.positiveComments;
          dispatch({
            type: CONSTANTS.SetCurrentPost,
            payload: { post: { ...currentPost, positiveComments: newPositive } }
          });
          break;
        case "Neutral":
          const newNatural = ++currentPost.neutralComments;
          dispatch({
            type: CONSTANTS.SetCurrentPost,
            payload: { post: { ...currentPost, neutralComments: newNatural } }
          });
          break;
        case "Negative":
          const newNegative = ++currentPost.negativeComments;
          dispatch({
            type: CONSTANTS.SetCurrentPost,
            payload: { post: { ...currentPost, negativeComments: newNegative } }
          });
          break;
      }
    }
  }


  const columns: Column<COMMENT>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'isActive',
        Cell: ({ value, row }) => (
          <input
            type="checkbox"
            checked={value}
            // style={{ marginTop: '7px' }}
            onChange={(e) => {
              handleCheckBoxForSingleComment(row.original.comment_id, e.target.checked);
            }}
          />
        ),
      },

      {
        Header: 'Date',
        accessor: 'created_time',
        sortType: 'basic',
      },
      {
        Header: 'Text',
        accessor: 'message',
        sortType: 'basic',
      },
      {
        Header: 'Nametag',
        accessor: 'name_tags',
        sortType: 'basic',
      },
      {
        Header: 'Products',
        accessor: 'wordBanks',
        sortable: true,
        Cell: ({ value }) => value.productsCount,
        sortType: (a: any, b: any) => {
          return a.original.wordBanks.productsCount > b.original.wordBanks.productsCount ? 1 : -1;
        },
      },
      {
        Header: 'Sentiments',
        accessor: 'result',
        sortType: 'basic',
        Cell: ({ value, row }) => {
          const [isSelected, setIsSelected] = useState(false);
          const [sentimentText, background] = getTextByAverageScore(value);

          const toggleIsSelected = () => {
            setIsSelected(!isSelected);
          };

          const handleSentimentEdit = (sentimentValue: number) => {
            updateCurrentPostSentiment(row.original.result, sentimentValue);
            dispatch({
              type: CONSTANTS.EditSentiment,
              payload: { commentId: row.original.comment_id, sentimentValue },
            });
          };
          return (
            <div
              onClick={(e) => {
                toggleIsSelected();
              }}
              style={{ position: 'relative' }}
            >
              {isSelected ? (
                <SentimentsContainer>
                  <Sentiment
                    className="right pointer"
                    background={colors.greenLight}
                    onClick={() => handleSentimentEdit(1)}
                  >
                    <span>Positive</span>

                  </Sentiment>
                  <Sentiment
                    className="right pointer"
                    background={colors.yellow}
                    onClick={() => handleSentimentEdit(0.39)}
                  >
                    <span>Neutral</span>
                  </Sentiment>
                  <Sentiment
                    className="right pointer"
                    background={colors.negative}
                    onClick={() => handleSentimentEdit(-1)}
                  >
                    <span>Negative</span>
                  </Sentiment>
                  {/* <span>{value}</span> */}
                </SentimentsContainer>
              ) : (
                <Sentiment className="right pointer" background={background}>
                  <span>{sentimentText}</span>
                  {value ? <span>{value.toFixed(1)}</span> : <></>}

                </Sentiment>
              )}
            </div>
          );
        },
      },
    ],
    [dispatch, handleCheckBoxForSingleComment]
  );





  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state,
    pageOptions,
    prepareRow,
  } = useTable<COMMENT>(
    {
      columns,
      data,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'created_time',
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );

  const { pageIndex } = state;

  return (
    <>
      <TableStyled {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span style={{ marginLeft: '10px' }}>🔽</span>
                      ) : (
                        <span style={{ marginLeft: '10px' }}>🔼</span>
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <Fragment key={i}>
                      <td {...cell.getCellProps()}>{cell.render('Cell')} </td>
                    </Fragment>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </TableStyled>
      <ButtonsContainer>
        <PrimaryButton
          style={{ marginRight: '3px', width: '80px' }}
          disabled={!canPreviousPage}
          onClick={() => {
            previousPage();
          }}
        >
          Previous
        </PrimaryButton>
        <PrimaryButton
          style={{ width: '80px' }}
          disabled={!canNextPage}
          onClick={() => {
            nextPage();
          }}
        >
          Next
        </PrimaryButton>
        <span style={{ marginLeft: '10px' }}>
          Page{` `}{' '}
          <span style={{ fontWeight: 'bold' }}>
            {pageIndex + 1} of {pageOptions.length}
          </span>
        </span>
      </ButtonsContainer>
    </>
  );
};

export default CommentsTable;