import { WORD_TAGS } from '../../../store/interface';
import { hardCodedWordsLength } from '../../../store/wordbankHardCodedWords';
import { WordTag } from '../../pages/report-preperation/word-bank/interface';
import { ACCOUNT_RESPONSE, BRAND_RESPONSE, PARSED_ACCOUNT, PARSED_ACCOUNTS, PARSED_BRAND } from './interface';

export const addIsActiveToBrands = (brandsResponse: BRAND_RESPONSE[]): PARSED_BRAND[] => {
  return brandsResponse.map((brand) => {
    return {
      ...brand,
      isActive: false,
    };
  });
};

export const addIsActiveToAccounts = (accountRespose: ACCOUNT_RESPONSE): PARSED_ACCOUNTS[] => {
  return Object.entries(accountRespose).map((accountPayload) => {
    let accounts: PARSED_ACCOUNT[] = accountPayload[1].map(account => {
      return {
        ...account,
        isActive: false,

      }
    });

    let name: string = accountPayload[0];

    let { accountName, accountId, platform, enabled } = accounts.find(acc => acc.accountName === name)
      || { accountName: "", accountId: "", platform: "", cat: 0, enabled: 0 };

    accounts = accounts.length === 1 && accounts.find(acc => acc.accountName === name) ? [] : accounts;
    accounts.sort(function (a, b) {
      if (a.accountName < b.accountName) { return -1; }
      if (a.accountName > b.accountName) { return 1; }
      return 0;
    })

    return {
      accountName, accountId, platform, enabled,
      isActive: false,
      accounts
    };




  });
};

export const getWordbankCount = (wordBank: WORD_TAGS) => {
  let sum = 0;
  Object.keys(wordBank).map((bank, i) => (sum += wordBank[bank as WordTag].length));
  return sum - hardCodedWordsLength;
};
