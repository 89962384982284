import React from 'react';
import styles from './BrandsPopup.module.css';

import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { validateManualyPageId } from "./../../../../api/facebookApi";
import { CONSTANTS } from "./../../../../store/constants";
import { AppContext } from "./../../../../store/context";
import { assets } from "./../../../ui/utils/assets";
import { Input, GreenBox, Icon, PrimaryButton } from "./../../../ui/utils/CommonStyles";
import { BRANDS_POPUP_PROPS, HANDLE_CHECK_BOX_PARAMS } from "./interface";
import {
  Brand,
  FetchedBrandsContainer,
  ErrorMessage,
} from "./Styles";

import {
  InputContainer,
  ButtonsWrapper,
} from "./../PopupGrid/Styles";
import { PostsType } from '../../sidebar/enums';






const BrandsPopup = ({
  brands,
  setBrands,
  setIsPopupOpen,
  setIsBrandsSelected,
  isBrandsSelected,
  setPostsTypeSelection

}: BRANDS_POPUP_PROPS) => {

  const [manualyPageId, setManualyPageId] = useState("");

  const [error, setError] = useState({ message: "", type: "" });

  const { dispatch } = useContext(AppContext);

  const hasOneBrandSelected = brands.filter((brand: any) => brand.isActive).length > 0;

  useEffect(() => {
    setIsBrandsSelected(hasOneBrandSelected);
    return () => { };
  }, [hasOneBrandSelected, setIsBrandsSelected]);

  const handleMouseClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleAddBrand();
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddBrand();
    }
  };

  const handleAddBrand = async () => {
    if (!manualyPageId) return;

    const { status, message, from } = await validateManualyPageId(manualyPageId);

    if (status !== "success") {
      setError({ message, type: "danger" });
      setTimeout(() => {
        setError({ message: "", type: "" });
      }, 4000);
    } else {
      const { name, id } = from;
      const isBrandExists = brands.find((brand) => brand.page_id === id);

      if (!isBrandExists)
        setBrands((prev) => {
          return [...prev, { page_id: id, brand_name: name, isActive: true, manualId: brands.length }];
        });
      else {
        setError({ message: `${name} already exists in the list`, type: "success" });
        setTimeout(() => {
          setError({ message: "", type: "" });
        }, 4000);
      }
    }

    setManualyPageId("");
  };

  const handleCheckBox = ({ page_id, checkBoxValue }: HANDLE_CHECK_BOX_PARAMS) => {
    setBrands((brands) => {
      const newBrands = [...brands];
      newBrands.forEach((brand, i) => {
        if (page_id === brand.page_id) {
          newBrands[i].isActive = checkBoxValue;
          return;
        }
      });
      return newBrands;
    });
  };

  const handleNext = async () => {
    if (!brands.length) return;
    setPostsTypeSelection(PostsType.BRANDS);
    setIsBrandsSelected(hasOneBrandSelected);
    setIsPopupOpen(false);
  };

  const handleReset = () => {
    setBrands((brands) => {
      return brands.map((brand) => {
        return {
          ...brand,
          isActive: false,
        };
      });
    });
    dispatch({ type: CONSTANTS.Reset, payload: null });
    setIsBrandsSelected(false);
    setIsPopupOpen(false);
  };
  return (
    <div>

      <InputContainer>
        <form autoComplete="on" onSubmit={handleMouseClick}>
          <Input
            value={manualyPageId}
            type="text"
            name="brandId"
            placeholder="Enter brand ID here"
            onChange={(e) => setManualyPageId(e.target.value)}
            onKeyPress={handleKeyPress}
            autoFocus={true}
          />
          <GreenBox type="submit" className="pointer">
            <Icon className="add" src={assets["add"]} />
          </GreenBox>
        </form>
      </InputContainer>
      {error.message && <ErrorMessage type={error.type}>{error.message}</ErrorMessage>}
      <FetchedBrandsContainer>
        {brands.map(({ brand_name, isActive, page_id }, index) => (
          <div key={index}>
            <input
              type="checkbox"
              checked={isActive}
              style={{ marginRight: "10px" }}
              onChange={(e) => {
                const params = {
                  page_id,
                  checkBoxValue: e.target.checked,
                };
                handleCheckBox(params);
              }}
            />
            <Brand>{brand_name}</Brand>
          </div>
        ))}

      </FetchedBrandsContainer>
      {brands.length > 0 && (
        <ButtonsWrapper>
          <Link to="/" onClick={handleReset} className="reset pointer">
            Reset
          </Link>
          {
            <PrimaryButton disabled={!isBrandsSelected} onClick={handleNext}>
              Next
            </PrimaryButton>
          }
        </ButtonsWrapper>
      )}



    </div>
  );
};



export default BrandsPopup;





