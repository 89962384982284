import styled from "styled-components";
import { colors } from "../../../../../../utils";

export const FlexDiv = styled.div`
  flex-grow: 1;
  width: 33%;
  .chart-headline {
    text-align: center;
    font-weight: 600;
    font-size: 17px;
    position: relative;
    left: 62px;
  }
  position: relative;
  right: 64px;
`;

export const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .recharts-legend-item{
    margin-right: 18px !important;

    .recharts-legend-item-text{
      color: #333B50 !important;
    }
  }
`;

export const ToolTip = styled.div`
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  padding: 10px;
  background-color: white;
  
  .title{
    color: ${colors.darkBlue};
    font-size: 12px;
  }

  .subtitle{
    font-size: 10px;
    color: #333B5099;
  }
`;
export const Value = styled.div`
  .bold {
    font-weight: bold;
  }
`;
