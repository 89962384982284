import { useContext } from 'react';
import { Redirect } from 'react-router';
import { AppContext } from '../../../../../store/context';
import { Wrapper } from './Styles';


const Publishers = () => {
  const {
    state: { report },
  } = useContext(AppContext);
  const { currentPost } = report;
  if (!currentPost) {
    return <Redirect to={`/${report.name}`} />;
  }

  //const currentTitle = tabs.filter((tab) => tab.type === currentTab)[0].title;
  return (
    <Wrapper>
        <div> 
         <h1>Publishers </h1>
         </div>
    </Wrapper>
  );
};

export default Publishers;
