import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { Redirect } from 'react-router';
import { AppContext } from '../../../../../store/context';
import { SentimentWrapper, WrapperFlex, ScoreWrapper, ScoreDiv, ScoreBrand, ScoreBrandSentiment, IconRotationWrapper, Seperator } from './Styles';
import OverAllSentimentGraph from './Graphs/OverAllSentimentGraph';
import { MentionsData, overAllSentimentData } from './Interface';
import { getTextByAverageScore } from '../../../utils/helper';
import { Icon, Sentiment } from '../../../utils/CommonStyles';
import { assets } from '../../../utils/assets';
import { POSTS_GROUP_AVERAGES } from '../../../../../store/interface';

const OverallSentiment = () => {
  const {
    state: { report },
  } = useContext(AppContext);
  const [mentions, setMentions] = useState<{ [index: string]: MentionsData[] }>({})
  const [max, setMax] = useState(0);
  const { currentPost, averageDetailsPostsToCompare ,detailsPostsToCompare, brandsToCompare, postsToCompare, currentPostsGroup } = report;
  const [avgBrands, setAvgBrands] = useState<{ [key: string]: number }>({});
  const [showCollapseBtn, setShowCollapseBtn] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [maxRowHeight, setMaxRowHeight] = useState('unset');

  const findPostsAmount = useCallback(() => {
    const newAvgBrands: { [key: string]: number } = {};
    brandsToCompare.forEach((brand) => {
      postsToCompare.forEach((post) => {
        if (post.id.split("_")[0] === brand.page_id) {
          if (!newAvgBrands[brand.brand_name]) {
            newAvgBrands[brand.brand_name] = 1;
          } else {
            newAvgBrands[brand.brand_name] += 1;
          }
        }
      });
      setAvgBrands(newAvgBrands);
    });
  }, [brandsToCompare, postsToCompare]);

  useEffect(() => {
    findPostsAmount();
  }, [findPostsAmount]);


  const scoreWrapperRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {

    const singleRowHeight = 30;
    if (scoreWrapperRef.current) {
      const rowHeight = scoreWrapperRef.current.clientHeight;
      if (singleRowHeight * 2 <= rowHeight) {
        setShowCollapseBtn(true);
        setCollapsed(true);
        setMaxRowHeight('50px');
      }
    }
  }, [scoreWrapperRef.current]);

   const handleCollapse = () => {
    if (collapsed) {
      setCollapsed(false);
      setMaxRowHeight('unset');
    } else {
      setCollapsed(true);
      setMaxRowHeight('50px')
    }
  }

  useEffect(() => {
    let newMax = 0;
    let mentionsList: { [index: string]: MentionsData[] } = {};
    overAllSentimentData.forEach((data) => {
      mentionsList[data.key] = [];
      mentionsList[data.key].push({ brandName: currentPost ? currentPost.brand.name : 'no brand name', total: currentPost ? currentPost.mentionsAndIntent[data.key] : 0 })
      newMax = currentPost && currentPost.mentionsAndIntent[data.key] > newMax ? currentPost.mentionsAndIntent[data.key] : newMax
    });
    setMentions(mentionsList)

    if (Object.keys(averageDetailsPostsToCompare).length > 0) {
      const averageDetailsPostsToCompareTemporal = averageDetailsPostsToCompare as POSTS_GROUP_AVERAGES;
      const oldMax = max;

      overAllSentimentData.forEach((data) => {
        const newBar: MentionsData = {
          total: averageDetailsPostsToCompareTemporal.mentionsAndIntent[data.key],
          brandName: `Competitive Average`,
        }
        let competitiveMax = newBar.total;
        if (competitiveMax > oldMax) {
          setMax(competitiveMax);
        }
        mentionsList[data.key].push(newBar)
      })
    }
    else if (Object.keys(detailsPostsToCompare).length > 0) {
      let compareMax = 0;
      for (const [brand, averages] of Object.entries(detailsPostsToCompare)) {
        overAllSentimentData.forEach((data) => {
          compareMax = averages.averages.mentionsAndIntent[data.key] > compareMax ? averages.averages.mentionsAndIntent[data.key] : compareMax;
          mentionsList[data.key].push({ brandName: brand, total: averages.averages.mentionsAndIntent[data.key], isAverage: averages.postsCount })
        })
      }
      if (compareMax > max) {
        setMax(compareMax);
      }
    } else {
      setMax(newMax)
    }
    setMentions(mentionsList);
  }, [detailsPostsToCompare, averageDetailsPostsToCompare, currentPost, max])

  if (!currentPost) {
    return <Redirect to={`/${report.name}`} />;
  }

  const [sentimentText, background] = getTextByAverageScore(currentPost.averageSentimentScore);

  return (
    <SentimentWrapper>

      <p className="sentiment-title"> Overall sentiment score </p>
      <div className="score-and-btn-container">
        <ScoreWrapper ref={scoreWrapperRef} maxHeight={maxRowHeight}>
          <ScoreDiv>
            <Sentiment className="left" background={background}>
            <span className="star-img-wrapper"> <img src={assets['compareStarSentimentWhite']} style={{ width: '16px' }} alt="" /> </span>
              <span>{+currentPost.averageSentimentScore.toFixed(5).slice(0, -3)}</span>
            </Sentiment>
            <p style={{marginRight:'4px'}}>{currentPost.brand.name || currentPostsGroup.groupName}</p>
          </ScoreDiv>


          {Object.keys(detailsPostsToCompare).map((key, index) => {
            return (
              <ScoreBrand key={index}>
                <ScoreBrandSentiment>
                  {detailsPostsToCompare[key].postsCount > 1 &&
                    <span className="average-img-wrapper"> <img src={assets['Avreage']} style={{ marginLeft: '2px', width: '18px' }} alt="" /> </span>
                  }
                  <span className="star-img-wrapper"> <img src={assets['compareStarSentiment']} style={{ width: '16px', margin: 'auto' }} alt="" /> </span>
                  <span style={{ margin: 'auto', fontSize: '13px' }}>{detailsPostsToCompare[key].averages.averageSentimentScore.toFixed(5).slice(0, -3)}</span>
                </ScoreBrandSentiment>
                <p className="brand-name">{key}</p>
              </ScoreBrand>
            )
          })}

        </ScoreWrapper>
        {showCollapseBtn && 
        <div className="collapse-btn">
          <span className="btn-text">{ collapsed ? 'More' : 'Less'}</span>
          <IconRotationWrapper collapse={collapsed}>
          <Icon
            shouldTransform={collapsed}
            className="pointer collapse-icon"
            src={assets['CollapseArrow']}
            onClick={(e) => {
              handleCollapse();
            }}
          />
          </IconRotationWrapper>
        </div>}
      </div>
      <Seperator/>
      <WrapperFlex>
        {Object.keys(currentPost.mentionsAndIntent).length && overAllSentimentData.map((data, index) =>
          <OverAllSentimentGraph index={index} avgBrands={avgBrands} key={index} max={max} title={data.title} mentionsData={mentions[data.key]} />
        )}
      </WrapperFlex>

    </SentimentWrapper>
  );
};

export default OverallSentiment;
