import styled, { keyframes }  from "styled-components";
import { colors } from "../../../../utils";

export const TitleWrapper = styled.div`
    margin: 22px 30px;
    display: flex;
    .title {
        font-weight: bold;
        font-size: 25px;
        margin-left: 25px;
        position: relative;
        top: 3px;
    }

    .back-btn{
      display: flex;
    }
`

export const SearchWrapper = styled.div`
  margin-left: auto;
  input{
    width: 353px;
    padding: 6px;
    border-radius: 5px;
    border: 1px solid ${colors.greyLight};
    background: ${colors.layoutBackground};
  }
`
export const IconSearch = styled.img`
    position: relative;
    top: 1px;
    right: 28px;
    cursor: pointer;
`
export const IconBack = styled.img`
    width: 25px;
    cursor: pointer;
    margin: auto;
`
export const Wrapper = styled.div`
    display: flex;
    margin: 10px 27px;
`

export const TotalAlerts = styled.div`
    background: ${colors.white};
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    height: auto;
    width: 17%;
    margin-left: 22px;
`
export const TotalAlertsDetails = styled.div`
  padding: 10px;
`

export const SingleTotalAlertDetails = styled.div`
  margin-bottom: 8px;
  width: 100%;
  display: flex;
`
export const SingleTotalAlertName = styled.span`
  font-size: 13px;
  position: relative;
  margin-left: 15px;
  top: 2px;
`
export const SingleTotalAlertCount = styled.span`
    margin-left: auto;
    font-size: 16px;
`
export const TitleTotal = styled.div`
    display: flex;
    font-size:16px;
    border-bottom: 1px solid #DBDBDB;
    padding: 10px 10px;
    width: 100%;
    justify-content: space-around;
`
export const TitleText = styled.div`
    font-weight: bold;
    position: relative;
    top: -1px;
    width: 60%;
    margin-left: 11px;
`
export const TitleCount = styled.div`
  margin-left: auto;
`

export const SingleTotalIconAlert = styled.img`
    width: 14px;
    height: 18px;
    position: relative;
    top: 2px;
`


export const IconAlert = styled.img`
    width: 14px;
    height: 18px;
    position: relative;
    top: 2px;
`


export const AlertDetailsWrapper = styled.div`
    width: 100%;
`

export const PostDetailsWrapper = styled.div`
    background: ${colors.white};
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    border-radius: 6px;
    height: 120px;
    width: 100%;
`
export const SmallDetails = styled.div`
    display: flex;
    padding: 15px 15px;
    font-size: 13px;
`
export const InnerSmallDetails = styled.div<{ date?:boolean, icon?:boolean, postId?:boolean }>`
  color: ${({date}) => date && `#313A48;`};
  margin-right: ${({date}) => date && '30px'};
  margin-right: ${({icon}) => icon && '30px'};
  margin-left: ${({postId}) => postId && '20px'}
`
export const InnerTypeIcon = styled.img`
`
export const MainDetails = styled.div`
    display: flex;
    width: 100%;
    font-size: 13px;
`

export const PostImg = styled.img`
    width: 50px;
    height: 50px;
    margin-left: 10px;
`

export const PostContent = styled.div`
     margin-left: 25px;
     margin-top: 5px;
     width: 42%;
`
export const ViewSource = styled.a`
    color: ${colors.blue};
    position: relative;
    top: 30px;
    margin-left: auto;
    right: 10px;
`

export const FreqWordWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    padding: 15px 20px 5px 15px;
    background: ${colors.white};
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
`

export const FreqWord = styled.div<{ category: number }>`
  display: flex;
  width: fit-content;
  margin-top: 10px;
  border-radius: 14px;
  box-shadow: 0 2 14 0;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  height: 35px;
  font-size: 12px;
  text-align: center;
  margin: 0px 12px 10px 7px;
  padding: 5px 10px;
  border: ${({ category }) =>
    category === 3 ? `1px solid ${colors.dangerousCategory}` : ""};
  border: ${({ category }) =>
    category === 2 ? `1px solid ${colors.offensiveCategory}` : ""};
  border: ${({ category }) =>
    category === 1 ? `1px solid ${colors.otherCategory}` : ""};
  border: ${({ category }) =>
    category === 0 ? `1px solid ${colors.supportCategory}` : ""};
`;


export const TableStyled = styled.table`
  border-collapse: separate;
  text-align: left;
  margin-bottom: auto;
  border-spacing: 0 8px;
  width: 100%;
  margin-top: 10px;
  padding: 15px 25px 0px 25px;
  thead{
    background: ${colors.layoutBackground};
    tr{
      color:  ${colors.darkGreyOpacity};
      background: ${colors.layoutBackground};
      &:hover {
      background: none;
      }
    }
    th:nth-child(2){
      position: relative;
      cursor: pointer;
      left: -30px;
    }
    th:last-child{
      text-align: right;
      padding-right: 8px;
      position: relative;
      top: -2px;
    }
    
  }
  tr:first-child {
    position: relative;
    top: 0px;
  }
  tr {
    position: relative;
    background-color: ${colors.white};
  }
  td {
    background-color: "rgba(52, 115, 226, 0.1)";
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-style: solid none;
    position: relative;
    height: 40px;
    padding: 0 25px 0 0;
  }

  td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 12px;
    padding-right: 2px;
    width: 7%;
  }

  td:nth-child(2) {
    width: 10%;
  }
  td:nth-child(3){
    width: 55%;
    img {
    margin-left: 15px;
    display: block;
    }
  } 

  td:nth-child(4) {
    width: 5%;
    text-align:center;
  }

  td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    width: 8%;
    img {
      position: relative;
      left: 37px;
      float: right;
      margin-right: 25%;
    }
  }

  .trash {
    cursor: pointer;
  }

  tr {
    font-size: 0.8125rem;
    text-align: left;
    height: 40px;
    margin-bottom: 8px;
    &:hover {
      background-color: #fff0d3;
    }
  }

  th {
    line-height: 1.0625rem;
    color: "rgba(51, 59, 80, 0.6)";
    font-weight: 300;
    text-align: left;
    height: 40px;
    &:hover {
      background-color: none;
    }
  }

  th:first-child {
    border-bottom: 0;
  }
`;


export const Spreader = styled.div`
  height: 250px;
`
const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

export const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;

  &:checked + div {
    background: #3d85f7 !important;
  }
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 4px 18px 9px 7px;
  color: #ffff;
  padding-left: 0px;
`;

export const Indicator = styled.div`
  width: 16px;
  height: 16px;
  background: ${colors.white};
  position: absolute;
  top: 6px;
  left: -13px;
  border: 2px solid #3d85f7;
  border-radius: 0.2em;

  .head-indicator {
    left: 0.5;
  }

  ${Input}:not(:disabled):checked & {
    background: #3d85f7;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + div {
    background: #3d85f7 !important;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 0em;
    left: 0.3em;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    /* animation-duration: 0.3s; */
    animation-fill-mode: forwards;
  }

  &::disabled {
    cursor: not-allowed;
  }
`;



export const InputPopUp = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;

  &:checked + div {
    background: #3d85f7 !important;
  }
`;

export const LabelPopUp = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 4px 18px 9px 7px;
  color: #ffff;
  padding-left: 0px;
`;

export const IndicatorPopUp = styled.div`
  width: 16px;
  height: 16px;
  background: ${colors.darkBlue};
  position: absolute;
  top: 1px;
  left: -13px;
  border: 2px solid #3d85f7;
  border-radius: 0.2em;

  .head-indicator {
    left: 0.5;
  }

  ${InputPopUp}:not(:disabled):checked & {
    background: #3d85f7;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${InputPopUp}:checked + div {
    background: ${colors.white} !important;
  }

  ${InputPopUp}:checked + &::after {
    display: block;
    top: 0em;
    left: 0.3em;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    /* animation-duration: 0.3s; */
    animation-fill-mode: forwards;
  }

  &::disabled {
    cursor: not-allowed;
  }
`;

export const CommentsActions = styled.div`
    position: relative;
    width: 100%;
    top: 17px;
    left: -23px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    color: ${colors.grey};
    float: right;
    p {
      font-size: 13px;
    }
`

export const IconImg = styled.img`
    margin-left: 12px;
    margin-right: 5px;
`

export const IconEye = styled.img`
    height: 20px;
    margin-left: 7px;
    margin-right: 9px;
`

export const Button = styled.div`
    display: inline-flex;
    cursor: pointer;
`
export const IconEdit = styled.img`
    position: relative;
    top: 1.2px;
    height: 16px;
    margin-left: 7px;
    margin-right: 9px;
`

export const Icon = styled.img`
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 0px;
`

export const PopUpWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 95%;
  .yes-hide {
    height: 105px;
  }
`
export const PopUpBox = styled.div`
  background: ${colors.darkBlue};
  margin:0 auto;
  margin-bottom: 15px;
  height: 50px;
  width: 426px;
  border-radius: 9px;
  .yes-popup-inner {
    display: block;
  }
`

export const PopUpInner = styled.div`
  font-size: 13px;
  color: ${colors.white};
  display: inline-flex;
  justify-content: space-between;
  margin: 15px 20px;
  width: 90%;
  .yes-hide-wrapper {
    float: right;
    margin-right: -4px;
  }
`

export const Actions = styled.div`
  display: flex;
`

export const Cancel = styled.div`
  cursor: pointer;
`

export const YesVerify = styled.div`
  color: ${colors.verifyAlertGreen};
  border-left: 1px solid ${colors.greyLight};
  margin-left: 15px;
  cursor: pointer;
  .icon-img {
     margin-left: 11px;
     margin-right: 5px;
     margin-top: -2px;
  }
`

export const YesHide = styled.div`
  color: ${colors.yesAlertHide};
  border-left: 1px solid ${colors.greyLight};
  margin-left: 15px;
  cursor: pointer;
  font-weight: bold;
  font-size:14px;
  .icon-img {
     margin-left: 11px;
     margin-right: 5px;
     margin-top: -2px;
  }
`

export const HideActionWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 110%;
  position: relative;
  left: -18px;
  padding: 5px 15px 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${colors.greyLight};
`

export const SingleComment = styled.div`
  padding: 10px;
  line-height: 30px;
`