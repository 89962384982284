import styled, { keyframes } from "styled-components";
import { colors } from "../../../../../utils";

const Button = styled.div`
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  width: fit-content;
  padding: 8px 10px;
  font-size: 13px;
  color: #ffffff;
  margin: 11px 0px 13px 12px;
  cursor: pointer;
  height: 35px;
  
  p {
    font-size: 17px;
    color: #ffffff;
    width: 155px;
    font-size: 13px;
    margin: auto;
    width: fit-content;
  }
  img {
    margin-right: 14px;
  }
`;

const ButtonClear = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  width: fit-content;
  padding: 8px 10px;
  font-size: 13px;
  color: #ffffff;
  margin: 11px 0px 13px 12px;
  cursor: pointer;
  p {
    font-size: 13px;
    color: #ffffff;
  }
  img {
    margin-right: 14px;
  }
`;

const ProcessButton = styled.div`
  background-color: ${colors.blue};
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  width: fit-content;
  min-width: 20px;
  padding: 7px 25px;
  font-size: 13px;
  color: #ffffff;
  margin: 11px 0px 13px 12px;
  cursor: pointer;
  height: 40px;
  p {
    font-size: 16px;
    color: #ffffff;
  }
  img {
    margin-right: 14px;
  }
`;

const CancelButton = styled.div`
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  width: fit-content;
  padding: 8px 10px;
  font-size: 13px;
  color: #ffffff;
  margin: 11px 0px 13px 12px;
  cursor: pointer;
  p {
    font-size: 16px;
    color: #ffffff;
  }
  img {
    margin-right: 14px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 90%;
  height: 60px;
  background: #2b304a;
  border: 1px solid #2b304a;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-top: 33px;
`;

const CompareContainer = styled.div`
  width: 90%;
  height: fit-content;
  position: absolute;
  z-index: 1;
  background: #2b304a;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  left:0;
`;

const CompareFooter = styled.div`
  width: 100%;
  margin: 0;
  border-top: 1px solid #ffffffa9;
  position: relative;
  background: #2b304a;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const CompareFooterContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-right: 10px;
`;

const TableStyled = styled.table`
  border-collapse: separate;
  text-align: left;
  margin-bottom: auto;
  margin-left: 30px;
  margin-top: -3px;
  border-spacing: 0 8px;
  width: 95%;
  overflow-y: scroll;
  height: 300px;
  position: relative;

  td {
    color: #ffff;
    background-color: "rgba(52, 115, 226, 0.1)";
    border: 1px solid white;
    border-style: solid none;
    position: relative;
    height: 40px;
    padding: 0 25px 0 0;
  }

  td:first-child {
    border-left-style: solid;
    border-right: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 12px;
    width: 10px;
  }

  td:nth-child(2) img {
    margin-left: 15px;
    display: block;
  }

  td:nth-child(2) {
    width: 10px;
  }

  td:nth-child(3) {
    width: fit-content;
  }

  td:nth-child(4) {
    width: 65%;
  }

  td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .trash {
    cursor: pointer;
  }

  tr {
    border: 2px solid ${colors.white};
  }
  tr {
    font-size: 0.8125rem;
    text-align: left;
    height: 40px;
    margin-bottom: 8px;
    &:hover {
      td {
        border: 2px solid #3d85f7;
        border-radius: 6px;
      }
      td:first-child {
        border-right: none;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      td:nth-child(2) {
        border-right: none;
        border-left: none;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      td:nth-child(3) {
        border-right: none;
        border-left: none;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      td:nth-child(4) {
        border-left: none;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
  }

  th {
    line-height: 1.0625rem;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 300;
    text-align: left;
    height: 40px;
    &:hover {
      background-color: none;
    }
  }

  th:first-child {
    border-bottom: 0;
  }
`;

const TableOverflow = styled.div`
  overflow-y: scroll;
  height: fit-content;
  max-height: 300px;
  min-height: 200px;
  position: relative;
  top: 0px;
  padding: 0 7px 60px 0;
  width: 99%;

  &::-webkit-scrollbar {
    background-color: transparent;
    padding-right: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9597A4;
    border-radius: 6px;
}
`;

const PlusInput = styled.button`
  border: 0;
  background-color: inherit;
  display: flex;
`;

export const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid rgb(77, 86, 111, 0.2);
  position: relative;
  width: 65%;
  overflow: scroll;
  margin: auto;
  padding-left: 80px;
   
  &::-webkit-scrollbar {
    display: none;
  }
`;

interface TAB_PROPS {
  isSelected: boolean;
}

export const Tab = styled.div<TAB_PROPS>`
  font-weight: 500;
  font-size: 0.875rem;
  color: ${({ isSelected }) => (isSelected ? colors.white : colors.titleDescription)};
  border-bottom: ${({ isSelected }) => isSelected && `2px solid ${colors.tabTitle}`};
  white-space: nowrap;
  height: 54px;
  margin-right: 30px;
  display: flex;

  > span{
    margin: auto;
  }
`;

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;

  &:checked + div {
    background: #3d85f7 !important;
  }
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0.6em 1em;
  color: #ffff;
  padding-left: 20px;
  width: 100%;

  &.post-comapre{
    width:50%;
  }
`;

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

const Indicator = styled.div<{ isComparePage?: boolean }>`
  width: 1.2em;
  height: 1.2em;
  background: #2b304a;
  position: absolute;
  top: 0.2em;
  left: -0.9em;
  border: 2px solid #3d85f7;
  border-radius: 0.2em;

  .head-indicator {
    left: 0.5;
  }

  ${Input}:not(:disabled):checked & {
    background: #3d85f7;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + div {
    background: #3d85f7 !important;
  }

  ${Input}:checked + &::after {
    display: block;
    top: ${({ isComparePage }) => (isComparePage ? "-2px" : "0em")};
    left: 0.3em;
    width: 3px;
    height: 11px;
    border: solid black;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    /* animation-duration: 0.3s; */
    animation-fill-mode: forwards;
  }

  &::disabled {
    cursor: not-allowed;
  }
`;

const WrapperTab = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  height: 54px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  form{
    display: flex;
  }
`;

const CompareDateContainer = styled.div<{ disabled?: boolean; selected?: boolean }>`
  display: flex;
  align-items: center;
  width: 239px;
  height: 40px;
  position: absolute;
  right: 32px;
  top: -4px;
  z-index: 1;
  height: 40px;
  margin-top: 5px;
  margin-bottom: -3px;
  border: 1px solid ${colors.greyLight};
  border-radius: 5px;
  padding: 0 14.42px 0 21.5px;
  background-color: ${({ selected }) => selected && colors.lightBlue};
  opacity: ${({ disabled }) => disabled && 0.2};
  pointer-events: ${({ disabled }) => disabled && "none"};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  & ${Input} {
    height: 100%;

    &.sidebar {
      margin-left: 11.5px;
      font-size: 0.8125rem;
      line-height: 1.5rem;
      color: ${colors.darkBlue};
    }
  }

  & span {
    margin-left: 11.5px;
    font-size: 0.8125rem;
    line-height: 1.5rem;
    color: ${colors.darkBlue};
  }

  & .arrow-right {
    margin-left: auto;
    cursor: pointer;
  }

  .center {
    margin: 0 auto;
  }

  .date-input {
    background-color: inherit;
    width: 170px;
    margin: 0 5px;
    border: none;
    color: ${colors.white};
  }

  .loader {
    background: ${colors.layoutBackground};
    width: 30px;
    margin: auto;
  }
`;

export const BrandsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 220px;
  padding: 0 20px;
  margin: 30px 0 60px 0;
  width: fit-content;
`;

export const CompareText = styled.div`
  padding: 21px 25px;
  font-size: 12px;
  color: #9597A4;
`;

export const SwitcherWrapper = styled.div`
  display: flex;
  margin-left: auto;
  position: relative;
  right: 23px;
  p {
    color: ${colors.white};
    padding: 20px 20px;
    font-size: 13px;
  }
  .switcher-button {
    transform: scale(1.1);
    display: flex;
    align-items: center;
    input {
      display: none;
    }
    label {
      display: block;
      height: 24px;
      width: 43px;
      border-radius: 50px;
      background: ${({ theme }) => (theme === "off" ? colors.darkBlue : colors.white)};
      border: 1px solid ${colors.white};
      cursor: pointer;
      transition: 0.4s;

      &:before {
        transition: 0.4s;
        content: "";
        position: absolute;
        top: 21px;
        left: ${({ theme }) => (theme === "off" ? "3px" : "21px")};
        height: 18px;
        width: 18px;
        background: ${({ theme }) => (theme === "off" ? colors.white : colors.darkBlue)};
        border-radius: 50%;
        border-radius: 16px;
        z-index: 1;
        animation: off 0.5s ease-out;
        transform-origin: 0 0;
      }
    }

    input:checked + label {
      background: ${colors.white};

      &:before {
        transform: translateX(26px);
        animation: on 0.5s ease-out;
        transform-origin: 100% 0;
        background: ${colors.darkBlue};
      }
    }
  }
`;

const CompareLoader = styled.img`
  position: fixed;
  width: 110px;
  top: 50%;
  left: 50%;
  margin-top: -110px;
  margin-left: -110px;
  z-index: 2;
`;

export const addCompare = {
  Wrapper,
  Button,
  ButtonClear,
  CancelButton,
  ProcessButton,
  CompareContainer,
  CompareFooter,
  CompareFooterContainer,
  TableStyled,
  TableOverflow,
  Input,
  rotate,
  Indicator,
  Label,
  BrandsContainer,
  PlusInput,
  CompareDateContainer,
  WrapperTab,
  CompareText,
  SwitcherWrapper,
  CompareLoader,
};
