import { COMMENT, WordsObject } from '../interface';

export const removeCommentsFromWordObjectAndBuildFilterArray = (
  wordFrequencies: WordsObject
): { newWordFrequencies: WordsObject; allCommentIdsToRemove: string[] } => {
  let newWordFrequencies = { ...wordFrequencies };
  let allCommentIdsToRemove = [] as string[];

  // loop through wordFrequencies to retrieve active comments of words.
  for (const [key] of Object.entries(wordFrequencies)) {
    // get active comments
    const currentCommentIdsToRemove = wordFrequencies[key].comments
      .filter(({ isActive }) => isActive)
      .map(({ id }) => id);
    allCommentIdsToRemove.push(...currentCommentIdsToRemove);
  }

  // loop throguh wordFrequencies to filter(delete) active comments
  if (allCommentIdsToRemove.length) {
    for (const [key] of Object.entries(wordFrequencies)) {
      newWordFrequencies[key].comments = newWordFrequencies[key].comments.filter(
        (comment) => !allCommentIdsToRemove.find((commentId) => commentId === comment.id)
      );
      const shouldDeleteWord =
        newWordFrequencies[key].isActive || newWordFrequencies[key].comments.length === 0;
      if (shouldDeleteWord) {
        delete newWordFrequencies[key];
      } else {
        newWordFrequencies[key].count = newWordFrequencies[key].comments.length;
      }
    }
  }

  return { newWordFrequencies, allCommentIdsToRemove };
};

export const getFilteredCommentsForStore = (
  currentPostCommentsMock: COMMENT[],
  allCommentsToRemove: string[]
): COMMENT[] => {
  return currentPostCommentsMock.filter(
    ({ id }) => !allCommentsToRemove.find((commentId) => commentId === id)
  );
};

export const calcSentimentsScore = (filteredComments: COMMENT[]) => {
  const sentimentsSum = filteredComments.reduce((total, comment) => {
    return total + comment.result;
  }, 0);

  const analayzedCommentsLength = filteredComments.filter(({ result }) => result !== 0).length;

  return sentimentsSum / analayzedCommentsLength;
};
