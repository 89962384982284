import { POST_ALERTS, POST_ALERT_WORD } from './../interface';
import { CONSTANTS } from "../constants";
import {
    POST_ACTIONS,
    AUTH_ACTIONS,
  ALERT_STATE_TYPE,
  ALERT_ACTIONS
} from "../interface";



export const alertReducer = (state: ALERT_STATE_TYPE, action: POST_ACTIONS | AUTH_ACTIONS | ALERT_ACTIONS) => {
  const { payload, type } = action;

  switch (type) {
    

    case CONSTANTS.AddAlertComment: {
      const { alertComment_id, isActive } = payload as {
        alertComment_id:string,
        isActive:boolean
      };
      let newAlertComments;
      if (isActive) {
        newAlertComments = [...state.alertCommentsCollection, { alertComment_id, isActive }];
      } else {
        newAlertComments = state.alertCommentsCollection.filter((comment)=> {
          return comment.alertComment_id !== alertComment_id;
        });
      }

      return {
        ...state,
        alertCommentsCollection: newAlertComments,
      };
    }

    case CONSTANTS.ResetAllCommentCollection: {
      return {
        ...state,
        alertCommentsCollection: [],
        alertEditPostCommentsCollection:[],
        alertPostCommentsCollection:[]
      };
    }

    case CONSTANTS.AddPostAlertComment: {
      const { alertComment_id, isActive } = payload as {
        alertComment_id:string,
        isActive:boolean
      };
      let newAlertPostComments;
      if (isActive) {
        newAlertPostComments = [...state.alertPostCommentsCollection, { alertComment_id, isActive }];
      } else {
        newAlertPostComments = state.alertPostCommentsCollection.filter((comment)=> {
          return comment.alertComment_id !== alertComment_id;
        });
      }

      return {
        ...state,
        alertPostCommentsCollection: newAlertPostComments,
      };
    }

    case CONSTANTS.AddEditPostAlertComment: {
      const { alertComment_id, isActive } = payload as {
        alertComment_id:string,
        isActive:boolean
      };
      let newAlertEditPostComments;
      if (isActive) {
        newAlertEditPostComments = [...state.alertEditPostCommentsCollection, { alertComment_id, isActive }];
      } else {
        newAlertEditPostComments = state.alertEditPostCommentsCollection.filter((comment)=> {
          return comment.alertComment_id !== alertComment_id;
        });
      }

      return {
        ...state,
        alertEditPostCommentsCollection: newAlertEditPostComments,
      };
    }

    case CONSTANTS.SetEditPostAlert: {
      const { post_id, post_details  } = payload as {
          post_id:string,
          post_details:POST_ALERTS
      }
      return {
        ...state,
        editPostAlert:{ post_id, post_details }
      };
    }

    case CONSTANTS.ResetEditPostAlert: {
      return {
        ...state,
        editPostAlert:{
          post_id:'',
          post_details:{}
        }
      };
    }

    case CONSTANTS.SetDatePickAlerts: {
        const { datePick } = payload as {
          datePick:string
        }
        return {
          ...state,
          datePick:datePick
        }
    }

    case CONSTANTS.SetWordBankAlerts: {
      const { wordBank } = payload as {
        wordBank: POST_ALERT_WORD[]
      }
      return {
        ...state,
        wordBank:wordBank
      }
    }

    default:
      return state;
  }
};
