import { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
// import SearchBox from "../searchbox/SearchBox"; //keep incase u want searchbox in the future
import { assets } from "../utils/assets";
import { Icon, PrimaryButton } from "../utils/CommonStyles";
import { Container, LeftGroup, RightGroup, GroupsContainer, Description, GroupButtonsContainer, TrashButton, ModalOverlay } from "./Styles";
import { ModalContainer, InputContainer, Input, ModalButtonsContainer, ModalText } from '../modal/Styles';
import { AppContext } from "../../../store/context";
import { Link, useHistory } from "react-router-dom";
import ReportInput from "./ReportNameInput";
import { CONSTANTS } from "../../../store/constants";
import { Modal } from "../modal/Modal";
import { calcGroupAverages } from "../post/Compare/utils/helper";
import { parsePostByGroup } from "../post/utils/helper";


interface BRAND_HEADER_PROPS {
  isTargetPostRouter: boolean;
}

const ReportHeaderSection = ({ isTargetPostRouter }: BRAND_HEADER_PROPS) => {
  const {
    state: { report }, dispatch
  } = useContext(AppContext);
  const history = useHistory();


  const { currentPostsGroup } = report;

  const getPath = useCallback(() => {
    const pathArray = history.location.pathname.split("/");
    const path = pathArray[pathArray.length - 1];
    return path;
  }, [history.location.pathname]);

  const [currentPath, setCurrentPath] = useState(getPath());
  const [modalOpen, setModalOpen] = useState(false);
  const [hasGroupName, setHasGroupName] = useState(false);
  const [inputText, setInputText] = useState('');
  const handleCreateReport = () => {
    history.push(`/${report.name}/view-report`);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleCreateGroup = () => {
    const currentGroupName = inputText;
    // set group name in context
    dispatch({ type: CONSTANTS.SetGroupExpention, payload: { groupExpended: false } });
    dispatch({
      type: CONSTANTS.SetCurrentPostsGroupName,
      payload: { currentGroupName }
    })

    // set averages object
    const groupAverages = calcGroupAverages(currentPostsGroup.posts);
    dispatch({
      type: CONSTANTS.SetCurrentPostGroupAverages,
      payload: { currentPostGroupAverages: groupAverages }
    })

    handleCloseModal();

    // redirect to group compare page
    const postToShow = parsePostByGroup(groupAverages);
    dispatch({ type: CONSTANTS.SetCurrentPost, payload: { post: postToShow } });
    history.push(`posts-group/${currentGroupName}/reactions`);
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHasGroupName(e.target.value.length > 0);
    setInputText(e.target.value);
  }

  const handleClearSelected = () => {
    document.querySelectorAll('.post-checkbox, .select-all-checkbox').forEach((checkbox) => {
      (checkbox as HTMLInputElement).checked = false;
    })

    currentPostsGroup.posts.forEach((post) => {
      dispatch({
        type: CONSTANTS.SetCurrentPostsGroup,
        payload: { post, value: false }
      })
    })

    dispatch({
      type: CONSTANTS.SetAllPostsChecked,
      payload: { allChecked: false }
    })
  }

  useEffect(() => {
    setCurrentPath(getPath());
  }, [getPath]);

  const cleanCompareItems = () => {
    dispatch({
      type: CONSTANTS.ResetBrandsAndPostsToCompare,
      payload: { reset: true },
    });
    dispatch({
      type: CONSTANTS.SetAfterCompare,
      payload: {afterCompare:false}
    });
  }

  const { name: reportName } = report;
  return (
    <Container isTargetPostRouter={isTargetPostRouter}>
      <GroupsContainer>
        <LeftGroup>
          <ReportInput />
          <div>
            <Link onClick={cleanCompareItems} to={`/${reportName}${currentPath === "posts-display" ? "" : "/posts-display"}`}>
              <Icon src={assets["arrowLeft"]} alt="" />
            </Link>
            <Description>
              {currentPath === "posts-display" && `${`Showing ${report.postsData.length} posts`}`}
              {currentPath === "comments" && currentPostsGroup.groupName === '' &&
                `${`Showing ${report.currentPost?.comments.length} fetched comments`}`}
              {currentPostsGroup.groupName !== '' &&
                `${`Showing 1 group with ${currentPostsGroup.posts.length} posts`}`}
            </Description>
          </div>
        </LeftGroup>
        {(isTargetPostRouter || currentPath === 'posts-display') && (
          <RightGroup>
            {/* <SearchBox /> */}
            {currentPath !== 'posts-display' ?
              <PrimaryButton onClick={handleCreateReport} className="create-report">
                <Icon className="report-icon" src={assets["report"]} alt="" />
                <span>Create report</span>
              </PrimaryButton> :
              currentPostsGroup.posts?.length > 1 ? (<GroupButtonsContainer className="group-btns-container">
                <TrashButton className="clear-selections" onClick={handleClearSelected}>
                  <Icon className="trash-icon" src={assets["trashBlack"]} alt="" />
                  <span className="btn-text">Clear</span>
                </TrashButton>
                <PrimaryButton onClick={handleOpenModal} className="create-group-modal">
                  <span>Select and name group</span>
                </PrimaryButton>
              </GroupButtonsContainer>) : ''
            }
          </RightGroup>
        )}
      </GroupsContainer>
      <Modal open={modalOpen}>
        <ModalContainer className="modal-container">
          <InputContainer className="modal-input-container">
            <Input type="text" className="group-name-input" placeholder='Untitled group' onChange={handleInputChange} />
            <ModalText className="modal-text">Enter a title to your group</ModalText>
          </InputContainer>
          <ModalButtonsContainer className="buttons-container">
            <TrashButton className="cancle-btn" onClick={handleCloseModal} >Cancel</TrashButton>
            <PrimaryButton className="next-btn" onClick={handleCreateGroup} disabled={!hasGroupName}>Next</PrimaryButton>
          </ModalButtonsContainer>
        </ModalContainer>
        <ModalOverlay />
      </Modal>
    </Container>
  );
};

export default ReportHeaderSection;
