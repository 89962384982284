import { ChangeEvent, useContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { CONSTANTS } from '../../../../store/constants';
import { AppContext } from '../../../../store/context';
import { COMMENT } from '../../../../store/interface';
import CommentsTable from '../../table/comments/CommentsTable';
import { assets } from '../../utils/assets';
import { Wrapper, SearchWrapper, IconSearch } from './Styles';


const Comments = () => {
  const {
    state: { report },
    dispatch
  } = useContext(AppContext);
  const [comments, setComments] = useState<COMMENT[]>([])
  const [originalComments, setOriginalComments] = useState<COMMENT[]>([])
  const [searchField, setSearchField] = useState("");
  const { currentPost } = report;
  useEffect(()=>{
    if (currentPost) {
      setComments(currentPost.comments)
      setOriginalComments(currentPost.comments)
    }
  },[currentPost])
  
  if (!currentPost) {
    return <Redirect to={`/${report.name}`} />;
  }

  

  const handleCheckBoxForSingleComment = (commentId: string, checkBoxValue: boolean) => {
    dispatch({
      type: CONSTANTS.SetSingleCommentIsActive,
      payload: { commentId, isActive: checkBoxValue },
    });
  };

  const setActiveStatusForAllComments = (checkBoxValue: boolean) => {
    dispatch({ type: CONSTANTS.SetAllCommentsIsActive, payload: { isActive: checkBoxValue } });
  };
  const handleSearchChange = (e:ChangeEvent<HTMLInputElement>) => {
    setSearchField(e.target.value);
};

  const callSearch = () =>{
    if ( searchField.length > 0 ) {
      const SearchComments = comments?.reduce((prevComments:COMMENT[],comment)=>{
        if ( comment.message.includes(searchField) ) {
          prevComments.push(comment)
        }
        return prevComments
      },[])
      setComments(SearchComments)
     
      if ( SearchComments?.length === 0) {
        const originalSearchComments = originalComments?.reduce((prevComments:COMMENT[],comment)=>{
          if ( comment.message.includes(searchField) ) {
            prevComments.push(comment)
          }
          return prevComments
        },[])
        setComments(originalSearchComments)
      }
    } else {
      setComments(originalComments)
    }
  }
  const resetSearch = () => {
    setSearchField('')
    setComments(originalComments)
  }
  
  return (
    <Wrapper>
      <SearchWrapper>
              <input 
              type="text" 
              placeholder = "Search comments" 
              onChange = {handleSearchChange}
              value={searchField}
              name="" 
              id="" />
              {searchField && (
                <IconSearch onClick={resetSearch} style={{marginLeft:'-8px',right:'34px'}} src={assets['close']} />
              )}
              <IconSearch onClick={callSearch} src={assets['searchIcon']}/>
        </SearchWrapper>
      <CommentsTable data={comments}
        handleCheckBoxForSingleComment={handleCheckBoxForSingleComment}
        setActiveStatusForAllComments={setActiveStatusForAllComments}
      />

    </Wrapper>
  );
};

export default Comments;
