import { Fragment, useMemo, useState, forwardRef, useRef, useEffect } from 'react';
import { useTable, Column, useSortBy, usePagination, useRowSelect } from 'react-table';
import { PARSED_WORD_FREQUENCY } from '../../post/word-frequencies/interface';
import CommentsPerWordTable from './CommentsPerWordTable';
import { TableStyled } from './Styles';
import { TABLE_PROPS, CheckboxProps } from './interface';
import { Icon, PrimaryButton } from '../../utils/CommonStyles';
import { ButtonsContainer } from '../CommonStyles';
import { assets } from '../../utils/assets';

const FrequenciesTable = ({ data, handleCheckBoxForSingleWord, setActiveStatusForAllWords }: TABLE_PROPS) => {
  const [currentWord, setCurrentWord] = useState('');

  const columns: Column<PARSED_WORD_FREQUENCY>[] = useMemo(
    () => [
      {
        id: 'word',
        Header: <span>Word</span>,
        sortType: (a: any, b: any) => {
          return a.original.word > b.original.word ? -1 : 1;
        },
        Cell: ({ row }: { row: any }) => {
          return (
            <div>
              <div
                className="ellipsis word"
                style={{ marginTop: row.original.word === currentWord ? '8px' : '0' }}
              >
                <Icon
                  shouldTransform={row.original.word === currentWord}
                  className="pointer dropdown"
                  src={assets['dropDown']}
                  onClick={(e) => {
                    setCurrentWord(row.original.word === currentWord ? '' : row.original.word);
                  }}
                />
                {`${row.original.word}`}
              </div>
              <div>
                {row.original.comments.length > 0 && row.original.word === currentWord && (
                  <CommentsPerWordTable
                    data={row.original.comments}
                    currentWord={row.original.word}
                    setCurrentWord={setCurrentWord}
                  />
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Frequency',
        accessor: 'count',
        Cell: ({ value }: { value: any }) => (
          <span style={{ top: '10px', position: 'absolute' }}>{value}</span>
        ),
        sortType: 'basic',
      },
    ],
    [currentWord]
  );

  const useCombinedRefs = (...refs: any): React.MutableRefObject<any> => {
    const targetRef = useRef();

    useEffect(() => {
      refs.forEach((ref: any) => {
        if (!ref) return;

        if (typeof ref === 'function') {
          ref(targetRef.current);
        } else {
          ref.current = targetRef.current;
        }
      });
    }, [refs]);

    return targetRef;
  };

  const IndeterminateCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(
    (
      { indeterminate, isCell, value, word, currentRows, isAllRowsSelected, ...rest },
      ref: React.Ref<HTMLInputElement>
    ) => {
      const defaultRef = useRef(null);
      const combinedRef = useCombinedRefs(ref, defaultRef);
      useEffect(() => {
        if (combinedRef?.current) {
          combinedRef.current.indeterminate = indeterminate ?? false;
        }
      }, [combinedRef, indeterminate]);
      return (
        <>
          {isCell ? (
            <input
              type="checkbox"
              ref={combinedRef}
              {...rest}
              checked={value}
              onChange={(e) => {
                handleCheckBoxForSingleWord(word!, e.target.checked!);
              }}
            />
          ) : (
            <input
              type="checkbox"
              ref={combinedRef}
              {...rest}
              onChange={(e) => setActiveStatusForAllWords(e.target.checked, currentRows!)}
              checked={isAllRowsSelected}
            />
          )}
        </>
      );
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state,
    pageOptions,
  } = useTable<PARSED_WORD_FREQUENCY>(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {
        sortBy: [
          {
            id: 'count',
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'isActive',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox

          Header: ({ getToggleAllPageRowsSelectedProps, page }) => {
            const currentRows = page.map((row: any) => row.original);
            const isAllRowsSelected =
              currentRows.filter(({ isActive }) => isActive).length === currentRows.length;
            return (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                  isCell={false}
                  currentRows={currentRows}
                  isAllRowsSelected={isAllRowsSelected}
                />
              </div>
            );
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: { row: any }) => {
            return (
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  isCell={true}
                  value={row.original.isActive}
                  word={row.original.word}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    }
  );
  const { pageIndex } = state;

  return (
    <>
      <TableStyled {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, i) => {
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}{' '}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span style={{ marginLeft: '10px' }}>🔽</span>
                        ) : (
                          <span style={{ marginLeft: '10px' }}>🔼</span>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={rowIndex}>
                {row.cells.map((cell, i) => {
                  return (
                    <Fragment key={i}>
                      <td {...cell.getCellProps()}>{cell.render('Cell')} </td>
                    </Fragment>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </TableStyled>
      <ButtonsContainer>
        <PrimaryButton
          style={{ marginRight: '3px', width: '80px' }}
          disabled={!canPreviousPage}
          onClick={() => {
            previousPage();
          }}
        >
          Previous
        </PrimaryButton>
        <PrimaryButton
          style={{ width: '80px' }}
          disabled={!canNextPage}
          onClick={() => {
            nextPage();
          }}
        >
          Next
        </PrimaryButton>
        <span style={{ marginLeft: '10px' }}>
          Page{` `}{' '}
          <span style={{ fontWeight: 'bold' }}>
            {pageIndex + 1} of {pageOptions.length}
          </span>
        </span>
      </ButtonsContainer>
    </>
  );
};

export default FrequenciesTable;
