import styled from "styled-components";
import { colors } from "../../../../../utils";
import { Title } from "../../reactions/CommonStyles";

export const Wrapper = styled.div<{ isReportPage?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  min-height: 311px;
  border-radius: 6px;
  width: 50%;

  ${Title} {
    margin-top: 20px;
  }
  .chart-headline {
    font-size: 21px;
    padding-left: 10px;
    padding-bottom: 20px;
    padding-top: 0;
  }
`;

export const TitleAndCollapser = styled.div<{collapsed: boolean}>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .collapse-btn-wrapper{
    background: #3473E212;
    display: flex;
    height: fit-content;
    padding: 6px 12px;

    .collapse-btn{
      display: flex;
      flex-direction: row;
    }
  }
`;

export const WrapperReactionsBreak = styled.div<{ isReportPage?: boolean }>`
  padding: 20px 0;
  border-radius: 6px;
  width: 45%;
  ${Title} {
    margin-top: 20px;
  }
`;

export const CompareGraphTitle = styled.div`
  color: ${colors.darkBlue};
  margin-bottom: 25px;
  font-size: 21px;
  padding-left: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  .custom-tooltip {
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    padding: 10px;
    background-color: white;

    .label{
      color: ${colors.darkBlue};
      font-size: 12px;
    }
    .value{
      font-size: 10px;
      color: #333B5099;
    }
  }
`;

export const ContainerGraphs = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 150px;
  justify-content: space-around;
`;

export const ContainerGraph = styled.div<{index:number,collapsed:boolean}>`
  position: relative;
  margin-bottom: 20px;
  display: ${({ index,collapsed }) => (collapsed && index < 2 ? 'block' : 'none')};
  display: ${({ index,collapsed }) => (!collapsed  && 'block' )};
  .graphTitle{
    position: absolute;
    left: 150px;
    bottom: 0;
    font-size: 10px;
    color: ${colors.darkBlue};
    font-weight: 400px;
  }

  .reaction-name-icon-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    left:25px;

    .graphTitle {
      width: fit-content;
      margin: auto;
      left: 0;
      position: relative;
      bottom: 0;
    }

    .emoji{
      width: fit-content;
      margin: auto;
    }
  }

  .custom-tooltip {
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    padding: 10px;
    background-color: white;

    .label{
      color: ${colors.darkBlue};
      font-size: 12px;
    }
    .posts_count{
      font-size: 10px;
      color: #333B5099;
    }
  }
`;

export const ReactionCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
  justify-content: space-around;
  img {
    width: 40px;
    height: 40px;
  }
`;
