import { useContext } from 'react';
import { AppContext } from '../../../../../store/context';
import { WrapperReactionsBreak, CompareGraphTitle } from './Styles';
import { POSTS_GROUP_AVERAGES, SINGLE_POST_DETAILED_RESPONSE } from '../../../../../store/interface';
import { colors } from '../../../../../utils';

import BrandBreakDown from '../BreakDown/BreakDown'
import { ChartHeadline } from '../Sentiment/Styles';

const ReactionsBreakDown = ({
  positiveComments,
  negativeComments,
  neutralComments,
  isReportPage,
}: {
  positiveComments: SINGLE_POST_DETAILED_RESPONSE["positiveComments"];
  negativeComments: SINGLE_POST_DETAILED_RESPONSE["negativeComments"];
  neutralComments: SINGLE_POST_DETAILED_RESPONSE["neutralComments"];
  isReportPage: boolean;
}) => {
  const {
    state: { report },
  } = useContext(AppContext);
  const { currentPost, averageDetailsPostsToCompare, averagedPostCount } = report;

  let reactionsSentiment = [
    {
      name: currentPost!.brand.name,
      total: "1",
      totalPosts: 1,
      positive: currentPost!.reactionsSentiments.veryPositiveReactions,
      neutral: currentPost!.reactionsSentiments.positiveReactions,
      negative: currentPost!.reactionsSentiments.negativeReactions,
    },
  ];

  const detailsPostsToCompare = report.detailsPostsToCompare;
  if (detailsPostsToCompare && Object.keys(detailsPostsToCompare).length > 0) {
    Object.keys(detailsPostsToCompare).forEach((key) => {
      const newBar: any = {
        name: key,
        total: detailsPostsToCompare[key].averages.totalComments.toString(),
        totalPosts: detailsPostsToCompare[key].postsCount,
        positive: detailsPostsToCompare[key].averages.reactionsSentiments.veryPositiveReactions,
        neutral: detailsPostsToCompare[key].averages.reactionsSentiments.positiveReactions,
        negative: detailsPostsToCompare[key].averages.reactionsSentiments.negativeReactions,
      };
      reactionsSentiment.push(newBar);
    });
  }

  if (Object.keys(averageDetailsPostsToCompare).length > 0) {
    reactionsSentiment.splice(1, reactionsSentiment.length);
    const averageDetailsPostsToCompareTemporal = averageDetailsPostsToCompare as POSTS_GROUP_AVERAGES;
    const newBar = {
      name: "Competitive Average",
      total: averagedPostCount.toString(),
      totalPosts: averagedPostCount,
      positive: averageDetailsPostsToCompareTemporal.reactionsSentiments.veryPositiveReactions,
      neutral: averageDetailsPostsToCompareTemporal.reactionsSentiments.positiveReactions,
      negative: averageDetailsPostsToCompareTemporal.reactionsSentiments.negativeReactions,
    };
    reactionsSentiment.push(newBar);
  }


  return (
    <WrapperReactionsBreak>
        <> 
         <ChartHeadline >Reactions Breakdown </ChartHeadline>
         <BrandBreakDown 
         reactionsSentiment = {reactionsSentiment}
         isReportPage={true}
         relatedBreakdown="reactions"
         />
         </>
    </WrapperReactionsBreak>
  );
};

export default ReactionsBreakDown;
