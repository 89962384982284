import { useContext } from "react";
import { AppContext } from "../../../../../store/context";
import { colors, colorsCharts, randomizeColorsChart } from "../../../../../utils";
import { assets } from "../../../utils/assets";
import { MentionsData } from "../Sentiment/Interface";
import { StripedBar } from "./StripedBar";
interface IRenderAvgStripedBarParams {
    x: number;
    y: number;
    width: number;
    height: number;
    index: number;
    payload: { postsCount: number, posts: number, total: number, brandName: string };
}
interface IRenderPostsCountTickParams {
    x: number;
    y: number;
    index: number;
    payload: { postsCount: number, posts: number, value: number | string, total: number, brandName: string };
}
interface IRenderCustomAvgTickParams {
    x: number;
    y: number;
    index: number;
    payload: { postsCount: number, posts: number, value: number | string, total: number, brandName: string };
}

export const useCustomChartRenderers = () => {

    const { state: { report: { 
        currentPostsGroup, 
        averageDetailsPostsToCompare, 
        averagedPostCount, 
        groupExpended,
        postsToCompare
    } } } = useContext(AppContext)

    const getRandomColor = (index: number) => {
        return Object.values(colorsCharts)[index];
    };

    const renderCustomLabel = (labelProps: any) => {
        const { x, y, width, value, offset } = labelProps;
        if(value === 0 && !postsToCompare.length) return <></>
        const opacity = value === 0 ? '0.35' : '1';
        return <><text style={{fontSize:'12px', fontWeight:'bold', opacity:`${opacity}` }} textAnchor="middle" x={x + width / 2} y={y - offset} fill="#000">{value}</text></>
    }

    const renderCustomAvgTick = (tickProps: IRenderCustomAvgTickParams, avgBrands?: { [key: string]: number }, mentionsData?: MentionsData[]) => {
        const { x, y, index, payload } = tickProps;
        if(payload.value === 0 && !postsToCompare.length) return <></>;
        const opacity = payload.value === 0 ? '0.35' : '1';
        let firstBrandPostsCount = currentPostsGroup.posts.length && !groupExpended ? currentPostsGroup.posts.length : 1;
        if (!mentionsData) {
            const valueToCompare = currentPostsGroup.posts.length && !groupExpended && index === 0 ? firstBrandPostsCount : payload.value
            return <image href={assets['Avreage']} style={{opacity:`${opacity}`}} x={x - 8} y={y} display={valueToCompare > 1 ? 'inline-block' : 'none'} />
        }
        let postsCount = 0;
        const currMentionName = mentionsData && mentionsData[index] ? mentionsData[index].brandName : ''
        if (index === 1 && mentionsData[index] && mentionsData[index].brandName === 'Competitive Average' && avgBrands) {
            postsCount = Object.values(avgBrands).reduce((a, b) => a + b);
        } else {
            postsCount = index === 0 && Boolean(firstBrandPostsCount) ?
                firstBrandPostsCount :
                avgBrands ? (avgBrands[currMentionName] || 0) : (payload.posts || payload.postsCount);
        }
        return <image href={assets['Avreage']} x={x - 8} y={y} style={{opacity:`${opacity}`}} display={postsCount > 1 ? 'inline-block' : 'none'} />
    }

    const renderPostsCountTick = (tickProps: IRenderPostsCountTickParams, avgBrands?: { [key: string]: number }, mentionsData?: MentionsData[]) => {
        const firstBrandPostsCount = currentPostsGroup.posts.length && !groupExpended ? currentPostsGroup.posts.length : 1;
        const { x, y, index, payload } = tickProps;
        if(payload.value === 0 && !postsToCompare.length) return <></>;
        const opacity = payload.value === 0 ? '0.35' : '1';
        let postsCount = 0;
        if (!mentionsData) {
            const valueToCompare = currentPostsGroup.posts.length && !groupExpended && index === 0 ? firstBrandPostsCount : payload.value
            return <text textAnchor="middle" style={{opacity:`${opacity}`}} x={x} y={y} display={valueToCompare > 1 ? 'inline-block' : 'none'} >{valueToCompare} </text>
        }
        const currMentionName = mentionsData && mentionsData[index] ? mentionsData[index].brandName : '';
        if (index === 1 && mentionsData[index] && mentionsData[index].brandName === 'Competitive Average' && avgBrands) {
            postsCount = Object.values(avgBrands).reduce((a, b) => a + b);
        } else {
            postsCount = index === 0 && Boolean(firstBrandPostsCount) ?
                firstBrandPostsCount :
                postsCount = avgBrands ? (avgBrands[currMentionName] || 0) : (payload.posts || payload.postsCount);
        }
        return <text textAnchor="middle" style={{opacity:`${opacity}`}} x={x} y={y} display={postsCount > 1 ? 'inline-block' : 'none'} >{postsCount} </text>
    }

    const renderAvgStripedBar = (barProps: IRenderAvgStripedBarParams, graphName: string, avgBrands?: { [key: string]: number }) => {
        const { x, y, width, height, index, payload } = barProps;
        let barColor = randomizeColorsChart[index % randomizeColorsChart.length];
        let postsCount = 0;
        // if( payload.total === 0) return  <rect x={x} y={y} width={width} height={height} fill={barColor} />
        if (index === 0) {
            barColor = colors.chartGreen;
            return Boolean(currentPostsGroup.posts.length) && !groupExpended ?
                <StripedBar x={x} y={y} width={width} height={height} background={barColor} source={graphName} index={index}/> :
                <rect x={x} y={y} width={width} height={height} fill={barColor} />
        }
        if (index === 1 && payload.brandName === 'Competitive Average' && avgBrands) {
            postsCount = Object.values(avgBrands).reduce((a, b) => a + b);
        } else {
            postsCount = avgBrands ? (avgBrands[payload.brandName] || 0) : (payload.posts || payload.postsCount);
        }

        return postsCount > 1 ?
            <StripedBar x={x} y={y} width={width} height={height} background={barColor} source={graphName} index={index}/> :
            <rect x={x} y={y} width={width} height={height} fill={barColor} />;
    }

    const renderAvgStripedBarForSameColorMultBars = (barProps: IRenderAvgStripedBarParams, graphName: string, avgBrands?: { [key: string]: number }) => {
        const { x, y, width, height, index, payload } = barProps;
        let barColor = randomizeColorsChart[0];
        let postsCount = 0;

        return currentPostsGroup && !groupExpended && currentPostsGroup.posts.length ? <StripedBar x={x} y={y} width={width} height={height} background={barColor} source={graphName} index={index}/> : 
        <rect x={x} y={y} width={width} height={height} fill={barColor}/>
    }

    return {
        renderCustomLabel,
        renderCustomAvgTick,
        renderPostsCountTick,
        renderAvgStripedBar,
        renderAvgStripedBarForSameColorMultBars
    }
}