import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 700px;
`;

export const Container = styled.div`
  display: flex;
  margin-left: 10px;
`;