import { useContext, useState } from 'react';
import { Redirect } from 'react-router';
import { AppContext } from '../../../../store/context';
import { Wrapper } from './Styles';
import CompareTabs from '../../tabs/compare/CompareTabs';
import Brands from './Brands/Brands';
import Publishers from './Publishers/Publishers';

const Compare = () => {
  const [currentTab, setCurrentTab] = useState("brands");
  const {
    state: { report },
  } = useContext(AppContext);
  const { currentPost } = report;
  if (!currentPost) {
    return <Redirect to={`/${report.name}`} />;
  }
  return (
    <Wrapper>
      <CompareTabs setCurrentTab={setCurrentTab} currentTab={currentTab} />

      { currentTab === 'brands' ? (
        <Brands></Brands>
      ) : (
        <Publishers></Publishers>
      )}
    </Wrapper>
  );
};

export default Compare;
