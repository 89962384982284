import { useContext, useMemo } from "react";
import { AppContext } from "../../../../../store/context";
import { POSTS_GROUP_AVERAGES } from "../../../../../store/interface";
import { ComposedChart, CartesianGrid, Cell, LabelList, YAxis, XAxis, Bar, Tooltip } from "recharts";

import { colors, colorsCharts, randomizeColorsChart } from "../../../../../utils";
import { assets } from "../../../utils/assets";
import { Wrapper } from "./Styles";
import { ChartHeadline } from "../Sentiment/Styles";
import { capitalizeFirstLetter, getMaximumForBarGraph } from "../utils/helper";
import { useCustomChartRenderers } from "../utils/useCustomChartRenderers";


const CommentsCompare = ({ total }: { total: number }) => {
  const {
    state: { report },
  } = useContext(AppContext);
  const { detailsPostsToCompare, averageDetailsPostsToCompare, averagedPostCount, currentPostsGroup, groupExpended } = report;
  const { renderCustomLabel, renderCustomAvgTick, renderPostsCountTick, renderAvgStripedBar } = useCustomChartRenderers()

  const commentsData = useMemo(() => {
    const data = [
      {
        name: report.currentPost?.brand.name,
        total: total,
        postsCount: (currentPostsGroup.posts.length && !groupExpended) || 1,
      },
    ];

    if (
      detailsPostsToCompare &&
      Object.keys(detailsPostsToCompare).length > 0 &&
      averagedPostCount === 0 &&
      Object.keys(averageDetailsPostsToCompare).length === 0
    ) {
      Object.keys(detailsPostsToCompare).forEach((key, index) => {
        const newBar: any = {
          name: key,
          total: detailsPostsToCompare[key].averages.totalComments,
          postsCount: detailsPostsToCompare[key].postsCount,
        };
        data.push(newBar);
      });
    }

    if (Object.keys(averageDetailsPostsToCompare).length > 0) {
      data.slice(1);
      const averageDetailsPostsToCompareTemporal = averageDetailsPostsToCompare as POSTS_GROUP_AVERAGES;
      const newBar: any = {
        name: "Competitive Average",
        total: averageDetailsPostsToCompareTemporal.totalComments,
        postsCount: averagedPostCount,
      };
      data.push(newBar);
    }
    return data;
  }, [detailsPostsToCompare, averageDetailsPostsToCompare, currentPostsGroup]);

  const getRandomColor = (index: number) => {
    return Object.values(colorsCharts)[index];
  };

  const DataFormater = (number: number) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + "B";
    } else if (number > 1000000) {
      return (number / 1000000).toString() + "M";
    } else if (number > 1000) {
      return (number / 1000).toString() + "K";
    } else {
      return number.toString();
    }
  };

  const CustomTooltip = ({ active, payload, label }: any): any => {
    if (active && payload && payload.length) {
      const isGroup = currentPostsGroup.posts.length && !groupExpended;
      if((payload[0].payload.postsCount <= 1) && (!isGroup || payload[0].payload.name.length) ) return <></>
      const name = isGroup && !payload[0].payload.name.length ? currentPostsGroup.groupName : payload[0].payload.name;
      const postsCount = isGroup && !payload[0].payload.name.length ? currentPostsGroup.posts.length : payload[0].payload.postsCount;
      return (
        <div className="custom-tooltip">
          <p className="label">{`${capitalizeFirstLetter(name)}`}</p>
          <p className="posts_count">{`posts: ${postsCount}`}</p>
        </div>
      );
    }

    return null;
  };


  let maximum = getMaximumForBarGraph(commentsData, "total");
  return (
    <Wrapper>
      <div>
        <ChartHeadline>
          Comments <img src={assets["comments"]} style={{ marginLeft: "25px" }} alt="" />{" "}
        </ChartHeadline>
        <ComposedChart width={450} height={380} data={commentsData} barGap={8}>
          <XAxis dataKey="postsCount" hide={false} tick={renderPostsCountTick} tickLine={false} xAxisId={1} axisLine={{ strokeWidth: 0 }}/>
          <XAxis dataKey="postsCount" tick={(props) => {
            console.log(commentsData)
            return renderCustomAvgTick(props)
          }} xAxisId={0} tickLine={false} axisLine={{ stroke: '#4D566F', opacity:'0.2' }}/>
          <YAxis
            tickFormatter={DataFormater}
            stroke={colors.chartGray}
            padding={{ top: 20 }}
            domain={[0, maximum + 10]}
          />
          <CartesianGrid stroke="#f5f5f5" />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Bar
            shape={(shapeProps) => renderAvgStripedBar(shapeProps, 'Comments')}
            dataKey="total"
            barSize={24}
          >
            {
              commentsData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={randomizeColorsChart[index % randomizeColorsChart.length]} strokeDasharray="3 3" stroke={entry.postsCount > 1 ? colors.black : getRandomColor(index) === 'black' ? colors.white : ''} />
                ))
              }
            <LabelList offset={10} dataKey="total" position="top" content={renderCustomLabel}/>
          </Bar>
        </ComposedChart>
      </div>
    </Wrapper>
  );
};

export default CommentsCompare;
