import { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import { CONSTANTS } from "../../../store/constants";
import { AppContext } from "../../../store/context";
import { Container } from "./Styles";

const DatePickerDisplay = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { dispatch } = useContext(AppContext);

  const onChange = (dates: [Date, Date] | null) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
  };

  const handleSetDateRange = () => {
    dispatch({ type: CONSTANTS.SetDateRange, payload: { dateRange: { startDate, endDate } } });
  };
  return (
    <Container>
      <DatePicker
        onCalendarClose={handleSetDateRange}
        calendarContainer={Container}
        autoFocus={false}
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        maxDate={new Date()}
        className="date-input pointer"
        dateFormat="yyyy/MM/dd"
      />
    </Container>
  );
};

export default DatePickerDisplay;
