import styled from 'styled-components';
import { colors } from '../../../../utils';
import { AlertContainer } from '../../no-posts-alert/Styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 612px;

  ${AlertContainer} {
    margin-top: 100px;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  position: absolute;
  right: 100px;
  top: 171px;
  margin-left: auto;
  .trash {
    margin-left: 27px;
  }

  span {
    margin-right: 27px;
    font-size: 0.875rem;
    color: ${colors.darkGrey};
  }
`;

export const Container = styled.div`
  display: flex;
  margin-right: 147px;
`;
