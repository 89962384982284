import styled from 'styled-components';
import { colors } from '../../../../utils';

export const Title = styled.span`
  font-weight: 600;
  font-size: 15px;
  color: ${colors.darkBlue};
`;

export const Description = styled.span`
  font-size: 0.8125rem;
  color: ${colors.darkBlue};
  white-space: nowrap;
`;

export const Percentage = styled.span`
  font-size: 1.5625rem;
  color: ${colors.darkBlue};
`;
