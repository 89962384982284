import styled, { keyframes }  from "styled-components";
import { colors } from "../../../../../utils";

export const TableStyled = styled.table`
  border-collapse: separate;
  text-align: left;
  margin-bottom: auto;
  border-spacing: 0 8px;
  width: 100%;
  margin-top: 10px;
  padding: 15px 10px 0px 10px;
  thead{
    background: ${colors.layoutBackground};
    tr{
      color:  ${colors.darkGreyOpacity};
      background: ${colors.layoutBackground};
    }
  }
  tr:first-child {
    position: relative;
    top: 0px;
  }
  tr {
    position: relative;
    background-color: ${colors.white};
  }
  td {
    background-color: "rgba(52, 115, 226, 0.1)";
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-style: solid none;
    position: relative;
    height: 40px;
    padding: 0 25px 0 0;
  }

  td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 12px;
    padding-right: 2px;
    width: 0%;
  }

  td:nth-child(2) {
    width: 5%;
  }
  td:nth-child(3){
    width: 45%;
    img {
    margin-left: 15px;
    display: block;
    }
  } 

  td:nth-child(4) {
    width: 5%;

    p{
      width: fit-content;
      margin: auto;
    }
  }

  td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    width: 10%;
  }

  .trash {
    cursor: pointer;
  }

  tr {
    font-size: 0.8125rem;
    text-align: left;
    height: 40px;
    margin-bottom: 8px;
    &:hover {
      background-color: #fff0d3;
    }
  }

  th {
    line-height: 1.0625rem;
    color: "rgba(51, 59, 80, 0.6)";
    font-weight: 300;
    text-align: left;
    height: 40px;
    &:hover {
      background-color: none;
    }
  }

  th:first-child {
    border-bottom: 0;
  }
`;


export const Spreader = styled.div`
  height: 250px;
`
const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

export const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;

  &:checked + div {
    background: #3d85f7 !important;
  }
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 4px 18px 9px 7px;
  color: #ffff;
  padding-left: 0px;
`;

export const Indicator = styled.div`
  width: 16px;
  height: 16px;
  background: ${colors.white};
  position: absolute;
  top: 6px;
  left: -13px;
  border: 2px solid #3d85f7;
  border-radius: 0.2em;

  .head-indicator {
    left: 0.5;
  }

  ${Input}:not(:disabled):checked & {
    background: #3d85f7;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + div {
    background: #3d85f7 !important;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 0em;
    left: 0.3em;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    /* animation-duration: 0.3s; */
    animation-fill-mode: forwards;
  }

  &::disabled {
    cursor: not-allowed;
  }
`;


export const CommentsActions = styled.div`
    position: absolute;
    top: 55px;
    right: -1145px;
    display: inline-flex;
    text-align: right;
    color: ${colors.tabTitle};
    float: right;
    p {
      font-size: 13px;
    }
`

export const IconImg = styled.img`
    margin-left: 12px;
    margin-right: 12px;
`

export const IconEye = styled.img`
    height: 20px;
    margin-left: 7px;
    margin-right: 9px;
`

export const Button = styled.div`
    display: inline-flex;
`
export const IconEdit = styled.img`
    position: relative;
    top: 1.2px;
    height: 16px;
    margin-left: 7px;
    margin-right: 9px;
`

export const SingleComment = styled.div`
  padding: 10px;
  line-height: 30px;
`