import { Page, StyleSheet, Image, View, Text } from "@react-pdf/renderer";
import { SINGLE_POST_DETAILED_RESPONSE } from "../../../../store/interface";
import Footer from "../Footer";
import Header from "../Header";
import { DATA_URL } from "../interface";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "rgb(251, 253, 255)",
  },

  likesBar: {
    width: 400,
    height: 70,
  },
  moreReactions: {
    width: 400,
    height: 200,
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "90%",
    marginTop: "70px",
  },
  reactionsText: {
    fontFamily: "Poppins-Bold",
    marginTop: "15px",
    fontSize: "20px",
  },
  paddingContent: {
    padding: "40px 57px",
  },
});

const Page2 = ({
  likesBarData,
  moreLikesBar,
  currentPost,
  reportName,
}: {
  currentPost: SINGLE_POST_DETAILED_RESPONSE;
  likesBarData: DATA_URL;
  moreLikesBar: DATA_URL;
  reportName: string;
}) => {
  const MyDoc = (
    <Page style={styles.page} size="A4" orientation="landscape">
      <View style={styles.paddingContent}>
        <Header reportName={reportName} current_page={2} total_pages={5} page_title={"Reactions"} />
        <View style={styles.content}>
          <View>
            <Text>Organic Posts</Text>
            <Text style={styles.reactionsText}>Reactions</Text>
          </View>
          <View>
            <Image style={styles.likesBar} src={likesBarData.url}></Image>
            <Image style={styles.moreReactions} src={moreLikesBar.url}></Image>
          </View>
        </View>
      </View>
      <Footer currentPost={currentPost} />
    </Page>
  );

  return MyDoc;
};

export default Page2;
