import { useContext, useState, useEffect, ChangeEvent } from "react";
import { AppContext } from "../../../store/context";
import { AlertHighlights } from "./AlertsHighlight/AlertHighlight";
import { AlertTotal } from "./AlertsHighlight/AlertsTotal";
import AlertsTable from "./AlertsTable/AlertsTable";
import { assets } from "../../ui/utils/assets";
import { getAllWidgetAlerts } from "./helper";
import {
  fetchAllAlerts,
  fetchVerifyAndHideAlerts,
  fetchWordBank,
} from "../../../api/facebookApi";
import { useHistory } from "react-router";
import { AlertsHighlightWrapper, 
  HeaderTitle, Wrapper, CommentsActions, 
  Icon, IconEye, Button, 
  PopUpBox, PopUpWrapper, PopUpInner ,
  Cancel, Actions, YesVerify, YesHide, HideActionWrapper,
  Label, Input, Indicator, CompareLoader,
  TitleWrapper, IconBack, SearchWrapper,IconSearch
} from "./Styles";
import {
  POST_ALERTS,
  ALERTS_WIDGETS,
  POST_ALERT_WORD,
} from "../../../store/interface";
import * as _ from "lodash";
import { CONSTANTS } from "../../../store/constants";

export const AlertsDashboard = () => {
  const history = useHistory();
  const {
    state: { alert },
    dispatch,
  } = useContext(AppContext);
  const { alertCommentsCollection, datePick } = alert;

  const [openPopUp, setOpenPopUp] = useState(false);
  const [actionType, setActionType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingVerifyHide, setLoadingVerifyHide] = useState(false);
  const [alertsWidgetsData, setAlertsWidgetsData] =
    useState<ALERTS_WIDGETS | null>(null);

  const [postsData, setPostsData] = useState<POST_ALERTS[]>();
  const [originalPostsData, setOriginalPostsData] = useState<POST_ALERTS[]>();
  const [searchField, setSearchField] = useState("");
  useEffect( () => {
    const getTheAlertsData = async ()=>{
      setIsLoading(true)
      const allAlertsWidgetData = await getAllWidgetAlerts(datePick);
      let posts: POST_ALERTS[] = await fetchAllAlerts(datePick);
      posts.forEach((post) => {
        let postWordsOfAlerts: string[] = [];
        post.alert.forEach((comment) => {
          comment.message = comment.comment.message;
          postWordsOfAlerts = [...postWordsOfAlerts, ...comment.alerted_words];
        });
        post.alerted_words = _.uniqWith(
          postWordsOfAlerts,
          (a: string, b: string) => a.toLowerCase() === b.toLowerCase()
        );
      });
      setAlertsWidgetsData(allAlertsWidgetData);
      setPostsData(posts);
      setOriginalPostsData(posts)
      setIsLoading(false);
    };

    const getWordBankToState = async () => {
      const getWordBank: POST_ALERT_WORD[] = await fetchWordBank();
      dispatch({
        type: CONSTANTS.SetWordBankAlerts,
        payload: {
          wordBank: getWordBank,
        },
      });
    };
    getWordBankToState();
    getTheAlertsData();
  }, [datePick, dispatch]);


  const actionTypes = {
    Verify: "Verify",
    HiddenEye: "HiddenEye",
  };

  const verifyAndHiddenHandlePopUp = async (actionType: string) => {
    setOpenPopUp(true);
    setActionType(actionType);
  };

  const sendVerifyAndHide = async (actionType: string) => {
    const ids: string[] = [];
    alertCommentsCollection.forEach((alertComment) => {
      ids.push(alertComment.alertComment_id);
    });
    setLoadingVerifyHide(true);
    const sendAction = await fetchVerifyAndHideAlerts(actionType,ids)

    if ( sendAction ) { 
      const copyPostData = postsData?.reduce((prevPosts:POST_ALERTS[],post) =>{
        const newAlerts = post.alert.filter((comment) =>{
          return ids.indexOf(comment.comment_id) === -1
        })
        if ( !newAlerts.length ) {
          return prevPosts
        }
        const postCopy = {...post,alert:newAlerts}
        prevPosts.push(postCopy)
        return prevPosts
      },[]);
      
      setPostsData(copyPostData);
      setLoadingVerifyHide(false);
      dispatch({
        type: CONSTANTS.ResetAllCommentCollection,
        payload: null,
      });
      window.alert(`${actionType} action had passed successfully!`)
      setOpenPopUp(false);
    } else {
      window.alert('Something Went wrong')
      window.location.reload()
    }
    
  };
  const closePopUp = () => {
    setOpenPopUp(false);
  };

  const handleBack = ()=> {
    dispatch({
      type: CONSTANTS.ResetAllCommentCollection,
      payload: null,
    });
    history.push('/')
  }
  
  const handleSearchChange = (e:ChangeEvent<HTMLInputElement>) => {
    setSearchField(e.target.value);
  };

  const callSearch = () => {
    if (searchField.length > 0) {
      setIsLoading(true)
      const afterSearchPostsAlerts = postsData?.reduce((prevPosts:POST_ALERTS[],post) =>{
        const newAlerts = post.alert.filter((comment) =>{
          return comment.message.includes(searchField)
        })
        if ( !newAlerts.length ) {
          return prevPosts
        }
        const postCopy = {...post,alert:newAlerts}
        prevPosts.push(postCopy)
        return prevPosts
      },[]);
      setPostsData(afterSearchPostsAlerts)

      if (afterSearchPostsAlerts?.length === 0) {
        const SearchPostsAlerts = originalPostsData?.reduce((prevPosts:POST_ALERTS[],post) =>{
          const newAlerts = post.alert.filter((comment) =>{
            return comment.message.includes(searchField)
          })
          if ( !newAlerts.length ) {
            return prevPosts
          }
          const postCopy = {...post,alert:newAlerts}
          prevPosts.push(postCopy)
          return prevPosts
        },[]);
        setPostsData(SearchPostsAlerts)
      } 
      
      setIsLoading(false)
    } else {
      setIsLoading(true)
      setPostsData (originalPostsData)
      setIsLoading(false)
    }
    
  }

  const resetSearch = () => {
    setSearchField('')
    setPostsData(originalPostsData)
  }
  return (
    <>
    <Wrapper>
      <TitleWrapper>
            <div className='back-wrapper' onClick={handleBack}>
                <IconBack src={assets['arrowLeft']}/>
            </div>
            <div className="title">
             Alerts
            </div>
            <SearchWrapper>
              <input 
              type="text" 
              placeholder = "Search comments" 
              onChange = {handleSearchChange}
              value={searchField}
              name="" 
              id="" />
              
              {searchField && (
                <IconSearch onClick={resetSearch} style={{marginLeft:'-8px',right:'34px'}} src={assets['close']} />
              )}
              <IconSearch onClick={callSearch} src={assets['searchIcon']}/>
            </SearchWrapper>
            
        </TitleWrapper>

        <AlertsHighlightWrapper>
          {alertsWidgetsData && (
            <AlertTotal totalAlerts={alertsWidgetsData.totalAlert} data={postsData} />
          )}
          {!isLoading && alertsWidgetsData !== null ? (
            <>
              {Object.keys(alertsWidgetsData.widgets).map((key, i) => (
                <AlertHighlights
                  key={i}
                  widget={key}
                  topCategoryWord={alertsWidgetsData.topCategoriesWords[i]}
                  widgetCount={alertsWidgetsData.widgets[key]}
                  totalAlerts={alertsWidgetsData.totalAlert}
                />
              ))}
            </>
          ) : (
            <CompareLoader className="loader" src={assets["loader"]} alt="" />
          )}
        </AlertsHighlightWrapper>

        {alertCommentsCollection.length > 0 && (
          <CommentsActions>
            <p>{alertCommentsCollection.length} comments selected</p>
            <Button
              onClick={() => verifyAndHiddenHandlePopUp(actionTypes.Verify)}
            >
              <Icon src={assets[actionTypes.Verify]} />
            </Button>
            <Button
              onClick={() => verifyAndHiddenHandlePopUp(actionTypes.HiddenEye)}
            >
              <IconEye src={assets[actionTypes.HiddenEye]} />
            </Button>
          </CommentsActions>
        )}

        {!isLoading && postsData ? (
          <>
            <AlertsTable data={postsData} />
          </>
        ) : (
          <CompareLoader className="loader" src={assets["loader"]} alt="" />
        )}

        {openPopUp && alertCommentsCollection.length > 0 && (
          <PopUpWrapper>
            {!loadingVerifyHide ? (
              <>
                {actionType === actionTypes.Verify && (
                  <PopUpBox>
                    <PopUpInner>
                      <p>
                        Verify ({alertCommentsCollection.length}) Comments?
                      </p>
                      <Actions>
                        <Cancel onClick={closePopUp}> Cancel </Cancel>
                        <YesVerify onClick={() => sendVerifyAndHide("verify")}>
                          <Icon
                            className="icon-img"
                            src={assets["verifyGreen"]}
                          />
                          Yes Verify
                        </YesVerify>
                      </Actions>
                    </PopUpInner>
                  </PopUpBox>
                )}

                {actionType === actionTypes.HiddenEye && (
                  <PopUpBox>
                    <PopUpInner>
                      <p>Hide ({alertCommentsCollection.length}) Comments? </p>
                      <Actions className="yes-hide-wrapper">
                        <Cancel onClick={closePopUp}> Cancel </Cancel>
                        <YesHide onClick={() => sendVerifyAndHide("hide")}>
                          <Icon className="icon-img" src={assets["HideRed"]} />{" "}
                          Yes Hide
                        </YesHide>
                      </Actions>
                    </PopUpInner>
                  </PopUpBox>
                )}
              </>
            ) : (
              <>
                <CompareLoader
                  className="loader"
                  src={assets["loader"]}
                  alt=""
                />
              </>
            )}
          </PopUpWrapper>
        )}
      </Wrapper>
    </>
  );
};
