import styled from 'styled-components';
import { colors, commentAdReactiosSensetivityColors } from '../../../../../utils';
import { assets } from '../../../utils/assets';
import { Title } from '../CommonStyles';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { SINGLE_POST_DETAILED_RESPONSE } from '../../../../../store/interface';
import { Total } from '../../graphs/commentsSentiment/Styles';

export const Wrapper = styled.div`
  height: 104px;
  margin-bottom: 7px;
  border-top: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: ${colors.white};
  padding: 13px 18px 4px 18px;
`;

const ReactionsBar = ({
  reactionsSentiments,
  isReportPage,
}: {
  reactionsSentiments: SINGLE_POST_DETAILED_RESPONSE['reactionsSentiments'];
  isReportPage: boolean;
}) => {
  const { positiveReactions, veryPositiveReactions, negativeReactions } = reactionsSentiments;
  const total = positiveReactions + veryPositiveReactions + negativeReactions;
  let reactionsSentiment = [
    {
      name: '',
      total,
      positive: positiveReactions,
      negative: negativeReactions,
      veryPositive: veryPositiveReactions,
      imgSrc: assets['likeColored'],
    },
  ];



  return (
    <Wrapper id={'reactions-bar'}>
      <Title>
        Reactions <Total>{total} Total</Total>
      </Title>
      <ResponsiveContainer width={'90%'}>
        <BarChart
          width={500}
          height={300}
          data={reactionsSentiment}
          layout={'vertical'}
          barSize={20}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <YAxis
            axisLine={false}
            width={65}
            type={'category'}
            tickLine={false}
            dataKey={'name'}
            orientation={'left'}
          />
          <XAxis tick={false} axisLine={false} type={'number'} dataKey={'total'} orientation={'bottom'} />
          <Bar stackId={'a'} radius={[5, 0, 0, 5]} dataKey="veryPositive" fill={commentAdReactiosSensetivityColors.veryPositive} />
          <Bar stackId={'a'} dataKey="positive" fill={commentAdReactiosSensetivityColors.positive} />
          <Bar stackId={'a'} radius={[0, 5, 5, 0]} dataKey="negative" fill={commentAdReactiosSensetivityColors.negative} />
          <Tooltip formatter={(val: any) => `${val}  (${(Intl.NumberFormat("en-GB", {
            style: "percent", minimumFractionDigits: 0,
            maximumFractionDigits: 1
          }).format(val / total))})`}
            cursor={{ fill: 'transparent' }} />
        </BarChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

export default ReactionsBar;
