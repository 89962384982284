import { useContext, useState, useEffect, ChangeEvent } from "react";
import { AppContext } from "../../../../store/context";
import { AlertHighlights } from "./AlertsHighlight/AlertHighlight";
import AlertsTable from "./AlertsTable/AlertsTable";
import { assets } from "../../../ui/utils/assets";
import { mockAlertsData } from './MockData'
import { AlertsHighlightWrapper, 
  Wrapper, CommentsActions, 
  Icon, IconEye, Button, 
  PopUpBox, PopUpWrapper, PopUpInner ,
  Cancel, Actions, YesVerify, YesHide, AlertsWordWrapper,
  Title, SearchWrapper, IconSearch
} from "./Styles";
import { FreqWord } from "./AlertsHighlight/Styles";
import { fetchPostAlerts, fetchVerifyAndHideAlerts, fetchWordBank } from "../../../../api/facebookApi";
import { ALERT_ICON, COMMENT_ALERT, EDIT_POST_ALERT_WORD, POST_ALERTS, POST_ALERT_WORD } from "../../../../store/interface";
import { AlertIcons, applyCategoryToTable } from "../../../pages/alerts/helper";
import { CONSTANTS } from "../../../../store/constants";
import { CompareLoader } from "../../../pages/alerts/Styles";
const Alerts = () => {
  const {
    state: { alert,report },
    dispatch
  } = useContext(AppContext);
  const { alertPostCommentsCollection } = alert
  const { currentPost } = report

  const [openPopUp,setOpenPopUp] = useState(false)
  const [actionType, setActionType] = useState('')
  const [postAlertsData, setPostAlertsData ] = useState<POST_ALERTS[]>([])
  const [postAlerts, setPostAlerts] = useState<COMMENT_ALERT[]>()
  const [originalPostAlerts, setOriginalPostAlerts] = useState<COMMENT_ALERT[]>()
  const [postAlertedWords, setPostAlertedWords] = useState<POST_ALERT_WORD[]>()
  const [alertIcons, setAlertIcons] = useState<ALERT_ICON[]>();
  const [loadingVerifyHide, setLoadingVerifyHide] = useState(false);
  const [totalAlertsCount, setTotalAlertsCount] = useState<number>()
  const [noAlerts, setNoPost] = useState(false)
  const [searchField, setSearchField] = useState("");

  useEffect(()=>{
    const getPostAlertsData = async () =>{
      if (report.dateRange){
        const getPostAlerts:POST_ALERTS[] = await fetchPostAlerts(currentPost!.postDetails.id,report.dateRange )
        if ( getPostAlerts.length > 0 ) {
          const wordBank:POST_ALERT_WORD[] = await fetchWordBank()
          const newPostAlertedWords:EDIT_POST_ALERT_WORD[] = []
    
          getPostAlerts[0].alert.forEach((alert)=>{
             getPostAlerts[0].alerted_words.push(...alert.alerted_words)
          })
          getPostAlerts[0].alerted_words = getPostAlerts[0].alerted_words.filter((alertedWord,indx)=>{
            return getPostAlerts[0].alerted_words.indexOf(alertedWord) === indx
          })


          getPostAlerts[0].alerted_words.forEach((alerted_word)=>{
            wordBank.forEach((word)=>{
              if ( word.word === alerted_word ){
                newPostAlertedWords.push({
                  word:word.word,
                  category:word.category,
                  count:0
                })
              }
            })
          })

          getPostAlerts[0].alert.forEach((comment)=>{
            for (let i=0; i< newPostAlertedWords.length; i++){
              let word:string = newPostAlertedWords[i].word
              if (comment.comment.message.includes(word)){
                newPostAlertedWords[i].count++
              }
            }
          })

          let alertCount = 0
          newPostAlertedWords.forEach((word,index) => {
            AlertIcons.forEach((alertIcon)=>{
              if ( word.category === alertIcon.id ){
                alertIcon.count = word.count
                alertCount +=word.count
              }
            })
          })
          
          setPostAlertsData(getPostAlerts)
          setPostAlerts(getPostAlerts[0].alert)
          setOriginalPostAlerts(getPostAlerts[0].alert)
          setPostAlertedWords(newPostAlertedWords)
          setAlertIcons(AlertIcons)
          setTotalAlertsCount(alertCount)
        } 
        else {
          setNoPost(true)
        }
      }
      
      
      
     
    }
    getPostAlertsData()
  },[])
 
  const actionTypes = {
    Verify: 'Verify',
    HiddenEye: 'HiddenEye'
  }
  const verifyAndHiddenHandle = (actionType:string)=>{
    setOpenPopUp(true)
    setActionType(actionType)
  }

  const sendVerifyAndHide = async (actionType: string) => {
      const ids: string[] = [];
      alertPostCommentsCollection.forEach((alertComment) => {
        ids.push(alertComment.alertComment_id);
      });
      setLoadingVerifyHide(true);
      const sendAction = await fetchVerifyAndHideAlerts(actionType,ids)

      if ( sendAction && postAlerts) { 
        postAlerts.map((comment,indx)=>{
          if (ids.indexOf(comment.comment_id) !== -1) {
            return postAlerts.splice(indx,1)
          }
        })
        
        setPostAlerts(postAlerts)
        setLoadingVerifyHide(false);
        dispatch({
          type: CONSTANTS.ResetAllCommentCollection,
          payload: null,
        });
        setPostAlertedWords([])
        setAlertIcons([])
        window.alert(`${actionType} action had passed successfully!`)
        setOpenPopUp(false);
      } 
      else {
        window.alert('Something Went wrong')
        window.location.reload()
      }
        
  };

  const handleSearchChange = (e:ChangeEvent<HTMLInputElement>) => {
      setSearchField(e.target.value);
  };

  const callSearch = () => {
    if ( searchField.length > 0 ) {
      const SearchAlerts = postAlerts?.reduce((prevAlerts:COMMENT_ALERT[],alert)=>{
        if ( alert.comment.message.includes(searchField) ) {
          prevAlerts.push(alert)
        }
        return prevAlerts
      },[])
      setPostAlerts(SearchAlerts)
     
      if ( SearchAlerts?.length === 0) {
        const originalSearchAlerts = originalPostAlerts?.reduce((prevAlerts:COMMENT_ALERT[],alert)=>{
          if ( alert.comment.message.includes(searchField) ) {
            prevAlerts.push(alert)
          }
          return prevAlerts
        },[])
        setPostAlerts(originalSearchAlerts)
      }
    } else {
      setPostAlerts(originalPostAlerts)
    }
  }

  const closePopUp = () =>{
    setOpenPopUp(false)
  }

  const resetSearch = () => {
    setSearchField('')
    setPostAlerts(originalPostAlerts)
  }

  return (
    <Wrapper>
      { noAlerts ? (
        <h1>No alerts</h1>
      ) : (
        <>
        <SearchWrapper>
              <input 
              type="text" 
              placeholder = "Search comments" 
              onChange = {handleSearchChange}
              value={searchField}
              name="" 
              id="" />
              
              {searchField && (
                <IconSearch onClick={resetSearch} style={{marginLeft:'-8px',right:'34px'}} src={assets['close']} />
              )}
              <IconSearch onClick={callSearch} src={assets['searchIcon']}/>
        </SearchWrapper>
        <AlertsHighlightWrapper>
        {alertIcons && (
          <>
          {alertIcons.map((icon, i) => (
              <AlertHighlights 
              key={i} 
              alert={icon} 
              totalAlerts={mockAlertsData.totalAlerts} />
            ))}
          </>
        )}
        
        
      </AlertsHighlightWrapper>
      <AlertsWordWrapper>
          <Title>
            <div className="total-alert" >{totalAlertsCount}</div>
            <div className="sub-title" > Total Alerts </div>
          </Title>
          { postAlertedWords && (
            <div className="word-content">
              {postAlertedWords.map((alert,i)=>(
                  <FreqWord key={i} category={applyCategoryToTable(alert.category)}>{alert.word}</FreqWord>
              ))}
           </div>
          )}
          
      </AlertsWordWrapper>
    
      {alertPostCommentsCollection.length > 0 && (
        <CommentsActions>
          <p>
            {alertPostCommentsCollection.length} comments selected
          </p>
          <Button onClick={()=>verifyAndHiddenHandle(actionTypes.Verify)}>
          <Icon src={assets[actionTypes.Verify]} />
          </Button>
          <Button onClick={()=>verifyAndHiddenHandle(actionTypes.HiddenEye)} >
          <IconEye src={assets[actionTypes.HiddenEye]}  />
          </Button>
          
        </CommentsActions>
      )}
      { postAlertedWords && postAlerts && (
        <>
        <AlertsTable
          words={postAlertedWords} 
          data={postAlerts}
        />
        </>
      )}
      

      {openPopUp && alertPostCommentsCollection.length >0 && (
        <PopUpWrapper>
         {!loadingVerifyHide ? (
              <>
                {actionType === actionTypes.Verify && (
                  <PopUpBox>
                    <PopUpInner>
                      <p>
                        Verify ({alertPostCommentsCollection.length}) Comments?{" "}
                      </p>
                      <Actions>
                        <Cancel onClick={closePopUp}> Cancel </Cancel>
                        <YesVerify onClick={() => sendVerifyAndHide("verify")}>
                          <Icon
                            className="icon-img"
                            src={assets["verifyGreen"]}
                          />{" "}
                          Yes Verify
                        </YesVerify>
                      </Actions>
                    </PopUpInner>
                  </PopUpBox>
                )}

                {actionType === actionTypes.HiddenEye && (
                  <PopUpBox>
                    <PopUpInner>
                      <p>Hide ({alertPostCommentsCollection.length}) Comments? </p>
                      <Actions className="yes-hide-wrapper">
                        <Cancel onClick={closePopUp}> Cancel </Cancel>
                        <YesHide onClick={() => sendVerifyAndHide("hide")}>
                          <Icon className="icon-img" src={assets["HideRed"]} />{" "}
                          Yes Hide
                        </YesHide>
                      </Actions>
                    </PopUpInner>
                  </PopUpBox>
                )}
              </>
            ) : (
              <>
                <CompareLoader
                  className="loader"
                  src={assets["loader"]}
                  alt=""
                />
              </>
            )}
        </PopUpWrapper>
      )}
        </>
      )}
    </Wrapper>
  );
};

export default Alerts