import { useContext, useEffect, useState } from "react";
import { Switch, Route, useRouteMatch, useHistory, Redirect } from "react-router-dom";
import PostsDisplay from "../components/pages/report-preperation/posts/PostsDisplay";
import PostsInput from "../components/pages/report-preperation/posts/PostsInput";
import WordBank from "../components/pages/report-preperation/word-bank/WordBank";
import ReportHeaderSection from "../components/ui/report/ReportHeaderSection";
import { AppContext } from "../store/context";
import PostRouter from "./PostRouter";

const ReportRouter = () => {
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const [isHeaderSection, setIsHeaderSection] = useState<boolean>(false);
  const [nestedRouteMatch, setNestedRouteMatch] = useState<string | null>(null);

  const {
    state: { report },
  } = useContext(AppContext);

  const routeMatchPath = routeMatch.path;

  useEffect(() => {
    const targetPathArray = history.location.pathname.split("/");

    setIsHeaderSection(targetPathArray.length > 2 && !targetPathArray.includes("word-bank"));
  }, [history.location.pathname]);

  const getFullPath = (path: string) => {
    return routeMatchPath + path;
  };
  const isTargetPostRouter = nestedRouteMatch === "/:report/post/:postId" 
  || nestedRouteMatch === '/:report/posts-display'
  ||  nestedRouteMatch === "/:report/posts-group/:groupName" ;
  return (
    <>
      {isHeaderSection && <ReportHeaderSection isTargetPostRouter={isTargetPostRouter} />}
      <Switch>

        <Route path={`${getFullPath("/post/:postId")}`}>
          <PostRouter setNestedRouteMatch={setNestedRouteMatch} />
        </Route>
        <Route path={`${getFullPath("/posts-group/:groupName")}`}>
          <PostRouter setNestedRouteMatch={setNestedRouteMatch} />
        </Route>
        <Route exact path={`${getFullPath("/word-bank")}`} component={WordBank} />
        <Route path={`${getFullPath("/posts-display")}`} component={PostsDisplay} />
        <Route exact path={`${getFullPath("/")}`} component={PostsInput} />
        <Redirect from="*" to={`/${report.name}`} />
      </Switch>
    </>
  );
};

export default ReportRouter;
