import styled from 'styled-components';

export const ModalContainer = styled.div`
    position: fixed;
    width: 500px;
    height: 250px;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -250px;
    background: #F5F6F8;
    box-shadow: 0px 0 17px #999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 33px;
    margin-top: 35px;
`;

export const Input = styled.input`
    width: 85%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 5px;
    height: 45px;
    padding: 14px 1px 7px 10px !important;
    font-size: 25px;
    color: #333B50;

    &::placeholder{
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 100%;
      padding: 5px 0 5px 0 !important;
    }
`;

export const  ModalText = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 110%;
    color: #676978;
    padding-left: 10px;
    margin-top: 5px;
`;

export const ModalButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
    padding: 0 32px 32px 0;

    .cancle-btn{
      margin-right: 25px;
    }
`;
