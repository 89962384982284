import { AlertComment, SINGLE_POST_RESPONSE } from './../store/interface';
import {
  DATE_RANGE,
  PARSED_POST,
  POSTS_COMPARE,
  SINGLE_POST_DETAILED_RESPONSE,
} from '../store/interface';
import axios from './index';
import { WORD_TAGS } from '../store/interface';
import { ACCOUNT_RESPONSE, BRAND_RESPONSE, PARSED_ACCOUNT, PARSED_BRAND } from '../components/ui/sidebar/interface';



export const fetchBrands = async (): Promise<BRAND_RESPONSE[]> => {
  try {
    const response = await axios.get(`/facebook/page-owner`);
    return response.data;
  } catch (error) {
    return {} as BRAND_RESPONSE[];
  }
};

export const fetchAccounts = async (): Promise<ACCOUNT_RESPONSE> => {
  try {
    // const response = await axios.get(`/facebook/page-owner`);
    let response = await axios.get(`/accounts/list`);

    return response.data;
  } catch (error) {
    return {} as ACCOUNT_RESPONSE;
  }
};

export const validateManualyPageId = async (manualyPageId: string) => {
  try {
    const response = await axios.get(`/facebook/validate?page_id=${manualyPageId}`);
    return response.data;
  } catch (error) { }
};

export const fetchPostsBasicInfo = async (postId: string) => {
  const response = await axios.get(`/facebook/single?post=${postId}`);
  return response.data;
};

export const fetchPostsDetails = async (
  posts: PARSED_POST[] | POSTS_COMPARE[],
  word_tags: WORD_TAGS
): Promise<SINGLE_POST_DETAILED_RESPONSE[]> => {
  let postsIds = posts.map(({ id }) => id);

  let postIdAdIdMap: any = [...posts].reduce((acc: any, curr: any) => {
    acc[curr.id] = curr.adId ? { adId: curr.adId, accountId: curr.account } : null;
    return acc
  }, {});

  let api = `/facebook/posts`;

  const response = await axios.post(api, {
    commentOrPostId: postsIds,
    word_tags,
    postIdAdIdMap
  });
  return response.data;
};



export const fetchPostsByBrandsIdsFromApi = async (
  brands: PARSED_BRAND[],
  dateRange: DATE_RANGE
): Promise<{ data: PARSED_POST[] }[]> => {
  const parsedBrands = brands.map((brand) => {
    return { page_id: brand.page_id };
  });
  const response = await axios.post(`/facebook/ids`, {
    brands: parsedBrands,
    from: dateRange.startDate,
    to: dateRange.endDate,
  });
  return response.data;
};


export const fetchPostsByAdAccountsIdsFromApi = async (
  accounts: PARSED_ACCOUNT[],
  dateRange: DATE_RANGE
): Promise<{ data: SINGLE_POST_RESPONSE[] }[]> => {
  let accIds = accounts.map(acc => acc.accountId);
  let accDictionary = accounts.reduce((obj: any, acc) => {
    obj[acc.accountId] = acc.accountName;
    return obj
  }, {});


  let { startDate, endDate } = dateRange;

  //const response = await axiosLib.get(`fb-ads-accounts?account=${accIds.join(',')}&startDate=${startDate}&endDate=${endDate}`);
  const response = await axios.post(`facebook/fb-ads-accounts`, { accIds, startDate, endDate, accDictionary });
  return response.data.map((post: any) => {
    return {
      ...post, ...{
        from: {
          name: accDictionary[post.account],
          id: 'cool'
        }
      }
    }
  });
  //return response.data;
};

export const fetchAllAlerts = async (date: string) => {
  const response = await axios.get(`/alerts/?days=${date}`)
  return response.data;
};

export const fetchAlertsWidgetData = async (date: string) => {
  const response = await axios.get(`/alerts/widgets/?days=${date}`)
  return response.data;
}

export const fetchWordBank = async () => {
  const response = await axios.get(`alerts/word_bank`)
  return response.data
}

export const fetchPostAlerts = async (post_id: string, dateRange: DATE_RANGE) => {
  const response = await axios.get(`alerts/post/${post_id}/?from=${dateRange.startDate}&to=${dateRange.endDate}`)
  return response.data
}

export const fetchVerifyAndHideAlerts = async (actionType: string, Ids: string[]) => {

  if (actionType === 'verify') {
    const response = await axios.put(`alerts/verify/`, {
      "alerts_ids": Ids
    })
    return response.data
  }

  if (actionType === 'hide') {
    const response = await axios.put(`facebook/comment`, {
      "comments_ids": Ids
    })
    return response.data
  }

}

