import { useContext, useEffect, useState } from "react";
import { assets } from "../utils/assets";
import { Icon } from "../utils/CommonStyles";
import { stylesAndAssets, ItemsContainer } from "./Styles";
import { fetchAndSetPostsByAdAccounts, fetchAndSetPostsByBrands } from "../../../store/actions/report-actions";
import { AppContext } from "../../../store/context";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import DatePickerDisplay from "../date-picker/DatePicker";

import { PARSED_ACCOUNTS } from "./interface";
import { fetchAccounts } from "../../../api/facebookApi";

import PopupGrid from "../popup/PopupGrid/PopupGrid";
import { addIsActiveToAccounts, addIsActiveToBrands, getWordbankCount } from "./helper";

import { PARSED_BRAND } from "./interface";
import { fetchBrands, fetchAllAlerts } from "../../../api/facebookApi";



import { CONSTANTS } from "../../../store/constants";
import useInterval from "../../../utils";
import { verifyTokenEffect } from "../../../api/authApi";
import { PostsType } from "./enums";

const {
  MainWrapper,
  Container,
  Header,
  Title,
  TitleDescription,
  InputContainer,
  SignOutContainer,
  Rectangle,
  UserSquare,
} = stylesAndAssets;

const Sidebar = () => {
  const {
    state: { report },
    dispatch,
  } = useContext(AppContext);
  const { navBarCollaped } = report;
  useInterval(async () => {
    const token = JSON.parse(JSON.stringify(localStorage.getItem("token")));
    const email = JSON.parse(JSON.stringify(localStorage.getItem("email")));
    const response = await verifyTokenEffect(email, token);
    if ((response.msg = "Success")) {
      const relevantToken = response.data && response.data?.idToken ? response.data.idToken : token;
      dispatch({
        type: CONSTANTS.Login,
        payload: { email, token: relevantToken },
      });
    }
  }, 10000);
  const [currentPath, setCurrentPath] = useState("");
  const [brands, setBrands] = useState<PARSED_BRAND[]>([]);
  const [accounts, setAccounts] = useState<PARSED_ACCOUNTS[]>([]);
  const [isBrandsSelected, setIsBrandsSelected] = useState(false);
  const [isAccountsSelected, setIsAccountsSelected] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [postsTypsSelection, setPostsTypeSelection] = useState<PostsType>(PostsType.BRANDS);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      if (!report.isInitialized) {
        const brandsResponse = await fetchBrands();

        const accountResponse = await fetchAccounts();
        if (brandsResponse.length > 0) {
          const parsedBrandsResponse = addIsActiveToBrands(brandsResponse);
          setBrands(parsedBrandsResponse);
          // dispatch({
          //   type: CONSTANTS.SetIsInitialzied,
          //   payload: { isInitialized: true },
          // });



          dispatch({
            type: CONSTANTS.SetBrands,
            payload: { brands: parsedBrandsResponse },
          });
        }
        if (Object.keys(accountResponse).length > 0) {
          const parsedAccountResponse: PARSED_ACCOUNTS[] = addIsActiveToAccounts(accountResponse).sort(function (a, b) {
            if (a.accountName < b.accountName) { return -1; }
            if (a.accountName > b.accountName) { return 1; }
            return 0;
          });
          setAccounts(parsedAccountResponse);
          // dispatch({
          //   type: CONSTANTS.SetIsInitialzied,
          //   payload: { isInitialized: true },
          // });
        }

      }
    };

    fetchData();
  }, [report.isInitialized, dispatch]);

  const toggleCollapsed = () => {
    dispatch({
      type: CONSTANTS.SetNavBarCollapsed,
      payload: { navBarCollaped: !navBarCollaped },
    });
  };

  const togglePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const handleFetchPosts = async () => {
    setIsLoading(true);

    if (postsTypsSelection === PostsType.BRANDS) {
      const selecetdBrands = brands.filter(({ isActive }) => isActive);
      await fetchAndSetPostsByBrands(selecetdBrands, report.dateRange!, dispatch);

    } else if (postsTypsSelection === PostsType.AD_ACCOUNTS) {

      //let selecetdAccounts = accounts.filter(({ isActive }) => isActive);
      let selecetdAccounts = accounts.reduce((accum: any, currValue) => {

        if (currValue.accounts.length > 0) {
          accum = [...accum, ...(currValue.accounts.filter(({ isActive }: any) => isActive))];
          return accum;
        } else if (currValue.isActive) {
          accum = [...accum, currValue];
          return accum;
        }
        return accum;
      }, [])
      await fetchAndSetPostsByAdAccounts(selecetdAccounts, report.dateRange!, dispatch);
    }


    setIsLoading(false);
    history.replace("/");
    history.push(`${report.name}`);
  };

  const isPostsFetched = report.posts.length > 0;
  const wordBankCount = getWordbankCount(report.wordBank);
  const shouldDisableCalander = () => {
    return (!isBrandsSelected && !isAccountsSelected) || (postsTypsSelection === PostsType.AD_ACCOUNTS && !isAccountsSelected) || (postsTypsSelection === PostsType.BRANDS && !isBrandsSelected);
  }
  const isFirstStep = report.name !== "" && !isBrandsSelected; //brands
  const isSecondStep = isBrandsSelected && !isPostsFetched; //date range: ;
  const isThirdStep = !shouldDisableCalander() && report.dateRange !== null && report.dateRange.endDate !== null; //fetch data
  const isFourthStep = isBrandsSelected && isPostsFetched && currentPath === `/${report.name}`; //posts input page
  const isFifthStep = currentPath === `/${report.name}/word-bank`; // word bank page
  const isAlarmPage = location.pathname === "/alerts";
  const handleSignOut = () => {
    dispatch({ type: CONSTANTS.Logout, payload: {} });
    dispatch({ type: CONSTANTS.Reset, payload: null });
    history.push("/login");
  };

  let selectionLabel = postsTypsSelection === PostsType.BRANDS ? "Brands" : (postsTypsSelection === PostsType.AD_ACCOUNTS ? "Ad Accounts" : null);
  return (
    <MainWrapper collapsed={navBarCollaped}>
      <Rectangle collapsed={navBarCollaped}>
        <Icon className="minimize pointer" src={assets["minimize"]} onClick={toggleCollapsed} />
        <Icon className="logo" src={assets["logo"]} alt="" />
        {navBarCollaped &&
          <div className="logos">
            <Icon className="logo collapsed" src={assets["CrownWhite"]} alt="" onClick={toggleCollapsed} />
            <Icon className="logo collapsed" src={assets["CalenderWhite"]} alt="" onClick={toggleCollapsed} />
            <Icon className="logo collapsed" src={assets["ShapeStarwhite"]} alt="" onClick={toggleCollapsed} />
            <Icon className="logo collapsed" src={assets["TagCutWhite"]} alt="" onClick={toggleCollapsed} />
            <div className="seperator white" />
            <Link to={`/alerts`}>
              <Icon className="logo background" src={assets["AlarmWhite"]} alt="" />
            </Link>
          </div>
        }
        <UserSquare className="pointer">SR</UserSquare>
      </Rectangle>
      <Container collapsed={navBarCollaped}>
        <Header>
          <Icon className="minimize pointer" src={assets["minimize"]} onClick={toggleCollapsed} />
          <Title>Cat</Title>
          <TitleDescription>By First Media</TitleDescription>
        </Header>
        <div className="seperator" />
        {isPopupOpen && (
          <PopupGrid
            postsTypsSelection={postsTypsSelection}
            setPostsTypeSelection={setPostsTypeSelection}
            setIsPopupOpen={setIsPopupOpen}
            setIsBrandsSelected={setIsBrandsSelected}
            setIsAccountsSelected={setIsAccountsSelected}
            isBrandsSelected={isBrandsSelected}
            isAccountsSelected={isAccountsSelected}
            brands={brands}
            accounts={accounts}
            setBrands={setBrands}

            setAccounts={setAccounts}


          />
        )}

        {!report.isInitialized && isFirstStep ? (
          <img className="loader" src={assets["loader"]} alt="" />
        ) : (
          <InputContainer disabled={!report.name} selected={isFirstStep} onClick={togglePopup}>
            <Icon src={assets["crown"]} alt="" />
            <span>{selectionLabel}</span>
            <Icon className="arrow-right" src={assets["arrowRight"]} alt="" />
          </InputContainer>
        )}

        <InputContainer disabled={shouldDisableCalander()} selected={isSecondStep}>
          <Icon src={assets["calendar"]} alt="" />
          <DatePickerDisplay />
        </InputContainer>

        <Link to={`/${report.name}`} style={{ pointerEvents: !isPostsFetched ? "none" : "auto" }}>
          <InputContainer disabled={!isPostsFetched} selected={isFourthStep}>
            <Icon src={assets["post"]} alt="" />
            <ItemsContainer>
              <span>Post ID</span>
              <span>{isPostsFetched && `(${report.posts.length})`}</span>
            </ItemsContainer>
            <Icon className="arrow-right" src={assets["arrowRight"]} alt="" />
          </InputContainer>
        </Link>

        <Link style={{ pointerEvents: isFifthStep ? "auto" : "none" }} to={`/${report.name}/word-bank`}>
          <InputContainer disabled={!isFifthStep} selected={isFifthStep}>
            <Icon src={assets["tag"]} alt="" />
            <ItemsContainer>
              <span>Word tags</span>
              <span>{wordBankCount > 0 && `(${wordBankCount})`}</span>
            </ItemsContainer>
            <Icon className="arrow-right" src={assets["arrowRight"]} alt="" />
          </InputContainer>
        </Link>
        <div className="seperator black" />
        <Link to={`/alerts`} onClick={() => fetchAllAlerts('30')}>
          <InputContainer selected={isAlarmPage}>
            <Icon className="alert" src={assets["alarm"]} alt="alarm" />
            <ItemsContainer>
              <span>Alerts</span>
            </ItemsContainer>
            <Icon className="arrow-right" src={assets["arrowRight"]} alt="" />
          </InputContainer>
        </Link>

        <InputContainer disabled={!isThirdStep} className="fetch pointer" onClick={handleFetchPosts}>
          {isLoading ? (
            <img className="loader" src={assets["loader"]} alt="" />
          ) : (
            <span className="center">Fetch Data</span>
          )}
        </InputContainer>

        <SignOutContainer className="pointer" onClick={handleSignOut}>
          <span>Sign out</span>
          <Icon className="sign-out" src={assets["signOut"]} />
        </SignOutContainer>
      </Container>
    </MainWrapper>
  );
};

export default Sidebar;
