import styled, { keyframes } from 'styled-components';

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;

  &:checked + div{
    background: #3D85F7 !important;
  }
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0.6em 1em;
  color: #ffff;
  padding-left: 20px;
`;

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;


const Indicator = styled.div`
  width: 1.2em;
  height: 1.2em;
  background: #fbfdff;
  position: absolute;
  top: 3.2em;
  left: -1.5em;
  border: 2px solid #3D85F7;
  border-radius: 0.2em;


  &.label-select-all{
    top: 0.2em;
    border: 2px solid #9b9b9b
  }

  .head-indicator {
    left: 0.5;
  }

  ${Input}:not(:disabled):checked & {
    background: #3D85F7;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + div{
    background: #3D85F7 !important;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 0em;
    left: 0.3em;
    width: 3px;
    height: 11px;
    border: solid #fff;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    /* animation-duration: 0.3s; */
    animation-fill-mode: forwards;
  }

  &::disabled {
    cursor: not-allowed;
  }
  
`;

const SelectAllText = styled.span`
  color: #333B50;
  opacity: 0.6;
`;


export const checkBox = {
  Input,
  rotate,
  Indicator,
  Label,
  SelectAllText
}