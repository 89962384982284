import { POST_PROPS } from './interface';
import { assets } from '../utils/assets';
import { Icon, Sentiment } from '../utils/CommonStyles';
import { postStyles } from './Styles';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../store/context';
import { useCallback, useContext, useEffect, useState } from 'react';
import { CONSTANTS } from '../../../store/constants';
import { getTextByAverageScore, titleCaseWord } from '../utils/helper';
import { parsePostByGroup } from './utils/helper';
import { POSTS_GROUP_AVERAGES } from '../../../store/interface';

const {
  Box,
  PostImg,
  MiddleCol,
  LeftColHeader,
  RightColHeader,
  RightCol,
  Title,
  ReactionsContainer,
  Sentiments,
  PostGroupImg,
  GroupNameContainer
} = postStyles;

const PostBox = ({ index, handlePostDelete, post, src, groupName }: POST_PROPS) => {
  const {
    comments,
    totalComments,
    postDetails: { id, created_time, message, story },
    type,
    picture,
    reactions,
    averageSentimentScore,
  } = post;
  
  const history = useHistory();
  const getPath = useCallback(() => {
    const pathArray = history.location.pathname.split("/");
    const path = pathArray[pathArray.length - 1];
    return path;
  }, [history.location.pathname]);

  const {
    state: { report },
    dispatch,
  } = useContext(AppContext);

  const isGroup = post.postDetails.id === '';
  const { currentPostsGroup, groupExpended } = report;

  const redirect = () => {
    dispatch({ type: CONSTANTS.EmptyCurrentPostsGroup, payload:{} })
    dispatch({ type: CONSTANTS.SetCurrentPost, payload: { post } });
    const pathToRedirect = isGroup ? `/${report.name}/posts-group/${groupName}/reactions` : `/${report.name}/post/${post.postDetails.id}/comments`
    history.push(pathToRedirect);
  };

  const [currentPath, setCurrentPath] = useState(getPath());

  useEffect(() => {
    setCurrentPath(getPath());
  }, [getPath]);

  const toggleExpandGroup = () => {
    dispatch({
      type: CONSTANTS.ToggleGroupExpention,
      payload: {}
    })
    const post = groupExpended ? parsePostByGroup(currentPostsGroup.average as POSTS_GROUP_AVERAGES) : currentPostsGroup.posts[0];
    dispatch({ type: CONSTANTS.SetCurrentPost, payload: { post } });
  } 

  const isAnalyzePage = (str: string) =>{
    const tabs = ['comments', 'reactions', 'frequencies', 'graphs', 'compare', 'alerts'];
    for( let tab of tabs){
      if(str.includes(tab)){
        return true;
      }
    } 

    return  false;
  }

  const [sentimentText, background] = getTextByAverageScore(averageSentimentScore);
  return (
    <>
    <Box onClick={isGroup? ()=>{} : redirect} className={`pointer ${isAnalyzePage(currentPath) ? 'analyze-page': ''} ${isGroup ? 'group' : ''}`} isPostRouter={src === 'post-router'}>
      {isGroup ? [0,1,2].map((_index) => {
        return <div className={`stack-${_index}`} key={_index}></div>
      }) : ''}
     {!isGroup ? 
     <PostImg src={picture} alt="" onClick={isGroup? ()=>{} : redirect} /> : 
     <PostGroupImg>
       <div className="groupShortName">Gr</div>
     </PostGroupImg>
     }
      {isGroup ?  <Icon className='group-arrow' onClick={toggleExpandGroup} src={assets["expendArrow"]} alt="expend arrow" /> : ''}
      <MiddleCol>
        <LeftColHeader>
          <img src={assets[type]} alt="" loading="lazy" />
          {!isGroup ? <span>{`ID ${id}`}</span> : 
          <GroupNameContainer>
            <span className='groupName'>{`${titleCaseWord(currentPostsGroup.groupName)}`}</span><span className='numOfPosts'>{`${currentPostsGroup.posts.length} posts`}</span>
          </GroupNameContainer>
          }
        </LeftColHeader>
        <Title className="ellipsis">{message}</Title>
        <ReactionsContainer className={`${isGroup ? 'group' : ''}`}>
          {isGroup ?
            <div className='average-icon'>
              <Icon src={assets['averageIcon']} />
              <span>Average</span>
            </div>
            : ''
           }
          <div>
            <Icon src={assets['comment']} />
            <span>{isGroup ? totalComments : comments.length}</span>
          </div>
          <div>
            <Icon src={assets['share']} />
            <span>{reactions.shares}</span>
          </div>
          <div>
            <Icon src={assets['heart']} />
            <Icon src={assets['GrayLike']} />
            <span>{reactions.totalReactions}</span>
          </div>
        </ReactionsContainer>
      </MiddleCol>
      <RightCol>
        <RightColHeader>
          {story && (
            <>
              <span className="campaign ellipsis">{story}</span>
              <span className="divider thin">|</span>
            </>
          )}
          <span className="thin">{created_time}</span>
          {src !== 'post-router' && (
            <Icon
              className="pointer"
              src={assets['trash']}
              onClick={(e) => {
                handlePostDelete!(index!, e);
              }}
            />
          )}
        </RightColHeader>
        <Sentiments className={'group'}  background={background}>
          <Sentiment className={`left group`} background={background}>
            <Icon className='sentiment-star' src={assets["star"]} alt="star" /> 
            <span>{+averageSentimentScore.toFixed(5).slice(0, -3)}</span>
          </Sentiment>
          <Sentiment className={`right group`} background={background}>
            <span>{sentimentText}</span>
          </Sentiment>
        </Sentiments>
      </RightCol>
    </Box>
    </>
  );
};

export default PostBox;
