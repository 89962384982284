import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Redirect } from "react-router";
import { AppContext } from "../../../../../store/context";
import { Wrapper, ToolTip, Value } from "./Styles";
import { SINGLE_POST_DETAILED_RESPONSE } from "../../../../../store/interface";
import { BarChart, LabelList, YAxis, Bar, Tooltip, Legend, XAxis } from "recharts";
import { colors, randomizeColorsChart } from "../../../../../utils";


interface reactionsSentiment {
  name: string;
  total: string;
  totalPosts: number;
  positive: number;
  neutral: number;
  negative: number;
}

const BrandBreakDown = ({
  reactionsSentiment,
  isReportPage,
  relatedBreakdown,
}: {
  reactionsSentiment: reactionsSentiment[];
  isReportPage: boolean;
  relatedBreakdown: string;
}) => {
  const {
    state: { report },
  } = useContext(AppContext);
  const { currentPost, currentPostsGroup } = report;


  const [legendData, setLegendData] = useState<any>([]);
  useEffect(() => {
    if (!reactionsSentiment) return;
    const parsedLegendData = reactionsSentiment.map((data, i) => {
      return {
        color: randomizeColorsChart[i % randomizeColorsChart.length],
      };
    });
    setLegendData(parsedLegendData);
  }, [reactionsSentiment]);

  if (!currentPost) {
    return <Redirect to={`/${report.name}`} />;
  }



  const sumTotal = (
    positive: SINGLE_POST_DETAILED_RESPONSE["positiveComments"],
    neutral: SINGLE_POST_DETAILED_RESPONSE["neutralComments"],
    negative: SINGLE_POST_DETAILED_RESPONSE["negativeComments"]
  ) => {
    let total = 0;
    if (+positive) total += +positive;
    if (+neutral) total += +neutral;
    if (+negative) total += +negative;
    return total;
  };

  const getPercentage = (number: number, total: number) => {
    if (!+number) {
      return 0;
    }
    const percentage = ((+number / total) * 100).toFixed(2);
    return +percentage;
  };

  const percentageReactionsSentiment = reactionsSentiment.map((reactionSentiment) => {
    const total = sumTotal(reactionSentiment.positive, reactionSentiment.neutral, reactionSentiment.negative);
    return {
      name: reactionSentiment.name,
      total: total,
      totalPosts: reactionSentiment.totalPosts,
      positive: Math.round(+reactionSentiment.positive) ? Math.round(+reactionSentiment.positive) : 0,
      positivePercentage: getPercentage(reactionSentiment.positive, total),
      neutral: Math.round(+reactionSentiment.neutral) ? Math.round(+reactionSentiment.neutral) : 0,
      neutralPercentage: getPercentage(reactionSentiment.neutral, total),
      negative: Math.round(+reactionSentiment.negative) ? Math.round(+reactionSentiment.negative) : 0,
      negativePercentage: getPercentage(reactionSentiment.negative, total),
    };
  });

  const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload } = props;
    return (
      legendData[payload.index] ?
        <g transform={`translate(${x},${y})`}>
          <rect x="-7" y="0" width="14" height="14" fill={legendData[payload.index].color} />
        </g> :
        <></>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any): any => {
    if (active && payload && payload.length) {
      const {
        name,
        totalPosts,
        positive,
        neutral,
        negative,
        positivePercentage,
        neutralPercentage,
        negativePercentage,
      } = payload[0].payload;

      const negetiveText = "Negative";
      const neutralText = relatedBreakdown === "reactions" ? "Positive" : "Neutral";
      const positiveText = relatedBreakdown === "reactions" ? "Very Positive" : "Positive";

      return (
        <ToolTip>
          <Value style={{ fontWeight: "bold", fontFamily: "Poppins", fontSize: "12px", color: "#2A2F4A" }}>{`${name || currentPostsGroup.groupName}`}</Value>
          <>
            <Value style={{ fontFamily: "Poppins", fontSize: "11px", color: "#333B5099" }}>{`${totalPosts} Posts`}</Value>
            <Value style={{ fontFamily: "Poppins", fontSize: "11px", color: "#ffffff" }}>{`${totalPosts} Posts`}</Value>
            <Value style={{ fontFamily: "Poppins", fontSize: "12px", color: "#2A2F4AE5" }}>
              {`${positiveText}`}
              <span style={{ fontWeight: "bold", padding: "0 7px" }}> {`${positivePercentage}% `}</span>
              {`${positive}`}
            </Value>
            <Value style={{ fontFamily: "Poppins", fontSize: "12px", color: "#2A2F4AE5" }}>
              {`${neutralText}`}
              <span style={{ fontWeight: "bold", padding: "0 7px" }}>{`${neutralPercentage}% `}</span>
              {`${neutral}`}
            </Value>
            <Value style={{ fontFamily: "Poppins", fontSize: "12px", color: "#2A2F4AE5" }}>
              {`${negetiveText}`}
              <span style={{ fontWeight: "bold", padding: "0 7px" }}>{`${negativePercentage}%`}</span>
              {`${negative}`}
            </Value>
          </>
        </ToolTip>
      );
    }

    return null;
  };

  return (
    <Wrapper>
      <>
        <BarChart width={450} height={300} data={percentageReactionsSentiment} barSize={23}>
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <XAxis tick={<CustomizedAxisTick />} tickLine={false} axisLine={{ stroke: '#4D566F', opacity:'0.2' }} />
          <YAxis tick={false} axisLine={false} tickLine={false} />
          <Bar
            stackId={"a"}
            radius={[0, 0, 0, 0]}
            dataKey="negativePercentage"
            fill="#FF6F31"
            isAnimationActive={!isReportPage}
            name="Negative"
          />
          <Bar
            stackId={"a"}
            dataKey="neutralPercentage"
            fill="#FFCA43"
            name="Neutral"
            radius={[0, 0, 0, 0]}
            isAnimationActive={!isReportPage}
          />
          <Bar
            stackId={"a"}
            radius={[5, 5, 0, 0]}
            dataKey="positivePercentage"
            name="Positive"
            fill="#74C34F"
            isAnimationActive={!isReportPage}
          />
        </BarChart>
      </>
    </Wrapper>
  );
};

export default BrandBreakDown;
