export const overAllSentimentData = [
      {
        title: 'Product Mentions',
        mentions: 'products',
        key: 'productsCount'
      },
      {
        title: 'Purchase Intent',
        mentions: 'purchaseIntent',
        key: 'purchaseCount'
      },
      {
        title: 'Brands Mentions',
        mentions: 'competitors',
        key: 'competitorsCount'
      }
]

export interface MentionsData{
  brandName : string,
  total: number,
  isAverage?: number;
}