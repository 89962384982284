import styled from 'styled-components';
import { colors } from '../../../../utils';
import { Input } from '../../utils/CommonStyles';


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  z-index: 2;
  position: absolute;
  top: 176px;
  left: 58px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 400px;
  min-height: 300px;
  overflow: overlay;
`;


export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid rgba(156, 163, 196, 0.3);
  border-radius: 5px;
  padding-left: 14.5px;
  margin: 19px 15px;

  ${Input} {
    height: 100%;
    width: 100%;
  }

  & form {
    display: flex;
    height: 100%;
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
position:absolute;
bottom:0px;

width:100%;
  display: flex;
  border-top: 1px solid rgb(77, 86, 111, 0.2);
  justify-content: space-around;
  margin-top: auto;
  align-items: center;
  padding-right: 18px;
  height: 76px;

  button {
    width: 89px;
    height: 40px;
  }
  a {
    background: none;
    border: 0;
    margin-right: 20px;
    color: ${colors.black};
  }
`;
