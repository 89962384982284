import styled from "styled-components";
import { colors } from "../utils";

export const LayoutContainer = styled.div<{ collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ collapsed }) => (collapsed ? 'calc(100vw - 60px)' : 'calc(100vw - 323px)')};
  background-color: ${colors.layoutBackground};
  padding-bottom: 20px;
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  margin-top: 25px;
  overflow-x: hidden;
`;

export const PostRouterContainer = styled.div`
  margin: 0 0 0 73px;
  height: 100%;
  position: relative;
`;
