import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import Footer from "../Footer";
import { COMMENT, SINGLE_POST_DETAILED_RESPONSE, WORD_TAGS } from "../../../../store/interface";
import Header from "../Header";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(251, 253, 255)",
    fontFamily: "Poppins",
    justifyContent: "space-between",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "30px 20px",
  },
  catImage: {
    height: "25px",
    width: "25px",
    marginRight: "15px",
  },
  catText: {
    fontSize: "30px",
  },
  content: {
    width: "100%",
    fontSize: "28px",
    paddingLeft: "50px",
    marginTop: "30px",
  },
  reportName: {
    fontFamily: "Poppins-Bold",
  },
  date: {
    fontSize: "14px",
  },
  paddingContent: {
    padding: "40px 57px",
  },

});


const Page10 = ({
  reportName,
  currentPost,
  wordBank
}: {
  currentPost: SINGLE_POST_DETAILED_RESPONSE;
  reportName: string;
  wordBank: WORD_TAGS | null;
}) => {

  console.log({ page7: currentPost, wordBank });
  let words = wordBank ? wordBank.purchaseIntent.map(product => {
    let key = Object.keys(currentPost.wordFrequencies).find(word => word === product);
    if (key) {

      return { ...currentPost.wordFrequencies[key], word: product }
    }
    return null;

  }).filter(product => {
    return product?.isActive || product?.comments.some(comment => comment.isActive)
  }) : [];
  words.forEach(word => {
    let temp = word?.comments.filter(comment => comment.isActive)
    if (temp && word) {
      word.comments = temp

    }
  })


  let commentsUx = (comments: COMMENT[] | null, title: string, percents: string, wordOrig: string) => {
    let commentsUx = comments?.map(comment => {
      return (
        <View style={{ border: '1px solid #e6e6e6', marginBottom: 5, borderRadius: 6, display: 'flex', flexDirection: 'row' }}>

          <Text style={{ flex: 1, padding: 6 }}>{comment.message.split(' ').map(word => (<Text style={{ color: (wordOrig.toLowerCase() === word.toLowerCase()) ? 'blue' : 'black' }}  >{word} </Text>))}</Text>


        </View>);
    });

    return (
      <View style={{ marginBottom: 20 }}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>

          <Text style={{ fontWeight: 'bold', fontFamily: 'Poppins-Bold' }}>{wordOrig.charAt(0).toUpperCase() + wordOrig.slice(1)}</Text>
          <Text style={{ marginLeft: 10, fontWeight: 'bold', fontFamily: 'Poppins-Bold' }}>{title}</Text>
          <Text style={{ marginLeft: 10 }}>  {percents}</Text>
        </View>

        {commentsUx}
      </View>
    );

  }

  let content = words.map(wordObj => {
    let totalWordCount = wordObj?.count || 0;
    let percents = Intl.NumberFormat("en-GB", { style: "percent" }).format((totalWordCount / Object.keys(currentPost.wordFrequencies).length));

    return (
      <View break={true} >

        {wordObj ? commentsUx(wordObj?.comments, `${wordObj?.count}  out of ${Object.keys(currentPost.wordFrequencies).length}`, percents, wordObj?.word) : ''}



      </View >
    );
  })



  const MyDoc = (
    <Page size="A4" style={styles.page} orientation="landscape" >
      <View style={{ ...styles.paddingContent, marginBottom: 30 }}>

        <Header reportName={reportName} current_page={10} total_pages={7} page_title={" Purchase Intent"} />
        <View

          style={{
            // ...styles.content,
            fontSize: 10,
            // border: '1px solid red',
            display: 'flex', flexDirection: 'row', width: '100%',
          }}>

          <View style={{ flex: 0.5 }}>
            <Text>Organic Posts</Text>

          </View>
          <View
            fixed={true}
            style={{
              flexWrap: 'wrap',
              flexFlow: 1000,
              // border: '1px solid green',
              flex: 1,

            }}>
            {content}
          </View>


        </View>





      </View>



      <Footer currentPost={currentPost} />
    </Page >
  );

  return MyDoc;
};


export default Page10;


