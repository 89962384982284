import React, { useCallback, useContext } from "react";
import { useTable, Column } from "react-table";
import { CONSTANTS } from "../../../../store/constants";
import { AppContext } from "../../../../store/context";
import { COMMENT } from "../../../../store/interface";
import { COMMENTS_PER_WORD_TABLE } from "./interface";
import { CommentsTableStyled } from "./Styles";

const Table = ({ data, currentWord, setCurrentWord }: COMMENTS_PER_WORD_TABLE) => {
  const { dispatch } = useContext(AppContext);

  const handleCheckBoxForSingleComment = useCallback(
    (currentWord: string, commentId: string, checkboxValue: boolean) => {
      dispatch({
        type: CONSTANTS.SetSingleCommentInWordIsActive,
        payload: { word: currentWord, commentId, isActive: checkboxValue },
      });
    },
    [dispatch]
  );

  const columns: Column<COMMENT>[] = React.useMemo(
    () => [
      {
        Header: <input type="checkbox" className="pointer" />,
        accessor: 'isActive',
        Cell: ({ row }: { row: any }) => {
          const isActive = data.find((comment) => row.original.id === comment.id)?.isActive;
          return (
            <input
              className="pointer"
              type="checkbox"
              checked={isActive}
              style={{
                top: "10px",
              }}
              onChange={(e) => handleCheckBoxForSingleComment(currentWord, row.original.id, e.target.checked)}
            />
          );
        },
      },
      {
        Header: "",
        accessor: "message",
      },
    ],
    [currentWord, handleCheckBoxForSingleComment, data]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<COMMENT>({
    columns,
    data,
  });

  return (
    <CommentsTableStyled {...getTableProps()} onClick={(e) => e.stopPropagation()}>
      <thead onClick={(e) => setCurrentWord("")}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => {
              return <th {...column.getHeaderProps()}>{column.render("Header")}</th>;
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")} </td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </CommentsTableStyled>
  );
};
export default Table;
