import { ComposedChart, LabelList, YAxis, XAxis, Bar, Tooltip, Cell, Legend } from "recharts";
import { colors, randomizeColorsChart } from "../../../../../../utils";
import { ChartWrapper, FlexDiv, ToolTip, Value } from "./Styles";
import { MentionsData } from "../Interface";
import { capitalizeFirstLetter, getMaximumForBarGraph } from "../../utils/helper";
import { useCustomChartRenderers } from "../../utils/useCustomChartRenderers";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../store/context";

const OverAllSentimentGraph = ({
  mentionsData,
  title,
  max,
  avgBrands,
  index,
}: {
  mentionsData: MentionsData[];
  title: string;
  max: number;
  avgBrands: { [key: string]: number };
  index: number;
}) => {
  const {
    state: { report },
  } = useContext(AppContext);
  const { currentPostsGroup, averagedPostCount, groupExpended } = report;
  const { renderCustomLabel, renderCustomAvgTick, renderPostsCountTick, renderAvgStripedBar } = useCustomChartRenderers();
  const [legendData, setLegendData] = useState<any>([]);
  const CustomTooltip = ({ active, payload }: any): any => {
    if (active && payload && payload.length) {
      const isGroup = currentPostsGroup.posts.length && !groupExpended;
      let { brandName, total, isAverage } = payload[0].payload;
      const isCompAvg = brandName === 'Competitive Average';
      if(((!isAverage || isAverage <= 1) && !isCompAvg) && (!isGroup || brandName.length) ) return <></>
      brandName = isGroup && (!isAverage || isAverage <= 1) && !isCompAvg ? capitalizeFirstLetter(currentPostsGroup.groupName): brandName;
      isAverage = isGroup && (!isAverage || isAverage <= 1) && !isCompAvg ? currentPostsGroup.posts.length: isAverage; 
      return (
        <ToolTip>
          <Value className="title bold">{`${brandName}`}</Value>
          <Value className='subtitle'>{`Posts: ${isCompAvg? averagedPostCount : isAverage}`}</Value>
        </ToolTip>
      );
    }
    return null;
  };
  useEffect(() => {
    if (!mentionsData) return;
    const parsedLegendData = mentionsData.map((data, i) => {
      const brandName = !data.brandName.length && currentPostsGroup.posts.length && !groupExpended ? capitalizeFirstLetter(currentPostsGroup.groupName) : data.brandName;
      return {
        value: brandName,
        color: randomizeColorsChart[i % randomizeColorsChart.length],
        type: "square"
      };
    });
    setLegendData(parsedLegendData);
  }, [mentionsData]);

  let maximum = getMaximumForBarGraph(mentionsData, "total", max);

  return (
    <FlexDiv>
      <ChartWrapper>
        <ComposedChart width={350} height={250} data={mentionsData}>
          <Tooltip cursor={false} content={<CustomTooltip />} />
            {/* in order to present the legend only once  */} 
            <Legend
              align="left"
              wrapperStyle={{ 
                maxWidth: 900, 
                fontSize: 12,
                position: "absolute", 
                bottom: -90, 
                left: "50px", 
                width:"70vw", 
                visibility: index === 0 ? "visible" : 'hidden',
              }}
              payload={legendData}
            />
          <XAxis dataKey="total" tick={(tickProps) => renderCustomAvgTick(tickProps, avgBrands, mentionsData)} xAxisId={0} tickLine={false} axisLine={{ stroke: '#4D566F', opacity:'0.2' }}/>
          <XAxis dataKey="total" tick={(tickProps) => renderPostsCountTick(tickProps, avgBrands, mentionsData)} tickLine={false} xAxisId={1} axisLine={{ strokeWidth: 0 }}/>
          <YAxis tick={false} axisLine={false} domain={[0, maximum + maximum / 10]} />
          <Bar isAnimationActive={false} alignmentBaseline="middle" dataKey="total" barSize={24} shape={(shapeProps) => renderAvgStripedBar(shapeProps, 'SentimentsGraphes', avgBrands)}>
            <LabelList offset={10} dataKey="total" position="top" content={renderCustomLabel}/>
            {mentionsData &&
              mentionsData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={randomizeColorsChart[index % randomizeColorsChart.length]}
                />
              ))}
          </Bar>
        </ComposedChart>
        <p style={{ color: colors.darkBlue, marginLeft: '13%', fontSize: 18, fontWeight:'bold' }}> {title} </p>
      </ChartWrapper>
    </FlexDiv>
  );
};

export default OverAllSentimentGraph;
