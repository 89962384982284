import { useHistory } from 'react-router';
import { Tabs, Tab } from './Styles';
import { tabs } from './tabsData';

interface FREQUENCIES_TABS_PROPS {
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  currentTab: string;
}

const WordFrequenicesTabs = ({ setCurrentTab, currentTab }: FREQUENCIES_TABS_PROPS) => {
  const history = useHistory();
  const { pathname } = history.location;

  const handleOnClick = (type: string) => {
    setCurrentTab(type);
    history.push(`${pathname}`);
  };

  return (
    <Tabs>
      {tabs.map(({ title, type }, i) => (
        <Tab
          className="pointer"
          isSelected={currentTab === type}
          onClick={(e) => handleOnClick(type)}
          key={i}
        >
          <span>{title}</span>
        </Tab>
      ))}
    </Tabs>
  );
};

export default WordFrequenicesTabs;
