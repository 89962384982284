import styled from "styled-components";
import { colors } from "../../../../utils";
import { CATEGORIES } from "../helper";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 120px;
  border-radius: 14px;
  width: 100%;
  margin:5px;
  /* box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.05); */

  .top-words-wrapper{
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }
`;

export const WrapperTotal = styled.div`
  box-shadow: 0px 2px 14px rgb(0 0 0 / 5%), 0px 0px 2px rgb(0 0 0 / 5%);
  display: flex;
  flex-direction: column;
  min-height: 120px;
  height: 100%;
  border-radius: 14px;
  max-width: 250px;
  min-width: 200px;
  margin:5px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.05);

  .Dropdown-root{
    margin:10px 13px 0px -4px;
    &.alerts-dates{
      .Dropdown-control{
        box-shadow: unset;
      }
    }
    .Dropdown-control{
      box-shadow: 0px 0px 12px rgb(0 0 0 / 10%);
      border-radius: 6px;
      border:0px;
      font-size: 14px;
      height: 35px;
      .Dropdown-placeholder{
        padding-left: 20px;
      }
    
  }
  .Dropdown-menu{
      border:0px;
      font-size: 14px;
    }
  }
`;

export const Icon = styled.img`
    position: relative;
    top: 39px;
    z-index: 2;
    right: 60px;
`

export const PaddingWrapper = styled.div`
  padding: 20px 0 0 20px;
  background-color: ${colors.white};
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  box-shadow: 0px 2px 14px rgb(0 0 0 / 5%), 0px 0px 2px rgb(0 0 0 / 5%);

  .precentage-num{
    padding-left: 4px;
  }

  .total-amount-text{
    margin: 9px 0 15px 0;
    font-size: 12px;
  }
`;

export const PaddingWrapperTotal = styled.div`
  padding: 20px 0 0 20px;
  position: relative;
  height: inherit;
  background-color: ${colors.white};
  border-radius: 14px;
  background:linear-gradient(180deg, #FFFFFF 43.02%, rgba(255, 253, 252, 0.95) 77.29%, #FF5A26 149.13%);

  .date-chart{
      position: absolute !important;
      left: 10px;
  }
`;

export const TotalAlerts = styled.div`
  font-size: 26px;
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
export const AlertIcon = styled.img`
  height: 16px;
  margin-right: 13px;
`;

export const FreqWord = styled.div<{ category: string }>`
  display: flex;
  width: 90%;
  margin: 0 auto;
  border-radius: 14px;
  box-shadow: 0 2 14 0;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  height: fit-content;
  padding: 5px 3px;
  margin-top: 7px;
  font-weight: bold;
  color: ${colors.black};
  /* color: ${({ category }) => (category === CATEGORIES.dangerous ? colors.dangerousCategory : "")};
  color: ${({ category }) => (category === CATEGORIES.offensive ? colors.offensiveCategory : "")};
  color: ${({ category }) => (category === CATEGORIES.other ? colors.otherCategory : "")};
  color: ${({ category }) => (category === CATEGORIES.contactRequests ? colors.supportCategory : "")}; */
`;

export const BottomLine = styled.div<{ category: string }>`
  border-bottom: ${({ category }) =>
    category === CATEGORIES.dangerous ? `2px solid ${colors.dangerousCategory}` : ""};
  border-bottom: ${({ category }) =>
    category === CATEGORIES.offensive ? `2px solid ${colors.offensiveCategory}` : ""};
  border-bottom: ${({ category }) =>
    category === CATEGORIES.other ? `2px solid ${colors.otherCategory}` : ""};
  border-bottom: ${({ category }) =>
    category === CATEGORIES.contactRequests ? `2px solid ${colors.supportCategory}` : ""};
  width: 90%;
  margin: 0 auto;
  margin-bottom: 8px;
`;
