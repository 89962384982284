import { assets } from "../../../../ui/utils/assets";
import { AlertIcon, Category, Wrapper, PaddingWrapper } from "./Styles";

export const AlertHighlights = ({
  alert,
}: {
  alert: { 
    iconName:string,
    id:number,
    count:number
   };
  totalAlerts: number;
}) => {
  return (
    <>
      <Wrapper>
        <PaddingWrapper>
          <Category>
            <div>
            <AlertIcon src={assets[alert.iconName]} alt={alert.iconName} />
            <span style={{ fontSize: 14 }}>{alert.iconName}</span>
            </div>
            <div>
            <span>{alert.count}</span>
            </div>
          </Category>
        </PaddingWrapper>
      </Wrapper>
    </>
  );
};
