//Action Persuasion
export const actionWordsBank = [
  'want',
  'need',
  'try',
  'lets',
  'make',
  'idea',
  'ideas',
  'perfect',
  'this',
  'weekend',
  'saturday',
  'sunday',
  'sat',
  'sun',
  'tomorrow',
  'week',
  'next',
  'should',
];

//Concern
export const concernWordsBank = [
  'safe',
  'unsafe',
  'danger',
  'dangerous',
  'toxic',
  'racist',
  'sexist',
  'unfair',
  'worry',
  'worried',
  'terrible',
  'trust',
  'untrustworthy',
  'distrust',
  'doubt',
  'alarm',
  'alarmed',
  'sure',
  'unreliable',
  'fatal',
  'deadly',
  'kill',
  'kills',
  'killed',
  'unhealthy',
  'terrible',
  'serious',
  'unstable',
  'offend',
  'offended',
  'shock',
  'shocked',
  'unsure',
  'semitic',
  'bigot',
  'hazard',
  'choking',
  'liberal',
  'nazi',
  'neonazi',
  'neo',
  'wing',
  'winged',
  'propaganda',
  'intolerant',
  'xenophobic',
  'bigoted',
  'discriminate',
  'discriminated',
  'intolerant',
  'discriminating',
  'fire',
  'flamable',
  'flammable',
  'plastic',
  'environment',
  'harm',
  'harms',
  'hurt',
  'hurts',
  'injure',
  'fume',
  'fumes',
  'pollution',
  'polute',
  'pollute',
  'polution',
  'planet',
  'earth',
];

//Purchase Intent
export const purchaseWordsBank = ['buy', 'bought', 'lets', 'should', 'get', 'order', 'store', 'purse'];

export const hardCodedWordsLength =
  actionWordsBank.length + concernWordsBank.length + purchaseWordsBank.length;
