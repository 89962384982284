import styled, {keyframes} from "styled-components";
import { colors } from "../../../../utils";
export const HeaderTitle = styled.h2``;

export const Wrapper = styled.div`
  margin-right: 110px;
  margin-left: -7px;
`;

export const AlertsHighlightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 230px;
`;

export const CommentsActions = styled.div`
    position: relative;
    top: 20px;
    margin-top: 20px;
    margin-bottom: 6px;
    display: inline-flex;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    p {
      font-size: 13px;
    }
`

export const Icon = styled.img`
    margin-left: 12px;
    margin-right: 12px;
    margin-top: -7px;
`

export const IconEye = styled.img`
    height: 20px;
    margin-left: 10px;
    margin-right: 9px;
    margin-top: -7px;
`

export const Button = styled.div`
  cursor: pointer;
`

export const PopUpWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 95%;
  .yes-hide {
    height: 105px;
  }
`
export const PopUpBox = styled.div`
  background: ${colors.darkBlue};
  margin:0 auto;
  margin-bottom: 15px;
  height: 50px;
  width: 426px;
  border-radius: 9px;
  .yes-popup-inner {
    display: block;
  }
`

export const PopUpInner = styled.div`
  font-size: 13px;
  color: ${colors.white};
  display: inline-flex;
  justify-content: space-between;
  margin: 15px 20px;
  width: 90%;
  .yes-hide-wrapper {
    float: right;
    margin-right: -4px;
  }
`

export const Actions = styled.div`
  display: flex;
`

export const Cancel = styled.div`
  cursor: pointer;
`

export const YesVerify = styled.div`
  color: ${colors.verifyAlertGreen};
  border-left: 1px solid ${colors.greyLight};
  margin-left: 15px;
  cursor: pointer;
  .icon-img {
     margin-left: 11px;
     margin-right: 5px;
     margin-top: -2px;
  }
`

export const YesHide = styled.div`
  color: ${colors.yesAlertHide};
  border-left: 1px solid ${colors.greyLight};
  margin-left: 15px;
  cursor: pointer;
  font-weight: bold;
  font-size:14px;
  .icon-img {
     margin-left: 11px;
     margin-right: 5px;
     margin-top: -2px;
  }
`

export const HideActionWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 110%;
  position: relative;
  left: -18px;
  padding: 5px 15px 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${colors.greyLight};
`

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

export const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;

  &:checked + div {
    background: #3d85f7 !important;
  }
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0px 17px 0px 7px;
  color: #ffff;
  padding-left: 0px;
`;

export const Indicator = styled.div`
  width: 18px;
  height: 18px;
  background: ${colors.darkBlue};
  position: absolute;
  top: 0.2px;
  left: -13px;
  border: 2px solid ${colors.white};
  border-radius: 0.2em;

  .head-indicator {
    left: 0.5;
  }

  ${Input}:not(:disabled):checked & {
    background: #3d85f7;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + div {
    background: #3d85f7 !important;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 1px;
    left: 0.3em;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    animation-fill-mode: forwards;
  }

  &::disabled {
    cursor: not-allowed;
  }
`;

export const AlertsWordWrapper = styled.div`
    display: flex;
    background: white;
    box-shadow: 0px 2px 14px rgb(0 0 0 / 5%), 0px 0px 2px rgb(0 0 0 / 5%);
    border-radius: 6px;
   
    padding:10px 22px;
    .word-content {
      display: flex;
      flex-wrap: wrap;
      margin-left: 55px;
      margin-top: 15px;
    }
`
export const Title = styled.div`
  .total-alert {
    font-weight: bold;
    font-size: 26px;
  }
`

export const SearchWrapper = styled.div`
  margin-left: auto;
  position: absolute;
  top: -84px;
  left: 450px;
  input{
    width: 260px;
    padding: 6px;
    border-radius: 5px;
    border: 1px solid ${colors.greyLight};
    background: ${colors.layoutBackground};
  }
`
export const IconSearch = styled.img`
    position: relative;
    top: 1px;
    right: 28px;
    cursor: pointer;
`