import { PostsDetailsAverageByBrand, POSTS_GROUP_AVERAGES, SINGLE_POST_DETAILED_RESPONSE } from "../../../../../store/interface";
import { calcGroupAverages } from "../utils/helper";

export const getEmptyAveragesObject = () : POSTS_GROUP_AVERAGES =>{
    return {
        totalComments: 0,
        totalCommentsWithNameTags: 0,
        totalCommentsWithReplies: 0,
        positiveComments: 0,
        negativeComments: 0,
        neutralComments:0,
        reactions: {
          angry: 0,
          care: 0,
          haha: 0,
          like: 0,
          love: 0,
          pride: 0,
          sad: 0,
          shares: 0,
          thankful: 0,
          totalReactions: 0,
          wow: 0,
        },
        averageSentimentScore: 0,
        reactionsSentiments: {
          negativeReactions: 0,
          positiveReactions: 0,
          veryPositiveReactions: 0
        },
        wordFrequencies: {},
        mentionsAndIntent: {
          productsCount: 0,
          purchaseCount: 0,
          competitorsCount: 0
        }
    }
}

export const getAveragesDictionery = (postsList: SINGLE_POST_DETAILED_RESPONSE[]) : PostsDetailsAverageByBrand =>{
    let averages : PostsDetailsAverageByBrand = {};
    postsList.forEach((post)=>{
      const brandName = post.brand.name;
      let newAverages : PostsDetailsAverageByBrand ={}
      
      if(averages[`${brandName}`]){
        // סכימה לאובייקט ממוצעים
        newAverages = { [brandName] : averages[brandName]}

       newAverages[brandName].averages = calcGroupAverages([newAverages[brandName].averages, post])
       newAverages[brandName].postsCount++;
       averages = {...averages,...{ [brandName] : newAverages[brandName]}}
       
      } 
      else {
        const currAverages = { [brandName] : { postsCount: 1, averages: calcGroupAverages([post])}};
        averages = { ...averages, ...currAverages};

      }
    })

    return averages
  }