import React from "react";
import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import { assets } from "../../ui/utils/assets";

const Header = ({
  page_title,
  current_page,
  total_pages,
  reportName,
}: {
  current_page: number;
  total_pages: number;
  page_title: string;
  reportName: string;
}) => {
  const styles = StyleSheet.create({
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      width: "100%",
    },
    reportTitle: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      fontSize: "20px",
      fontFamily: "Poppins",
    },
    pageTitle: {
      fontFamily: "Poppins-Bold",
      marginTop: "8px",
    },
    pageNumbers: {
      color: "#333B50",
      fontSize: "10px",
      display: "flex",
      flexDirection: "row",
      marginTop: "4px",
      height: "20px",
      alignItems: "center",
    },
    catImage: {
      height: "26px",
      width: "26px",
      marginLeft: "10px",
    },
  });
  return (
    <View style={styles.wrapper}>
      <View style={styles.reportTitle}>
        <Text>{reportName || "Report Name Here"}</Text>
        <Text style={styles.pageTitle}>{page_title} </Text>
      </View>
      <View style={styles.pageNumbers}>
        <Text>
          Page {current_page}/12
        </Text>
        <Image style={styles.catImage} src={assets["catReport"]} />
      </View>
    </View>
  );
};

export default Header;
