import styled from "styled-components";
import { colors } from "../../../../../utils";
import { CATEGORIES } from "../../../../pages/alerts/helper";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 65px;
  border-radius: 14px;
  background: ${colors.white};
  width: 100%;
  margin:5px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.05);
`;

export const WrapperTotal = styled.div`
  display: flex;
  flex-direction: column;
  height: 120px;
  border-radius: 14px;
  width: 100%;
  margin:5px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.05);

  .Dropdown-root{
    margin:10px 13px 0px -4px;
    .Dropdown-control{
    box-shadow: 0px 0px 12px rgb(0 0 0 / 10%);
    border-radius: 6px;
    border:0px;
    font-size: 14px;
    height: 35px;
    .Dropdown-placeholder{
      padding-left: 20px;
    }
    
  }
  .Dropdown-menu{
      border:0px;
      font-size: 14px;
    }
  }
`;

export const Icon = styled.img`
    position: relative;
    top: 39px;
    z-index: 2;
    right: 60px;
`

export const PaddingWrapper = styled.div`
  padding: 21px 0 0 20px;
  background-color: ${colors.white};
  border-radius: 14px;
`;

export const PaddingWrapperTotal = styled.div`
  padding: 20px 0 0 20px;
  background-color: ${colors.white};
  border-radius: 14px;
  background:linear-gradient(180deg, #FFFFFF 43.02%, rgba(255, 253, 252, 0.95) 77.29%, #FF5A26 149.13%);
`;

export const TotalAlerts = styled.div`
  font-size: 26px;
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  padding-right: 25px;
`;
export const AlertIcon = styled.img`
  height: 16px;
  margin-right: 13px;
`;

export const FreqWord = styled.div<{ category: string }>`
  display: flex;
  width: fit-content;
  margin: 0 auto;
  border-radius: 14px;
  box-shadow: 0 2 14 0;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  height: 35px;
  text-align: center;
  margin: 1px 12px 10px 7px;
  padding: 5px 10px;
  border: ${({ category }) =>
    category === CATEGORIES.dangerous ? `2px solid ${colors.dangerousCategory}` : ""};
  border: ${({ category }) =>
    category === CATEGORIES.offensive ? `2px solid ${colors.offensiveCategory}` : ""};
  border: ${({ category }) =>
    category === CATEGORIES.other ? `2px solid ${colors.otherCategory}` : ""};
  border: ${({ category }) =>
    category === CATEGORIES.contactRequests ? `2px solid ${colors.supportCategory}` : ""};
`;

export const BottomLine = styled.div<{ category: string }>`
  border-bottom: ${({ category }) =>
    category === CATEGORIES.dangerous ? `2px solid ${colors.dangerousCategory}` : ""};
  border-bottom: ${({ category }) =>
    category === CATEGORIES.offensive ? `2px solid ${colors.offensiveCategory}` : ""};
  border-bottom: ${({ category }) =>
    category === CATEGORIES.other ? `2px solid ${colors.otherCategory}` : ""};
  border-bottom: ${({ category }) =>
    category === CATEGORIES.contactRequests ? `2px solid ${colors.supportCategory}` : ""};
  width: 90%;
  margin: 0 auto;
  margin-bottom: 8px;
`;
