import styled from 'styled-components';
import { colors } from '../../../../../utils';

export const Wrapper = styled.div<{ isReportPage: boolean }>`
  display: flex;
  align-items: center;
  height: 63px;
  margin: 0 0 7px 0;
  background-color: ${colors.white};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0 18px 0 26px;
  height: 116px;
  width: ${({ isReportPage }) => isReportPage && '650px'};
  svg {
    margin-top: 15px;
  }

  img {
    max-width: 55px;
    max-height: 55px;
    margin-right: 16px;
  }
`;

export const Container = styled.div`
  display: flex;
  height:auto;
  flex-direction: column;
`;
