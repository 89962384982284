import styled from "styled-components";
import { colors } from "../../../../utils";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(77, 86, 111, 0.2);
  margin: 0 147px 0 73px;
  height: 111px;

  span {
    margin-top: 4px;
    font-size: 13px;
    color: ${colors.titleDescription};
  }
`;