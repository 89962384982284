import { useContext } from 'react';
import { AppContext } from '../../../../store/context';
import CommentsSentiment from './commentsSentiment/CommentsSentiment';
import UserReplies from './userReplies/UserReplies';
import UserTags from './userTags/UserTags';
import { Column, CommentsSentimentWrapper, Wrapper, Container } from './Styles';
import { SINGLE_POST_DETAILED_RESPONSE } from '../../../../store/interface';

export const UserTagsDisplay = ({
  currentPost,
  isReportPage,
}: {
  currentPost: SINGLE_POST_DETAILED_RESPONSE;
  isReportPage: boolean;
}) => (
  <Column style={{ marginRight: '9px' }} id={'user-tags'}>
    <UserTags
      isReportPage={isReportPage}
      totalCommentsWithNameTags={currentPost!.totalCommentsWithNameTags}
      totalComments={currentPost!.totalComments}
    />
  </Column>
);

export const UserRepliesDisplay = ({
  currentPost,
  isReportPage,
}: {
  currentPost: SINGLE_POST_DETAILED_RESPONSE;
  isReportPage: boolean;
}) => (
  <Column id={'user-replies'}>
    <UserReplies
      isReportPage={isReportPage}
      totalCommentsWithReplies={currentPost!.totalCommentsWithReplies}
      totalComments={currentPost!.totalComments}
    />
  </Column>
);

export const CommentsSentimentDisplay = ({
  currentPost,
  isReportPage,
}: {
  currentPost: SINGLE_POST_DETAILED_RESPONSE;
  isReportPage: boolean;
}) => (
  <CommentsSentimentWrapper id={'comments-sentiment'}>
    <CommentsSentiment
      positiveComments={currentPost!.positiveComments}
      negativeComments={currentPost!.negativeComments}
      neutralComments={currentPost!.neutralComments}
      isReportPage={isReportPage}
    />
  </CommentsSentimentWrapper>
);

const Graphs = () => {
  const {
    state: {
      report: { currentPost },
    },
  } = useContext(AppContext);

  return (
    <Wrapper>
      <CommentsSentimentDisplay currentPost={currentPost!} isReportPage={false} />
      <Container>
        <UserTagsDisplay currentPost={currentPost!} isReportPage={false} />
        <UserRepliesDisplay currentPost={currentPost!} isReportPage={false} />
      </Container>
    </Wrapper>
  );
};

export default Graphs;
