import React from "react";
import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import { assets } from "../../ui/utils/assets";
import { SINGLE_POST_DETAILED_RESPONSE } from "../../../store/interface";
const Footer = ({ currentPost }: { currentPost: SINGLE_POST_DETAILED_RESPONSE }) => {
  const styles = StyleSheet.create({
    footer: {
      borderTop: "0.2px solid #4D566F",
      height: "100px",
      backgroundColor: "white",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: "20px",
      fontSize: "8px",
    },
    idText: {
      marginLeft: "6px",
    },
    firstMedia: {
      fontSize: "12px",
      marginLeft: "450px",
    },
  });
  return (
    <View style={styles.footer} fixed>
      <Image style={{ width: "14px", height: "14px" }} src={assets["facebookPNG"]}></Image>
      <Text style={styles.idText}>
        ID {currentPost!.postDetails.message} | {currentPost!.totalComments} Comments
      </Text>
      <Text style={styles.firstMedia}>By First Media</Text>
    </View>
  );
};

export default Footer;
