import styled from "styled-components";
import { colors } from "../../../../../utils";
export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: ${colors.white};
  padding: 20px 0 0 16px;
  min-height: 381px;
`;
