import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect, Route } from "react-router-dom";
import LayoutRouter from "./routes/LayoutRouter";
import Sidebar from "./components/ui/sidebar/Sidebar";
import { Container } from "./utils";
import Login from "./components/pages/login/Login";
import useVerifyTokenEffect from "./components/pages/login/UseVerifyToken";

function App() {
  const isLoggedIn = useVerifyTokenEffect();
  return (
    <Container>
      {isLoggedIn && (
        <>
          <Sidebar />
          <LayoutRouter />
        </>
      )}

      <Route exact path="/login" component={Login} />
      <Redirect from="*" to="/" />
    </Container>
  );
}

export default App;
