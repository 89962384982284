import styled from 'styled-components';
import { colors } from '../../../utils';
import { PrimaryButton } from '../utils/CommonStyles';

interface ContainerProps {
  isTargetPostRouter: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: ${({ isTargetPostRouter }) => !isTargetPostRouter && '1px solid rgb(77, 86, 111, 0.2)'};
  margin: ${({ isTargetPostRouter }) => (isTargetPostRouter ? '0 147px 0 73px' : '0 147px 20px 73px')};

  @media screen and (max-width: 1250px) {
    margin: ${({ isTargetPostRouter }) => (isTargetPostRouter ? '0 50px 0px 73px' : '0 50px 25px 73px')};

    ${PrimaryButton} {
      width: 150px;
    }
  }
`;

export const Description = styled.span`
  font-size: 0.8125rem;
  margin-top: 3px;
  margin: 3px 0 0 19.5px;
  color: ${colors.titleDescription};
`;

export const GroupsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 92px;
`;

export const LeftGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const RightGroup = styled.div`
  display: flex;
  margin-top: 8px;

  ${PrimaryButton} {
    position: relative;
  }
`;

export const GroupButtonsContainer = styled.div`
  display:flex;
  align-items: self-end;
  padding-bottom: 10px;

`;

export const TrashButton = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  width: 80px;
  justify-content: space-around;
  background-color: transparent;
  border: none;
  margin-right: 22px;
  cursor: pointer;
`;

export const ModalOverlay = styled.div`
  position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
`;
