import styled from "styled-components";
import { colors } from "../../../utils";

export const PrimaryButton = styled.button<{ disabled?: boolean }>`
  width: 155px;
  height: 32px;
  background: ${colors.blue};
  border-radius: 5px;
  color: ${colors.primaryButton};
  font-size: 0.8125rem;
  border: none;
  opacity: ${({ disabled }) => disabled && 0.4};
  cursor: ${({ disabled }) => !disabled && "pointer"};
  & :focus {
    border: 0;
    outline: none;
  }

  .report-icon {
    position: absolute;
    top: 9px;
    left: 10px;
  }

  &.update-data {
    min-width: 129px;
    min-height: 40px;
    align-self: flex-end;
    margin-top: 15px;
  }

  &.create-group-modal {
    padding: 5px 8px;
    width: fit-content;
    height: 40px;
  }
`;

export const Icon = styled.img<{ shouldTransform?: boolean; hover?: boolean }>`
  &.dropdown {
    ${({ shouldTransform }) =>
      shouldTransform &&
      `-webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);`}
  }
  :hover {
    cursor: ${({ hover }) => hover && "pointer"};
  }
  &.group-arrow {
    position: absolute;
    right: -15px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    text-align: center;
    cursor: pointer;

    &.expended {
      z-index: 999;
      top: 63px;
      left: 86%;
      right: unset;
      bottom: unset;
      transform: scaleX(-1);
    }
  }
`;

export const Input = styled.input`
  background-color: inherit;
  border: 0;
  &:focus {
    outline: none;
  }
`;

export const InputCompare = styled.input`
  background-color: inherit;
  border: 0;
  width: 267px;
  height: 35;
  color: ${colors.white};
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-weight: 600;
    font-size: 14px;

    color: ${colors.white};
  }
`;

export const ReportInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 40px;
  width: 50%;

  ${Input} {
    height: 100%;
    width: 100%;
    font-weight: 600;
    font-size: 25px;
    color: ${colors.darkGrey};
    padding-left: 6px;
    border-radius: 5px;
    text-transform: capitalize;

    &:focus {
      border: 1px solid rgba(43, 48, 74, 0.3);
    }

    &::placeholder {
      font-weight: 600;
      font-size: 25px;
      color: ${colors.darkGrey};
    }
  }
`;

export const WrapperWhite = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: overlay;
  margin: 0 29px 30px 42px;
  background-color: ${colors.white};
  height: 100%;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 24px 28px 39px 27px;
`;

export const WrapperWhiteHeader = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(77, 86, 111, 0.2);

  .title {
    font-size: 1.25rem;
    color: ${colors.darkGrey};
    margin-bottom: 38px;
  }

  .remove {
    margin-bottom: auto;
  }
`;

export const GreenBox = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: ${colors.greenLight};
  width: 39px;
  height: 100%;
  &:hover {
    background-color: ${colors.green};
  }
  .add {
    width: 14px;
  }
`;

export const Sentiment = styled.div<{ background?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 3px;
  background: ${({ background }) => background};
  border-radius: 4px;
  font-size: 0.8125rem;
  color: ${colors.white};
  height: 30px;
  box-sizing: border-box;
  padding: 6px;
  font-size: 17px;

  .star-img-wrapper{
    margin: auto 5px;
    display: flex;
  }


  &.single {
    height: fit-content;
    padding: 3px 10px;

    .sentiment-star {
      width: 16px;
      margin: 0 4px 4px 0;
    }

    span {
      margin: auto;
    }
  }

  &.left {
    width: fit-content;
    font-size: 13px;

    span {
      margin-top: 2px;
      margin-right: 4px;
    }
  }

  &.right {
    width: 100px;
    font-size: 13px;
    &.pointer{
      font-size:13px; 
    }
  }

  &.group {
    height: 24px;

    &.left {
      border-right: 1px solid;
      border-radius: 0;
      position: relative;
      display: flex;

      .sentiment-star {
        width: 16px;
        margin-right: 5px;
      }

      span {
        margin-left: 5px;
      }
    }

    &.right {
      span {
        margin-right: 30px;
      }
    }
  }
`;
