import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 700px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 434px;
  width: 90%;
`;

export const ContainerReactions = styled.div`
  display: flex;
  justify-content: space-between;
  height: 434px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 90%;
`;
