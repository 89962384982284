import styled from "styled-components";
import { useEffect, useRef } from "react";

export const reactionsFill: { [key: string]: { fill: string; emoji: string } } = {
  care: {
    fill: "#2C97F9",
    emoji: "🤗",
  },
  love: {
    fill: "#A9D4FC",
    emoji: "❤️",
  },
  haha: {
    fill: "#FFBE3F",
    emoji: "😂",
  },
  wow: {
    fill: "#60C57C",
    emoji: "😯",
  },
  sad: {
    fill: "#FF783F",
    emoji: "😢 ",
  },
  angry: {
    fill: "#F14F2B",
    emoji: "😡",
  },
};

export const colors = {
  white: "#ffffff",
  black: "#000000",
  grey: "#4D566F",
  greyLight: "#ccccce",
  blue: "rgb(52, 115, 226)",
  lightBlue: "#D7E6FF",
  darkBlue: "#2A2F4A",
  darkGrey: "#333B50",
  darkGreyOpacity:'rgba(51,59,80,0.62)',
  tabTitle: "#3d85f7",
  titleDescription: "#676978",
  primaryButton: "#F5F6F8",
  rectangle: " #2B304A;",
  sidebarBackground: "#EDEDED",
  layoutBackground: "#fbfdff",
  yellow: "#FFCA43",
  greenLight: "#60C57C",
  positiveLight: "#74C44F",
  negativeLight: "#FF6F31",
  green: "#39bf5e",
  negative: "#FF783F",
  charts: "#8884d8",
  chartGreen: "#2E9C67",
  chartGray: "#75808F",
  compareChart: "#bbbec0",
  dangerousCategory: "#FF5A26",
  offensiveCategory: "#FF9D43",
  otherCategory: "#FFD12E",
  supportCategory: "#BB8BF9",
  verifyAlertGreen:'#2E9C67',
  yesAlertHide: '#E86D46'
};

export const colorsCharts = {
  titleDescription: '#676978',
  primaryButton: '#67B6FF',
  yellow: '#FFE37E',
  greenLight: '#FFB571',
  green: '#C093FA',
  negative: '#FF783F',
  charts: "#62B7B2",
  chartGreen: "#CCEBF9",
  chartGray: "#74C34F",
};

export const commentAdReactiosSensetivityColors = {
  negative: '#FF6F31',
  netural: '#B9DBEB',
  positive: '#67B6FF',
  veryPositive: '#74C34F'



};

export const randomizeColorsChart = [
  "#2E9C67",
  "#3d85f7",
  "#C093FA",
  "#ffbe3f",
  "#E86D46",
  "#62B7B2",
  "#67B6FF",
  "#74C344",
  "#8884d8",
  "#CCEBF9",
  "#FFE37E",
  "#FFB571",
  "#2E9C67",
  "#3d85f7",
  "#C093FA",
  "#ffbe3f",
  "#E86D46",
  "#62B7B2",
  "#67B6FF",
  "#74C344",
  "#8884d8",
  "#CCEBF9",
  "#FFE37E",
  "#FFB571",
];

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;
