import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';
import { SINGLE_POST_DETAILED_RESPONSE } from '../../../../../store/interface';
import { Total } from '../commentsSentiment/Styles';
import { Wrapper } from './Styles';

const UserTags = ({
  totalCommentsWithNameTags,
  totalComments,
  isReportPage,
}: {
  totalCommentsWithNameTags: SINGLE_POST_DETAILED_RESPONSE['totalCommentsWithNameTags'];
  totalComments: number;
  isReportPage: boolean;
}) => {
  const totalCommentsWithoutNameTags = totalComments - totalCommentsWithNameTags;
  const userTagsDataForGraph = [
    { amount: totalCommentsWithNameTags, name: 'Comments with name tags' },
    { amount: totalCommentsWithoutNameTags, name: 'Comments without name tags' },
  ];
  const COLORS = ['#2C97F9', '#DDE0E6'];
  const precentage = ((totalCommentsWithNameTags / totalComments) * 100).toFixed(0);

  return (
    <Wrapper>
      <h3 style={{ fontSize: '15px' }}>
        User Tagged <Total>{totalCommentsWithNameTags}</Total>
        <Total style={{ fontWeight: 600 }}> {precentage}%</Total>
      </h3>
      <ResponsiveContainer height={'80%'} width={'100%'}>
        <PieChart>
          <Pie
            isAnimationActive={!isReportPage}
            data={userTagsDataForGraph}
            cy={160}
            cornerRadius={29}
            innerRadius={5}
            paddingAngle={5}
            labelLine={false}
            dataKey="amount"
          >
            {userTagsDataForGraph.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip isAnimationActive={!isReportPage} />
        </PieChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

export default UserTags;
