import { usePDF, PDFViewer, Font } from "@react-pdf/renderer";
import { useContext, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { AppContext } from "../../../store/context";
import CompletedPdf from "./CompletedPdf";
import * as htmlToImage from "html-to-image";
import LikesBar from "../../ui/post/reactions/likes/LikesBar";
import MoreReactions from "../../ui/post/reactions/more-reactions/MoreReactions";
import { DATA_URL, REFS } from "./interface";
import { CommentsSentimentDisplay, UserRepliesDisplay, UserTagsDisplay } from "../../ui/post/graphs/Graphs";
// @ts-ignore
import poppinsBold from "../../../components/ui/utils/fonts/Poppins-Bold.ttf";
// @ts-ignore
import poppinsRegular from "../../../components/ui/utils/fonts/Poppins-Regular.ttf";
import { Redirect } from "react-router-dom";
import { SINGLE_POST_DETAILED_RESPONSE, WORD_TAGS } from "../../../store/interface";
import { PrimaryButton } from "../../ui/utils/CommonStyles";
import { useHistory } from "react-router";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const registerFonts = () => {
  Font.register({
    family: "Poppins-Bold",
    src: poppinsBold,
  });
  Font.register({
    family: "Poppins",
    src: poppinsRegular,
  });
};

const ReportManager = () => {
  const [copyCurrentPost, setCopyCurrentPost] = useState<SINGLE_POST_DETAILED_RESPONSE | null>(null);
  const [copyReportName, setCopyReportName] = useState<string>("");
  const [copyWordBank, setCopyWordBank] = useState<WORD_TAGS | null>(null);
  const history = useHistory();
  const back = () => {
    history.goBack()
  }


  const {
    state: {
      report: { currentPost },
    },
  } = useContext(AppContext);

  const [dataUrls, setDataUrls] = useState<DATA_URL[]>([]);
  const [refs, setRefs] = useState<REFS>();
  const {
    state: { report },
  } = useContext(AppContext);
  const onCapture = useCallback(async () => {
    const newDataUrls = await Promise.all(
      Object.entries(refs!).map(async ([key, value]) => {
        return {
          url: value ? await htmlToImage.toPng(value) : "chart is unavailable",
          name: key,
        };
      })
    );
    setDataUrls(newDataUrls);
  }, [refs]);

  useEffect(() => {
    const likesBarRef = document.getElementById("likes-bar");
    const moreReactionsBarRef = document.getElementById("more-reactions");
    const userTagsBar = document.getElementById("user-tags");
    const userRepliesChart = document.getElementById("user-replies");
    const commentsSentiments = document.getElementById("comments-sentiment");
    registerFonts();
    setRefs({
      likesBarRef,
      moreReactionsBarRef,
      userTagsBar,
      userRepliesChart,
      commentsSentiments,
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await onCapture();
    };
    if (refs) {
      fetchData();
    }
  }, [refs, onCapture]);

  useEffect(() => {
    if (currentPost && report) {
      setCopyCurrentPost(currentPost);
      setCopyReportName(report.name);
      setCopyWordBank(report.wordBank);
    }
  }, [currentPost, report]);
  if (!currentPost) {
    return <Redirect to="/" />;
  }
  return (

    <Wrapper>
      <div style={{ alignSelf: 'end', margin: '0 147px 0 73px' }} >
        <button style={{ cursor: 'pointer', color: '#2b314a', textDecoration: 'underline' }} onClick={(e) => back()} > Back</button>
      </div>
      {/* <PrimaryButton onClick={toggleSave}>save</PrimaryButton> */}
      {dataUrls.length > 0 && copyCurrentPost && (
        <PdfDownload
          Document={
            <CompletedPdf wordBank={copyWordBank} currentPost={copyCurrentPost} reportName={copyReportName} dataUrls={dataUrls} />
          }
        />
      )}
      {dataUrls.length > 0 && copyCurrentPost && (
        <PDFViewer
          children={
            <CompletedPdf wordBank={copyWordBank} currentPost={copyCurrentPost} reportName={copyReportName} dataUrls={dataUrls} />
          }
          style={{ width: "1000px", height: "800px", overflow: "hidden" }}
        ></PDFViewer>
      )}
      {!dataUrls.length && (
        <div
          style={{
            display: "flex",
            overflow: "scroll",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <LikesBar
            likeAmount={currentPost!.reactions.like}
            totalReactions={currentPost!.reactions.totalReactions}
            isReportPage={true}
          />
          <MoreReactions reactions={currentPost!.reactions} isReportPage={true} />
          <UserTagsDisplay currentPost={currentPost!} isReportPage={true} />
          <UserRepliesDisplay currentPost={currentPost!} isReportPage={true} />
          <CommentsSentimentDisplay currentPost={currentPost!} isReportPage={true} />
        </div>
      )}
    </Wrapper>
  );
};

interface PDF_DOWNLOAD {
  Document: JSX.Element;
  reportName?: string;
}

const PdfDownload = ({ Document, reportName = "MyReport" }: PDF_DOWNLOAD) => {
  const [instance] = usePDF({ document: Document });
  if (instance.loading) return <div>Loading ...</div>;

  if (instance.error) return <div>Something went wrong: {instance.error}</div>;

  return (
    <Wrapper>
      {instance.url && (
        <PrimaryButton>
          <a style={{ color: "white" }} href={instance.url} download={`${reportName}.pdf`}>
            Download
          </a>
        </PrimaryButton>
      )}
    </Wrapper>
  );
};

export default ReportManager;
