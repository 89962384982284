import { assets } from "../../../utils/assets";
import { Description, Percentage } from "../CommonStyles";
import { Wrapper, Container } from "./Styles";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from "recharts";

const LikesBar = ({
  likeAmount,
  totalReactions,
  isReportPage,
}: {
  likeAmount?: number;
  totalReactions?: number;
  isReportPage: boolean;
}) => {
  let percentage = 0;
  if (likeAmount) {
    percentage = (likeAmount / totalReactions!) * 100;
    percentage = percentage < 1 ? +percentage.toFixed(1) : +percentage.toFixed(0);
  }

  const likesData = [
    {
      name: "",
      total: totalReactions,
      positive: likeAmount,
      negative: totalReactions! - likeAmount!,
      description: `${likeAmount} Like`,
      percentage,
      imgSrc: assets["likeColored"],
    },
  ];

  const Chart = () => (
    <BarChart
      width={isReportPage ? 500 : 600}
      height={300}
      data={likesData}
      layout={"vertical"}
      barSize={20}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <YAxis
        axisLine={false}
        width={65}
        type={"category"}
        tickLine={false}
        dataKey={"name"}
        orientation={"left"}
      />
      <XAxis tick={false} axisLine={false} type={"number"} dataKey={"total"} orientation={"bottom"} />
      <Bar stackId={"a"} dataKey="positive" fill="#2C97F9" isAnimationActive={!isReportPage} />
      <Bar
        stackId={"a"}
        radius={[0, 5, 5, 0]}
        dataKey="negative"
        fill="#DEDEDE"
        isAnimationActive={!isReportPage}
      />
    </BarChart>
  );
  return (
    <Wrapper id={"likes-bar"} isReportPage={isReportPage}>
      <img src={likesData[0].imgSrc} alt="" />
      <Container>
        <Percentage>{likesData[0].percentage}%</Percentage>
        <Description>{likesData[0].description}</Description>
      </Container>
      {!isReportPage ? (
        <ResponsiveContainer width={'90%'} height="auto">
          <Chart />
        </ResponsiveContainer>
      ) : (
        <Chart />
      )}
    </Wrapper>
  );
};

export default LikesBar;
