import { Route, Switch, Redirect } from "react-router-dom";
import { AlertsDashboard } from "../components/pages/alerts/AlertsDashboard";
import ReportManager from "../components/pages/report-creation/ReportManager";
import Homepage from "../components/pages/report-preperation/homepage/Homepage";
import ReportRouter from "./ReportRouter";
import EditAlert from "../components/pages/alerts/EditAlert/EditAlert";
import { LayoutContainer, Layout } from "./styles";
import { useContext } from "react";
import { AppContext } from "../store/context";

const LayoutRouter = () => {
  const {
    state: { report },
    dispatch,
  } = useContext(AppContext);
  const { navBarCollaped } = report;
  
  return (
    <LayoutContainer collapsed={navBarCollaped}>
      <Layout>
        <Switch>
          <Route path="/:report/view-report" component={ReportManager} />
          <Route path="/alerts" component={AlertsDashboard} />
          <Route path="/edit-alert/:postId" component={EditAlert}/>
          <Route path="/:report">
            <ReportRouter />
          </Route>
          <Route exact path="/" component={Homepage} />
          <Redirect from="*" to="/" />
        </Switch>
      </Layout>
    </LayoutContainer>
  );
};
export default LayoutRouter;
