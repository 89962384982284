import { Fragment, useContext, useMemo, useEffect } from "react";
import { useTable, Column, useSortBy } from "react-table";
import { AppContext } from "../../../../store/context";
import { TABLE_ALERTS_PROPS } from "../../../ui/table/post-input/interface";
import { assets } from "../../../ui/utils/assets";
import { Icon } from "../../../ui/utils/CommonStyles";
import { TableStyled, Label, Input, Indicator, SingleComment } from "./Styles";
import { CONSTANTS } from "../../../../store/constants";
import { handleMarkWords, applyCategoryToTable } from "../helper";
import { COMMENT, POST_ALERT_WORD } from "../../../../store/interface";
import moment from "moment";

const EditAlertsTable = ({ 
  words, 
  data,
  handleAllCommentCheck
}: TABLE_ALERTS_PROPS) => {
  const {
    state: { alert },
    dispatch,
  } = useContext(AppContext);
  const { alertEditPostCommentsCollection, wordBank } = alert;

  const handleCommentCheck = (comment_id: string, checked: boolean) => {
    dispatch({
      type: CONSTANTS.AddEditPostAlertComment,
      payload: { alertComment_id: comment_id, isActive: checked },
    });
  };

  useEffect(() => {
    
  }, [alertEditPostCommentsCollection])


  const renderAsHTML = (content: string) => {
    return { __html: content };
  };

  const columns: Column<{
    _id: string;
    alerted_words: string[];
    comment_id: string;
    category: number;
    createdAt: Date | string;
    updateAt: Date;
    comment: COMMENT;
    message: string;
    wordCount: number;
  }>[] = useMemo(
    () => [
      {
        id: "post",
        Header: "",
        sortType: (a: any, b: any) => {
          return a.original.post_id > b.original.post_id ? -1 : 1;
        },
        Cell: ({ row }: { row: any }) => {
          const isActive = alertEditPostCommentsCollection.find(
            (comment) => comment.alertComment_id === row.original.comment_id
          )?.isActive;
          return (
            <span>
              <span
                className="ellipsis word"
                style={{ marginTop: row.original.post_id ? "8px" : "0" }}
              >
                <Label>
                  <Input
                    checked={isActive}
                    style={{ marginTop: "6px", marginLeft: "13px" }}
                    type="checkbox"
                    onChange={(e) => {
                      handleCommentCheck(
                        row.original.comment_id,
                        e.target.checked
                      );
                    }}
                  />
                  <Indicator style={{ left: "-0.5em" }} />
                </Label>
              </span>
            </span>
          );
        },
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ row }: { row: any }) => {
          const date = moment(row.original.createdAt).format("DD-MM-YYYY");
          return date;
        },
      },
      {
        Header: "Text",
        accessor: "message",
        Cell: ({ row }: { value: any; row: any }) => {
          const newPostAlertedWords:POST_ALERT_WORD[] = []

            words.forEach((word) => {
              wordBank.forEach((wordBankWord)=>{
                if ( wordBankWord.word === word.word ){
                  newPostAlertedWords.push({
                    word:wordBankWord.word,
                    category:wordBankWord.category
                  })
                }
              })
            })
          const comment = handleMarkWords(row.original.message,row.original.category, newPostAlertedWords)  
          return (
            <>
              <SingleComment
                dangerouslySetInnerHTML={renderAsHTML(comment)}
              ></SingleComment>
            </>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "wordCount",
        Cell: ({row}) => {
          return(
            <p>{row.original.alerted_words.length}</p>
          )
        },
      },
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ row }) => {
          
          return <Icon src={assets[applyCategoryToTable(row.original.category)]} />;
        },
      },
    ],
    [alertEditPostCommentsCollection]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<{
      _id: string;
      alerted_words: string[];
      comment_id: string;
      category: number;
      createdAt: Date | string;
      updateAt: Date;
      comment: COMMENT;
      message: string;
      wordCount: number;
    }>(
      {
        columns,
        data,
      },
      useSortBy
    );

  return (
    <TableStyled {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, i) => {
              const { key } = { ...column.getHeaderProps() };
              const keys = [
                "header_post",
                "header_message",
                "header_wordCount",
              ];
              if (!keys.includes(key.toString())) {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={i}
                  >
                    {column.render("Header")}
                    <span style={{ marginLeft: "8px" }}>
                      <img src={assets["tableHeadIcon"]} />
                    </span>
                  </th>
                );
              }
                
              if (key === 'header_post'){
                return (
                  <>
                 <th key={i}>
                 <Label>
                  <Input
                    // checked={isActive}
                    style={{ marginTop: "6px", marginLeft: "13px" }}
                    type="checkbox"
                    onChange={(e) => {
                      handleAllCommentCheck(
                        e.target.checked
                      );
                    }}
                  />
                  <Indicator style={{ left: "0.5em" }} />
                </Label>
                 <span style={{position:'relative',top:'3px'}}>{column.render("Header")}</span>
               </th>
                  </>
                )
              }  
              return (
                <th {...column.getHeaderProps()} key={i}>
                  {column.render("Header")}
                  
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <>
              <tr {...row.getRowProps()} key={rowIndex}>
                {row.cells.map((cell, i) => {
                  return (
                    <Fragment key={i}>
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    </Fragment>
                  );
                })}
              </tr>
            </>
          );
        })}
      </tbody>
    </TableStyled>
  );
};

export default EditAlertsTable;
