import { REACTIONS } from '../../../../../store/interface';
import { assets } from '../../../utils/assets';
import { Description, Percentage, Title } from '../CommonStyles';
import { Wrapper, Container, ReactionCol } from './Styles';

const MoreReactions = ({ reactions, isReportPage }: { reactions: REACTIONS; isReportPage?: boolean }) => {
  let reactionsCopy = { ...reactions };

  const totalReactionsAmountCopy = reactions.totalReactions;

  delete reactionsCopy.like;
  delete reactionsCopy.shares;
  delete reactionsCopy.thankful;
  delete reactionsCopy.pride;
  delete reactionsCopy.totalReactions;

  const reactionsArray = Object.entries(reactionsCopy).map(([name, amount]) => {
    let percentage = (amount! / totalReactionsAmountCopy!) * 100;
    percentage = percentage < 1 ? +percentage.toFixed(1) : +percentage.toFixed(0);
    return {
      description: `${amount} ${name.charAt(0).toUpperCase()}${name.slice(1)}`,
      percentage,
      imgSrc: assets[name] ?? '',
    };
  });

  return (
    <Wrapper isReportPage={isReportPage} id={'more-reactions'}>
      <Title>More reactions</Title>
      <Container>
        {reactionsArray.map(({ description, percentage, imgSrc }, index) => (
          <ReactionCol key={index}>
            <Percentage key={index} className="percentage">
              {percentage}%
            </Percentage>
            <img src={imgSrc} alt=""></img>
            <Description>{description}</Description>
          </ReactionCol>
        ))}
      </Container>
    </Wrapper>
  );
};

export default MoreReactions;
