import styled from 'styled-components';
import { colors } from '../../../../utils';
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: none;
  margin-right: 147px;
  margin: 0 147px 0 0;
  height: 80%;
  margin-top: 28px;
`;

export const Container = styled.div`
  display: flex;
  height: 434px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const CommentsSentimentWrapper = styled.div`
  height: 104px;
  margin-bottom: 7px;
  border-top: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: ${colors.white};
  padding: 13px 18px 4px 18px;
`;
