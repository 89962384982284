import { checkBox } from './Styles';
import { CONSTANTS } from "../../../store/constants";
import { useContext } from 'react';
import { AppContext } from '../../../store/context';
import { SINGLE_POST_DETAILED_RESPONSE } from '../../../store/interface';
const { Label, Input, Indicator, SelectAllText } = checkBox;


const CheckBox = ({ checkBoxText, value, posts }: { checkBoxText: string, value: boolean, posts: SINGLE_POST_DETAILED_RESPONSE[] }) => {

  const {
    state: { report },
    dispatch,
  } = useContext(AppContext);

  const { allPostsChecked } = report;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    posts.forEach((post)=>{
      // const value = posts.length > 1 ?
      dispatch({
        type: CONSTANTS.SetCurrentPostsGroup,
        payload: { post, value: e.target.checked }
      })
    })

    if(allPostsChecked){
      dispatch({
        type: CONSTANTS.SetAllPostsChecked,
        payload: { allChecked: false }
      })

      document.querySelectorAll('.select-all-checkbox').forEach((checkbox)=>{
        (checkbox as HTMLInputElement).checked = false;
      })
    }
  }

  const handleChangeAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    posts.forEach((post)=>{
      // const value = posts.length > 1 ?
      setAllCheckBoxes(e.target.checked);
      dispatch({
        type: CONSTANTS.SetCurrentPostsGroup,
        payload: { post, value: e.target.checked }
      })

      dispatch({
        type: CONSTANTS.SetAllPostsChecked,
        payload: { allChecked: e.target.checked }
      })
    })
  }


  const setAllCheckBoxes = ( checked: boolean) => {
    document.querySelectorAll('.post-checkbox').forEach((checkbox)=>{
      (checkbox as HTMLInputElement).checked = checked;
    })

    dispatch({
      type: CONSTANTS.SetAllPostsChecked,
      payload: { allChecked: true }
    })
  }

  const currentPost = posts[0];
  return (
    posts.length > 1 ?
      <Label htmlFor={'checkbox-select-all'} >
      <Input
        id={`checkbox-select-all`}
        type="checkbox"
        className='select-all-checkbox'
        name={`checkbox-select-all`}
        value={value.toString()}
        // checked={isActive}
        onChange={handleChangeAll}
      />
      <Indicator className='label-select-all' />
      <SelectAllText>{checkBoxText}</SelectAllText>
    </Label>
      : 
      <Label htmlFor={`checkbox-${currentPost.postDetails.id}`}>
      <Input
        id={`checkbox-${currentPost.postDetails.id}`}
        className='post-checkbox'
        type="checkbox"
        name={`checkbox-${currentPost.postDetails.id}`}
        value={value.toString()}
        // checked={isActive}
        onChange={handleChange}
      />
      <Indicator />
    </Label>
  );
};

export default CheckBox;
