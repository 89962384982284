import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { login } from '../../../api/authApi';
import { CONSTANTS } from '../../../store/constants';
import { AppContext } from '../../../store/context';
import { assets } from '../../ui/utils/assets';
import {
  Wrapper,
  Input,
  Header,
  Icon,
  Container,
  Title,
  InputContainer,
  BorderText,
  LoginButton,
  Form,
} from './Styles';

const Login = () => {
  const {
    state: { auth },
    dispatch,
  } = useContext(AppContext);
  const [inputType, setInputType] = useState('password');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleToggle = () => {
    if (inputType === 'password') setInputType('text');
    else setInputType('password');
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await login(email, password);
      if (response.error) return;
      const payload = { token: response.idToken, email };
      dispatch({ type: CONSTANTS.Login, payload });
    } catch (error) {
      dispatch({ type: CONSTANTS.Logout, payload: {} });
    }
  };

  if (auth.token) {
    return <Redirect to="/" />;
  }

  return (
    <Wrapper>
      <Container>
        <Header>
          <Icon src={assets['loginLogo']} alt="Cat" />
          <Title>Cat</Title>
        </Header>
        <BorderText>Username</BorderText>
        <Form autoComplete="on" onSubmit={handleLogin}>
          <InputContainer>
            <Input name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </InputContainer>
          <BorderText>Password</BorderText>
          <InputContainer>
            <Input type={inputType} value={password} onChange={(e) => setPassword(e.target.value)} />
            <Icon onClick={handleToggle} className="badge pointer" src={assets['password']} />
          </InputContainer>
          <LoginButton type="submit" disabled={!email || !password}>
            Login
          </LoginButton>
        </Form>
      </Container>
    </Wrapper>
  );
};

export default Login;
