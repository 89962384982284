import { colors } from '../../../utils';

export const getTextByAverageScore = (avg: number): string[] => {
  switch (true) {
    case avg === 0:
      return ['No Sentiment', colors.blue];
    case avg < 0.2:
      return ['Negative', colors.negativeLight];
    case avg >= 0.4:
      return ['Positive', colors.positiveLight];
    case avg < 0.4 && avg >= 0.2:
      return ['Neutral', colors.yellow];
    default:
      return ['Neutral', colors.yellow];
  }
};

export const  titleCaseWord = (word: string) : string =>{
  if (!word) return word;
  return word[0].toUpperCase() + word.substr(1).toLowerCase();
}
