import React from "react";
import { ResponsiveContainer, BarChart, YAxis, XAxis, Bar, Tooltip } from "recharts";
import { SINGLE_POST_DETAILED_RESPONSE } from "../../../../../store/interface";
import { commentAdReactiosSensetivityColors } from "../../../../../utils";
import { assets } from "../../../utils/assets";
import { Title } from "../../reactions/CommonStyles";
import { Total } from "./Styles";

const CommentsSentiment = ({
  positiveComments,
  negativeComments,
  neutralComments,
  isReportPage,
}: {
  positiveComments: SINGLE_POST_DETAILED_RESPONSE["positiveComments"];
  negativeComments: SINGLE_POST_DETAILED_RESPONSE["negativeComments"];
  neutralComments: SINGLE_POST_DETAILED_RESPONSE["neutralComments"];
  isReportPage: boolean;
}) => {
  const total = positiveComments + negativeComments + neutralComments;
  const reactionsSentiment = [
    {
      name: "",
      total,
      positive: positiveComments,
      neutral: neutralComments,
      negative: negativeComments,
      imgSrc: assets["likeColored"],
    },
  ];

  return (
    <div style={{ width: isReportPage ? "600px" : "100%", height: "100%" }}>
      <Title>
        Comments <Total>{total} total</Total>
      </Title>
      {isReportPage ? (
        <BarChart
          width={580}
          height={80}
          data={reactionsSentiment}
          layout={"vertical"}
          barSize={20}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <YAxis
            axisLine={false}
            width={65}
            type={"category"}
            tickLine={false}
            dataKey={"name"}
            orientation={"left"}
          />
          <XAxis tick={false} axisLine={false} type={"number"} dataKey={"total"} orientation={"bottom"} />
          <Bar
            stackId={"a"}
            radius={[5, 0, 0, 5]}
            dataKey="positive"
            fill={commentAdReactiosSensetivityColors.positive}
            isAnimationActive={!isReportPage}
          />
          <Bar stackId={"a"} dataKey="neutral" fill={commentAdReactiosSensetivityColors.netural} isAnimationActive={!isReportPage} />
          <Bar
            stackId={"a"}
            radius={[0, 5, 5, 0]}
            dataKey="negative"
            fill={commentAdReactiosSensetivityColors.negative} 
            isAnimationActive={!isReportPage}
          />
          <Tooltip cursor={{ fill: "transparent" }} isAnimationActive={!isReportPage} />
        </BarChart>
      ) : (
        <ResponsiveContainer width={"90%"}>
          <BarChart
            width={500}
            height={300}
            data={reactionsSentiment}
            layout={"vertical"}
            barSize={20}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <YAxis
              axisLine={false}
              width={65}
              type={"category"}
              tickLine={false}
              dataKey={"name"}
              orientation={"left"}
            />
            <XAxis tick={false} axisLine={false} type={"number"} dataKey={"total"} orientation={"bottom"} />
            <Bar
              stackId={"a"}
              radius={[5, 0, 0, 5]}
              dataKey="positive"
                fill={commentAdReactiosSensetivityColors.positive} 
              isAnimationActive={!isReportPage}
            />
              <Bar stackId={"a"} dataKey="neutral" fill={commentAdReactiosSensetivityColors.netural} isAnimationActive={!isReportPage} />
            <Bar
              stackId={"a"}
              radius={[0, 5, 5, 0]}
              dataKey="negative"
                fill={commentAdReactiosSensetivityColors.negative} 
              isAnimationActive={!isReportPage}
            />
              <Tooltip formatter={(val: any) => `${val}  (${(Intl.NumberFormat("en-GB", {
                style: "percent", minimumFractionDigits: 0,
                maximumFractionDigits: 1
              }).format(val / total))})`} cursor={{ fill: "transparent" }} isAnimationActive={!isReportPage} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default CommentsSentiment;
