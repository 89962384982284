import styled from 'styled-components';

export const TableStyled = styled.table`
  border-collapse: separate;
  text-align: left;
  margin-bottom: auto;
  border-spacing: 0 8px;
  width: 100%;
  td {
    background-color: 'rgba(52, 115, 226, 0.1)';
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-style: solid none;
    position: relative;
    height: 40px;
    padding: 0 25px 0 0;
  }

  td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 5px;
  }

  td:nth-child(2) {
    width: 500px;
  }

  td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    width: 300px;
  }

  .trash {
    cursor: pointer;
  }

  tr {
    font-size: 0.8125rem;
    text-align: left;
    height: 40px;
    margin-bottom: 8px;
    &:hover {
      background-color: #fff0d3;
    }
  }

  th {
    height: 40px;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.0625rem;
    color: rgba(51, 59, 80, 0.6);
    &:hover {
      background-color: none;
    }
  }
`;

export const SentimentsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: -49px;
  left: 110px;
  z-index: 1;
`;
