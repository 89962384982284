import { POSTS_GROUP_AVERAGES, SINGLE_POST_DETAILED_RESPONSE, WordObjectValue } from "../../../../../store/interface";

export const calcGroupAverages = (currentPostsGroup: (SINGLE_POST_DETAILED_RESPONSE | POSTS_GROUP_AVERAGES)[] | []): POSTS_GROUP_AVERAGES => {
    const averageDivder = currentPostsGroup.length;
    const averagesObj: { [index: string]: number | object | { [index: string]: number } } | POSTS_GROUP_AVERAGES = getEmptyAveragesObject();
    let keys: string[] = recursiveKeys(averagesObj);
    keys = keys.filter((key) => {
        return key !== 'wordFrequencies';
    })

    // calc avrages
    currentPostsGroup.forEach((post, index) => {
        keys.forEach((key: string) => {
            const splitKeys = key.split('.');
            if (splitKeys.length > 1) {
                // sub object
                const objectName = splitKeys[0], keyName = splitKeys[1];
                if (post[objectName] && (post[objectName] as { [index: string]: number })[keyName])
                    ((averagesObj[objectName] as { [index: string]: number })[keyName] as number) += ((post[objectName] as { [index: string]: number })[keyName] as number);

                if (index === averageDivder - 1) {
                    // average
                    const average = Math.round(((averagesObj[objectName] as { [index: string]: number })[keyName] as number) / averageDivder);
                    ((averagesObj[objectName] as { [index: string]: number })[keyName] as number) = average;
                }

            } else {
                (averagesObj[key] as number) += post[key] as number;

                if (index === averageDivder - 1) {
                    // average
                    const isSentimentCalc = key === 'averageSentimentScore';
                    const average = isSentimentCalc ? (averagesObj[key] as number) / averageDivder : Math.round((averagesObj[key] as number) / averageDivder);
                    (averagesObj[key] as number) = average;
                }
            }

        });

        // word frequencies summing
        const currnetWordFrequencies = post.wordFrequencies;
        Object.keys(currnetWordFrequencies).forEach((word) => {
            if ((averagesObj.wordFrequencies as { [index: string]: WordObjectValue })[word]) {
                (averagesObj.wordFrequencies as { [index: string]: WordObjectValue })[word].count += post.wordFrequencies[word].count;
                (averagesObj.wordFrequencies as { [index: string]: WordObjectValue })[word].comments = [
                    ...(averagesObj.wordFrequencies as { [index: string]: WordObjectValue })[word].comments,
                    ...post.wordFrequencies[word].comments
                ];
                (averagesObj.wordFrequencies as { [index: string]: WordObjectValue })[word].isActive = false;
            } else {
                (averagesObj.wordFrequencies as { [index: string]: WordObjectValue })[word] = post.wordFrequencies[word];
            }
        });
    })

    const parsedAveragesObj = parseToPostsGroupAverage(averagesObj);
    return parsedAveragesObj;
}

export const parseToPostsGroupAverage = (objToParse: { [index: string]: number | object | { [index: string]: number } } | POSTS_GROUP_AVERAGES): POSTS_GROUP_AVERAGES => {
    try {
        let parsedObject: POSTS_GROUP_AVERAGES = getEmptyAveragesObject() as POSTS_GROUP_AVERAGES;
        for (let key in objToParse) {
            if (parsedObject.hasOwnProperty(key)) {
                parsedObject[key] = objToParse[key];
            }
        }

        return parsedObject;

    } catch (error) {
        console.log('parseToPostsGroupAverage: something went wrong while tring to parse')
        return {} as POSTS_GROUP_AVERAGES;
    }
}

const recursiveKeys = (obj: any, propStr = ''): string[] => {
    let keys: any = [];
    Object.entries(obj).forEach(([key, val]) => {
        const nestedPropStr = propStr + (propStr ? '.' : '') + key;
        if (typeof val === 'object') {
            const addedKeys = recursiveKeys(val, nestedPropStr);
            keys = [...keys, ...addedKeys];
        }
        else {
            keys.push(nestedPropStr);
        }
    });

    return keys;
}

const getEmptyAveragesObject = (): { [index: string]: number | object | { [index: string]: number } } | POSTS_GROUP_AVERAGES => {
    return {
        totalComments: 0,
        totalCommentsWithNameTags: 0,
        totalCommentsWithReplies: 0,
        positiveComments: 0,
        negativeComments: 0,
        neutralComments: 0,
        reactions: {
            angry: 0,
            care: 0,
            haha: 0,
            like: 0,
            love: 0,
            pride: 0,
            sad: 0,
            shares: 0,
            thankful: 0,
            totalReactions: 0,
            wow: 0,
        },
        averageSentimentScore: 0,
        reactionsSentiments: {
            negativeReactions: 0,
            positiveReactions: 0,
            veryPositiveReactions: 0
        },
        wordFrequencies: {},
        mentionsAndIntent: {
            productsCount: 0,
            purchaseCount: 0,
            competitorsCount: 0
        }
    }
}

export const getMaximumForBarGraph = (data: any[], key: string, currMax: number = 0) => {
    let maximum = 0;
    if (data && data.length) {
        maximum = Math.max(...(data.map(obj => obj[key]))) + Math.max(...(data.map(obj => obj[key])));
    }
    maximum = maximum === 0 ? 2 : maximum;
    maximum = Math.max(maximum, currMax)
    return maximum + 2;
}

export const capitalizeFirstLetter = (str:string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}