import { useContext } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { verifyTokenEffect } from '../../../api/authApi';
import { CONSTANTS } from '../../../store/constants';
import { AppContext } from '../../../store/context';

const useVerifyTokenEffect = () => {
  const {
    state: { auth },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const token = JSON.parse(JSON.stringify(localStorage.getItem('token')));
      const email = JSON.parse(JSON.stringify(localStorage.getItem('email')));
      if (!email || !token) {
        dispatch({ type: CONSTANTS.Logout, payload: {} });
        history.push('/login');
        return false;
      }
      try {
        const response = await verifyTokenEffect(email, token);
        if ((response.msg = 'Success')) {
          dispatch({ type: CONSTANTS.Login, payload: { email, token }});
        } else {
          dispatch({ type: CONSTANTS.Logout, payload: {} });
          return history.push('/login');
        }
      } catch (error) {
        dispatch({ type: CONSTANTS.Logout, payload: {} });
        return history.push('/login');
      }
    };
    fetchData();
  }, [dispatch, history]);
  return auth.isTokenValid;
};

export default useVerifyTokenEffect;
