import React, { useContext, useState } from "react";
import { CONSTANTS } from "../../../store/constants";
import { AppContext } from "../../../store/context";
import { Input, ReportInputContainer } from "../utils/CommonStyles";

const ReportInput = () => {
  const {
    state: { report },
    dispatch,
  } = useContext(AppContext);
  const [reportName, setReportName] = useState(report.name);
  const handleMouseLeave = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch({ type: CONSTANTS.SetReport, payload: { name: reportName } });
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      dispatch({ type: CONSTANTS.SetReport, payload: { name: reportName } });
    }
  };

  return (
    <ReportInputContainer>
      <Input
        placeholder={`${reportName || "Untitled Report"}  `}
        onMouseLeave={handleMouseLeave}
        onKeyPress={handleKeyPress}
        onChange={(e) => {
          e.preventDefault();
          const name = e.target.value;
          const parsedName = `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
          setReportName(parsedName);
        }}
      />
    </ReportInputContainer>
  );
};

export default ReportInput;
