import PostInputTable from "../../../ui/table/post-input/PostInputTable";
import { assets } from "../../../ui/utils/assets";
import {
  Icon,
  WrapperWhite,
  WrapperWhiteHeader,
  Input,
  PrimaryButton,
  GreenBox,
} from "../../../ui/utils/CommonStyles";
import { HeaderTop, HeaderBottom, InputContainer } from "./Styles";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../store/context";
import { addPost, deletePost } from "../../../../store/actions/report-actions";
import { Redirect, useHistory } from "react-router";
import Alert from "../../../ui/no-posts-alert/Alert";
import { CONSTANTS } from "../../../../store/constants";

const PostsInput = () => {
  const [postId, setPostId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    state: { report },
    dispatch,
  } = useContext(AppContext);

  const history = useHistory();
  const hasPosts = report.posts.length > 0;
  const hasOnePostSelected = report.posts.filter(({ isActive }) => isActive).length;

  useEffect(() => {
    dispatch({ type: CONSTANTS.ResetPostsData, payload: {} });
  }, [dispatch]);

  const handleMouseClick = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await handleAddPost();
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      await handleAddPost();
    }
  };

  const handleAddPost = async () => {
    if (!postId) return;

    setIsLoading(true);
    await addPost(postId.trim(), dispatch);
    setPostId("");
    setIsLoading(false);
  };

  const handleNext = () => {
    history.push(`${report.name}/word-bank`);
  };

  const handleDeletePost = (postIndex: number) => {
    deletePost(postIndex, dispatch);
  };

  const handleCheckBox = (postId: string, checkBoxValue: boolean) => {
    dispatch({
      type: CONSTANTS.SetPostIsActive,
      payload: { postId, isActive: checkBoxValue },
    });
  };

  const setActiveStatusForAllPosts = (checkBoxValue: boolean) => {
    dispatch({ type: CONSTANTS.SetAllPostsIsActive, payload: { isActive: checkBoxValue } });
  };

  if (!report.name) {
    return <Redirect to={`/`} />;
  }

  return (
    <WrapperWhite>
      <WrapperWhiteHeader>
        <HeaderTop>
          <span className="title">Enter or choose post/page ID's for report {report.name}</span>
        </HeaderTop>
        <HeaderBottom>
          <InputContainer>
            <Icon className="badge" src={assets["badge"]} />
            <form autoComplete="on" onSubmit={handleMouseClick}>
              <Input
                value={postId}
                type="text"
                name="postId"
                placeholder="Enter post ID here"
                onChange={(e) => setPostId(e.target.value)}
                onKeyPress={handleKeyPress}
                autoFocus={true}
              />
              <GreenBox type="submit" className="pointer">
                <Icon className="add" src={assets["add"]} />
              </GreenBox>
            </form>
          </InputContainer>
          <PrimaryButton className="update-data" onClick={handleNext} disabled={!hasOnePostSelected}>
            Next
          </PrimaryButton>
          {isLoading && <img className="loader" src={assets["loader"]} alt="" />}
        </HeaderBottom>
      </WrapperWhiteHeader>
      {hasPosts ? (
        <PostInputTable
          data={report.posts}
          handleDeletePost={handleDeletePost}
          handleCheckBox={handleCheckBox}
          setActiveStatusForAllPosts={setActiveStatusForAllPosts}
        />
      ) : (
        <Alert msg={"No posts to show yet"} />
      )}
    </WrapperWhite>
  );
};

export default PostsInput;
