import { useContext } from "react";
import { AppContext } from "../../../../store/context";
import Alert from "../../../ui/no-posts-alert/Alert";
import ReportInput from "../../../ui/report/ReportNameInput";
import { Header, Wrapper } from "./Styles";

const Homepage = () => {
  const {
    state: {
      report: { name },
    },
  } = useContext(AppContext);
  return (
    <Wrapper>
      <Header>
        <ReportInput />
        {!name && <span>enter a title for your report name</span>}
      </Header>
      <Alert msg={"No posts to show yet"} />
    </Wrapper>
  );
};

export default Homepage;
