import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../store/context";
import { addCompare } from "../Styles";
import { CONSTANTS } from "../../../../../../store/constants";
import { PARSED_BRAND } from "../../../../sidebar/interface";
const { Label, Input, Indicator } = addCompare;

const CompareBrandsPick = () => {

  const {
    state: { report },
    dispatch,
  } = useContext(AppContext);
  const { brands, brandsToCompare } = report;

  const [brandsComparePick, SetBrandsToComparePick] = useState(brands.map((brandPick)=>{
    return {...brandPick,isActive:false}
  }))

  useEffect(()=>{
    SetBrandsToComparePick(brands.map((brandPick)=>{
      return {...brandPick,isActive:false}
    }))
},[brands])
  
  useEffect(()=>{
      SetBrandsToComparePick(brandsComparePick.map((brandPick)=>{
        return {...brandPick,isActive:false}
      }))
  },[])

  useEffect(()=>{
    if(brandsComparePick && brandsToCompare.length > 0) {
      const brandsToCompareDict = brandsToCompare.reduce((dict:{[key:string]:PARSED_BRAND},brand)=>{
        dict[brand.page_id]= brand
        return dict;
      },{})
      const newBrandsComparePick = brandsComparePick.map((brandPick)=>{
        const isBrandPickMatching = brandsToCompareDict[brandPick.page_id] && brandsToCompareDict[brandPick.page_id].isActive === true;
        if(isBrandPickMatching){
          return {...brandPick,isActive:true}
        }        
        return brandPick
      })
      SetBrandsToComparePick(newBrandsComparePick)
    }
  },[])

  const handleBrandCheck = (
    brand_name: string,
    page_id: string,
    checked: boolean,
    index:number
  ) => {
    brandsComparePick[index].isActive = checked
    SetBrandsToComparePick(brandsComparePick)
    dispatch({
      type: CONSTANTS.AddSelectedBrandsToCompare,
      payload: { brand_name, page_id, isActive: checked },
    });
  };

  return (
    <>
      {brandsComparePick.map(({ brand_name, isActive, page_id }, index) => (
        <div style={{width:"fit-content", marginRight:'5%'}} key={index}>
          <Label htmlFor={`checkbox-${index}`}>
            {brand_name}
            <Input
              id={`checkbox-${index}`}
              type="checkbox"
              checked={isActive}
              name={brand_name}
              value={isActive.toString()}
              onChange={(e) => {
                handleBrandCheck(brand_name,page_id,e.target.checked,index);
              }}
            />
            <Indicator />
          </Label>
        </div>
      ))}
    </>
  );
};
export default CompareBrandsPick;
